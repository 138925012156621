import React, { useState, useEffect } from 'react';
import { mapData, postsList } from './Posts.utils';
import Loading from '../shared/Loading';
import ResponsiveCards from '../shared/ResponsiveCards';
import { bottomOfThePage } from '../shared/bottomOfThePage';
import { useQuery } from '@apollo/client';
import { POSTS_QUERY } from './Posts.gql';

const queryPerPage = 8;

const PostsQuery = props => {
  const { limit, inlineLoader, searchBy = '' } = props;
  const [loadingMore, setLoadingMore] = useState(false);

  const { loading, error, data, fetchMore } = useQuery(POSTS_QUERY, {
    variables: { offset: 0, limit: limit || queryPerPage, searchBy: `%${searchBy}%` },
  });

  // scroll listener
  const onScroll = () => {
    if (bottomOfThePage('.cards > .card:last-child') && !loadingMore) {
      setLoadingMore(true);
    }
  };

  useEffect(() => {
    if (!limit && window) {
      window.addEventListener('scroll', onScroll);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // show more when hit bottom of the page
  useEffect(() => {
    if (loadingMore) {
      fetchMore({
        variables: {
          offset: data.posts.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          setLoadingMore(false);
          return Object.assign({}, prev, {
            posts: [...prev.posts, ...fetchMoreResult.posts],
          });
        },
      });
    }
  }, [loadingMore]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading)   return <Loading inlineLoader={inlineLoader} />;
  if (error) return <p>Error :(</p>;

  const items = mapData(data.posts);

  return <ResponsiveCards>{postsList(items)}</ResponsiveCards>;
};

export default PostsQuery;
