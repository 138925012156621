import React from 'react';
import { Container } from 'semantic-ui-react';
import Title from '../shared/Title';
import ProductBrowser from './ProductBrowser/ProductBrowser';
import { shops, sortByOptions } from '../../utils/data';

function Steals() {
  return (
    <Container>
      <Title title="Okazje" icon="search"></Title>
      <ProductBrowser shops={shops} sortByOptions={sortByOptions} newProducts={true} />
    </Container>
  );
}

export default Steals;
