import React, { useState } from 'react';
import { Tab } from 'semantic-ui-react';
import {postTabs } from './PostsTabs.data'
import PostsQuery from './PostsQuery';

const generateTabs = (searchBy) => {
  return postTabs.map((tab)=> {
    return { menuItem: tab.menuItem, render: () => <Tab.Pane attached={true}><br/><PostsQuery searchBy={searchBy} /></Tab.Pane> }
  })
}

const PostsTabs = ()  => {
  const [searchBy, setSearchBy] = useState('');

  const callback = (e, data) => {
    const { activeIndex, panes } = data;
    setSearchBy(postTabs[activeIndex].value)

    // alert(activeIndex);
    // setActiveIndex(activeIndex);
    // console.log('e', e);
    // console.log('data', data);
    // const newUrl = panes[activeIndex].url ? panes[activeIndex].url : '';
    // updateUrl(`/dropy/${newUrl}`);
  };

  return <Tab className='tabs' panes={generateTabs(searchBy)} onTabChange={callback} />;
}

export default PostsTabs;
