import React from "react";
import { AdBlockDetectedWrapper } from "adblock-detect-react";
import AdBlockMessage from '../AdBlockMessage/AdBlockMessage'

const AdBlockDetected = () => {
    return (
        <AdBlockDetectedWrapper>
            <AdBlockMessage/>
        </AdBlockDetectedWrapper>
    );
};

export default AdBlockDetected;