import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { openUrl } from '../../../utils/utils';
import {Container, Menu, Responsive, Segment, Visibility, Dropdown, Icon, Image} from 'semantic-ui-react';
import { getWidth, menuItems } from './utils';
import AdBlockDetected from '../Adblock/AdBlockDetected/AdBlockDetected'
import tiktokIcon from '../../../img/icons8-tiktok.svg';
import {socials} from './Template.data'
import Footer from './Footer/Footer'

const SocialIcons = () => {
  return socials.map(item => {
    const {title, icon, url} = item;
    if(title === 'TikTok') return <Image src={tiktokIcon} alt={title} style={{cursor: 'pointer',width: '28px', height: '28px', color: 'red !important'}} onClick={() => openUrl(url)} />
    return <Dropdown
        icon={icon}
        style={{ marginRight: '10px' }}
        as="a"
        onClick={() => openUrl(url)}
    />
  })
}

class DesktopContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility once={false} onBottomPassed={this.showFixedMenu} onBottomPassedReverse={this.hideFixedMenu}>
          <Segment inverted textAlign="center" vertical>
            <Menu fixed={fixed ? 'top' : null} inverted={!fixed} pointing={!fixed} secondary={!fixed} size="large">
              <Container>
                {menuItems()}
                <Menu.Item position="right" style={{fontSize: '24px', margin: '0', padding: '0 0 0 10px', alignSelf: 'auto'}}>
                  <SocialIcons/>
                </Menu.Item>
              </Container>
            </Menu>
          </Segment>
        </Visibility>
        <AdBlockDetected/>
        {children}
        <Footer/>
      </Responsive>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
};

export default DesktopContainer;
