import React, { useState, useRef } from 'react';
import { Container, Icon, Grid, Form, Button, Checkbox } from 'semantic-ui-react';
import './ImageCreator.css';

import ImageCreatorForm from './ImageCreatorForm';
import Image from './Image';
import ImagePromoText from './ImagePromoText';
import { shops, promoText } from './ImageCreator.data';
import { findShopValueByName } from './ImageCreator.utils';

const canvasHeight = 600;
const canvasWidth = 600;

const setDefaultForm = props => {
  const { data, promoType } = props;

  // let promoTitle, promoDate,
  //   promoRetail,
  //   promoResell = '';
  // let promoHour = '9:00';
  // let promoShop = shops[0].value;

  // if (data) {
  //   const {
  //     title,
  //     imageUrl,
  //     buyUrl,
  //     price,
  //     percentage,
  //     reduced,
  //     type,
  //     finalPrice,
  //     promoCode,
  //     status,
  //     onlyNikeApp,
  //     sizes,
  //     model,
  //     shop,
  //     date,
  //   } = data;
  // }

  const {
    title,
    imageUrl,
    buyUrl,
    price,
    percentage,
    reduced,
    type,
    finalPrice,
    promoCode,
    status,
    onlyNikeApp,
    sizes,
    model,
    shop,
    date,
    method,
    where,
  } = data;

  // console.log('data', data);

  let promoShop = '';
  let shopDetails = {};

  // if (where) {
  //   shopDetails = findShopValueByName(where, shops);
  // }
  // if (shop) {
  //   shopDetails = findShopValueByName(shop, shops);
  // }
  //
  // promoShop = shopDetails && shopDetails.value ? shopDetails.value : shops[0].value;

  promoShop = shop;

  const promoDate = date;
  // percentage
  const promoTitle = title;
  const promoRetail = parseInt(price);
  const promoResell = finalPrice;
  const promoHour = promoType === 'okazja' ? `Kod: "${promoCode}"` : '';

  return {
    date: promoDate,
    promoType,
    shop: promoShop,
    hour: promoHour,
    title: promoTitle,
    retail: promoRetail,
    resell: promoResell,
    method,
    percentage,
  };
};

function ImageCreator(props) {
  const { data, promoType } = props;
  const { imageUrl, bigImage } = data;
  const defaultForm = setDefaultForm(props);

  const canvasId = 'newImage';
  const [image, setImage] = useState(imageUrl ? { file: bigImage || imageUrl } : '');
  const [imageScale, setImageScale] = useState(1.9);
  const [imagePosition, setimagePosition] = useState(110);
  const [form, setForm] = useState(defaultForm);
  const [grid, setGrid] = useState(false);
  const [currentDate, setCurrentDate] = useState(props.data.date ? new Date(props.data.date) : new Date());

  const refInput = useRef(null);

  const onChange = (e, name) => {
    const { value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleFileChange = event => {
    try {
      setImage({
        file: URL.createObjectURL(event.target.files[0]),
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const onScaleChange = direction => {
    if (direction === 'up') {
      setImageScale(imageScale + 0.1);
    } else {
      setImageScale(imageScale - 0.1);
    }
  };

  const onPositionChange = direction => {
    if (direction === 'up') {
      setimagePosition(imagePosition - 2);
    } else {
      setimagePosition(imagePosition + 2);
    }
  };

  const onGridChange = () => {
    setGrid(!grid);
  };

  const onDatePickerChange = (event, data) => {
    setCurrentDate(data.value);
  };

  const onShopChange = (event, data) => {
    setForm({ ...form, shop: data.value });
  };

  const onPromoTypeChange = (event, data) => {
    setForm({ ...form, promoType: data.value });
  };

  return (
    <Container style={{ textAlign: 'center' }}>
      <br />
      <br />
      {defaultForm && (
        <Grid centered>
          <Grid.Column mobile={16} tablet={9} computer={10} centered>
            <Image
              id={canvasId}
              data={form}
              scale={imageScale}
              position={imagePosition}
              file={image.file}
              grid={grid}
              currentDate={currentDate}
            />
            {/*<br />*/}
            {/*<ImagePromoText form={form} currentDate={currentDate} />*/}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={3} computer={6}>
            <ImageCreatorForm
              data={form}
              onPromoTypeChange={onPromoTypeChange}
              callback={onChange}
              datePickerCallback={onDatePickerChange}
              currentDate={currentDate}
              shopCallback={onShopChange}
              refInput={refInput}
              handleFileChange={handleFileChange}
              grid={grid}
              onGridChange={onGridChange}
              onScaleChange={onScaleChange}
              onPositionChange={onPositionChange}
              canvasId={canvasId}
              canvasWidth={canvasWidth}
              canvasHeight={canvasHeight}
            />
          </Grid.Column>
        </Grid>
      )}

      <h4>Strony do usuwania tła:</h4>
      <p>
        <a href="https://pixlr.com/pl/remove-background/" target="_blank">
          pixlr
        </a>
        ,{' '}
        <a href=" https://remove-white-background.imageonline.co/" target="_blank">
          imageonline
        </a>
      </p>

      <h4>Pozostale</h4>
      <p>
        <a href="https://blog.sharelov.com/how-to-post-on-instagram-from-pc-mac/">
          Jak dodawać posty na Instagram z komputera
        </a>
      </p>
      <br />
      <br />
    </Container>
  );
}

export default ImageCreator;
