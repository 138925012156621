import React, { memo } from 'react';
import { Card, Icon, Button, Label, Popup } from 'semantic-ui-react';
import './ProductCard.css';
import LazyImage from '../LazyImage';
import dayjs from 'dayjs';
import ModalConfirmation from './ModalConfirmation';
import relativeTime from 'dayjs/plugin/relativeTime';
import PromoModal from './PromoModal';
import 'dayjs/locale/pl';
import { isBeta } from '../../Steals/ProductBrowser/ProductBrowserUtils';
dayjs.extend(relativeTime);
dayjs.locale('pl');

// const sizesText = (sizes) => {
//   let res = '';
//   if(sizes){
//     if(sizes < 3){
//       res = 'Ostatnie sztuki!'
//     }
//     if(sizes >= 3 && sizes < 10){
//       res = 'Rozmiary szybko znikają'
//     }
//     if(sizes >= 10 ){
//       res = 'Dużo rozmiarów'
//     }
//   }
//   return res;
// }

const listOfSizes = sizes => {
  const sizesCounter = sizes.length;
  // let level = sizesText(sizesCounter);
  // let someSizes = sizes.slice(0, 5);
  const listOfSizes = () =>
    sizes.map((size, index) => {
      return `${size}${index + 1 !== sizesCounter ? ', ' : ''}`;
    });

  return (
    <Card.Meta>
      <Popup
        content={listOfSizes()}
        trigger={
          <span style={{ cursor: 'pointer' }}>
            Rozmiary: <strong>{sizes.length}</strong>
          </span>
        }
      />
    </Card.Meta>
  );
};

const ProductCard = props => {
  const { data, onPromoModal, beta } = props;
  const {
    id,
    title,
    imageUrl,
    buyUrl,
    price,
    percentage,
    reduced,
    type,
    finalPrice,
    promoCode,
    excluded,
    status,
    onlyNikeApp,
    sizes,
    model,
    shop,
    gender,
    discountTypeId,
      shopName
  } = data;

  const onBtnClick = url => {
    window.open(url, '_blank');
  };

  // const shoes = type => {
  //   if (type && type.length > 0) {
  //     const typeLow = type.toLowerCase();
  //     if (typeLow.includes('buty')) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };
  //
  // const promo = data => {
  //   alert(data);
  // };

  // console.log('shop', shop);

  return (
    <Card>
      <LazyImage
        src={imageUrl}
        wrapped
        ui={false}
        label={
          percentage
            ? {
                color: 'red',
                content: `-${percentage}%`,
                ribbon: 'right',
              }
            : null
        }
        shop={shop}
      />
      <Card.Content>
        <Card.Description className="shortTitle">
          <strong>{title}</strong>
        </Card.Description>
        {model && <Card.Meta><span className="mobile hidden">Model:</span> {model}</Card.Meta>}
        <Card.Meta>{type}</Card.Meta>
        {sizes && listOfSizes(sizes)}
        {status && <Card.Description>{status}</Card.Description>}
      </Card.Content>
      {price && (
        <Card.Content extra>
          <div style={{ textAlign: 'center' }}>
            <Label tag size="large">
              {reduced ? (
                <>
                  <strong
                    style={{
                      fontWeight: 'bold !important',
                      color: '#db2828',
                    }}
                  >
                    {finalPrice} zł
                  </strong>
                  <Label.Detail className="mobile hidden" style={{ textDecoration: 'line-through' }}>{price} zł</Label.Detail>
                </>
              ) : (
                <strong style={{ fontWeight: 'bold !important', color: '#000' }}>{finalPrice} zł</strong>
              )}
            </Label>
            {!excluded && promoCode ? (
              <>
                <br />
                <Label pointing size="small" style={{ backgroundColor: '#d4d4d5' }}>
                  {promoCode ? `Kod: "${promoCode}"` : ''}
                </Label>
              </>
            ) : (
              ''
            )}
          </div>
        </Card.Content>
      )}
      <Card.Content extra>
        <div style={{ textAlign: 'center' }}>
          {buyUrl && (
            <>
              {onlyNikeApp ? (
                <ModalConfirmation />
              ) : (
                <Button
                  primary
                  size="tiny"
                  onClick={() => {
                    onBtnClick(buyUrl);
                  }}
                >
                  <Icon name="external" /> {shopName}
                </Button>
              )}
            </>
          )}
          {beta && onPromoModal && (
            <Button
              basic
              color="grey"
              size="tiny"
              onClick={() => {
                onPromoModal(data);
              }}
            >
              <Icon name="share square" /> Promo
            </Button>
          )}
        </div>
      </Card.Content>
    </Card>
  );
};

export default memo(ProductCard);
