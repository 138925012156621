import React from 'react';
import { Card, Button, Icon, Input, Popup, Feed, Label } from 'semantic-ui-react';
import copy from 'copy-to-clipboard';
import LazyImage from '../shared/LazyImage';

const SaleCard = ({ data }) => {
  // console.log('data', data);
  const {
    code = '',
    discount = '',
    sale_type,
    image_url = '',
    message = '',
    title = '',
    url = '',
    shop = {},
    refUrl = '',
  } = data;
  const { if: shopId, text: shopTitle } = shop;

  // console.log('data', data);

  return (
    <Card>
      <div className="content" style={{ maxHeight: '45px' }}>
        <Label color="red" ribbon="right" size="medium">
          -{discount}%
        </Label>
        <div className="header" style={{ marginTop: '-26px' }}>
          {shopTitle}{' '}
        </div>
      </div>
      {/*<LazyImage*/}
      {/*  src={process.env.PUBLIC_URL +`./img/shops/${shopId}.png`}*/}
      {/*  wrapped*/}
      {/*  ui={false}*/}
      {/*  label={{*/}
      {/*    as: 'a',*/}
      {/*    color: 'red',*/}
      {/*    content: `-${discount}%`,*/}
      {/*    ribbon: 'left',*/}
      {/*  }}*/}
      {/*/>*/}
      <Card.Content>
        {/*<Label color="red" ribbon size="medium">*/}
        {/*  -{discount}%*/}
        {/*</Label>*/}
        <div style={{ padding: '12px 0' }}>{message}</div>

        <div style={{ textAlign: 'center', paddingTop: '10px' }}>
          {code && sale_type !== 5 ? (
            <Popup
              trigger={
                <Input
                  size="mini"
                  icon="cut"
                  iconPosition="left"
                  // label={{ tag: true, content: ':' }}
                  labelPosition="left"
                  defaultValue={code}
                  style={{ maxWidth: '150px' }}
                  onClick={() => {
                    copy(code);
                  }}
                  onChange={e => {}}
                />
              }
              content="Kod został skopiowany"
              on="focus"
            />
          ) : (
            <Input
              size="mini"
              icon="cut"
              disabled
              iconPosition="left"
              iputProps={{ style: { textAlign: 'center' } }}
              // label={{ tag: true, content: ':' }}
              labelPosition="left"
              defaultValue="Kod przyjdzie na maila"
              style={{ maxWidth: '150px' }}
            />
          )}
        </div>

        <div style={{ textAlign: 'center', paddingTop: '10px' }}>
          <a href={refUrl} target="_blank" rel="noopener noreferrer">
            <Button primary size="tiny">
              <Icon name="shop"></Icon>
              Przejdź do sklepu
            </Button>
          </a>
        </div>
      </Card.Content>
    </Card>
  );
};

export default SaleCard;
