import { gql } from '@apollo/client';

export const POSTS_QUERY = gql`
  query GetPosts($limit: Int, $offset: Int, $searchBy: String) {
    posts(limit: $limit, offset: $offset, order_by: { created_time: desc }, where: {message: {_ilike: $searchBy }}) {
      created_time
      id
      message
      page
      url
      picture
    }
  }
`;
