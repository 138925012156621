import React, { useState } from 'react'
import {Accordion, Icon} from 'semantic-ui-react'
import {FooterLinks} from "./FooterLinks";

const FooterMobile = ({data}) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const handleClick = (index) =>{
        setActiveIndex(index)
    }

    return (
        <Accordion style={{textAlign: 'left', margin: '0 14px'}}>
            {data.map((footerRow, index) => {
                const{ title= '', children = []} = footerRow
                return <div key={index}><Accordion.Title
                    active={activeIndex === index}
                    index={index}
                    onClick={ () => handleClick(index)}
                >
                    <Icon name='dropdown' />
                    {title}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === index} style={{margin: '0  0 14px 24px'}}>
                    <FooterLinks data={children}></FooterLinks>
                </Accordion.Content></div>
            })}
        </Accordion>
    )
}

export default FooterMobile;
