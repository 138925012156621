// AWIN
export const awinURL = 'https://www.awin1.com/cread.php?awinmid=';
export const awinaffid = '688439';

// WEBGAINS
export const wgcampaignid = '1440705';
export const webgainsURL = `https://track.webgains.com/click.html?wgcampaignid=${wgcampaignid}&wgprogramid=`;

// SKIM LINKS
export const skimlinksURL = 'https://go.skimresources.com?id=157312X1623776&xs=1&sref=streetdealspl&url=';

export const shopsPL = [
  {
    country: 'PL',
    agency: 'awin',
    name: 'Answear',
    domain: 'answear.com',
    domainId: '8015',
    extraTracking: '%3Fref%3Dawin%26utm_source%3Dawin%26utm_medium%3Daffiliate',
  },
  {
    country: 'PL',
    agency: 'awin',
    name: 'Ataf',
    domain: 'ataf.pl',
    domainId: '18352',
  },
  {
    country: 'PL',
    agency: 'awin',
    name: 'Distance',
    domain: 'distance.pl',
    domainId: '15592',
  },
  {
    country: 'PL',
    agency: 'awin',
    name: 'Eastend',
    domain: 'eastend.pl',
    domainId: '10336',
  },
  {
    country: 'PL',
    agency: 'awin',
    name: 'eObuwie',
    domain: 'eobuwie.com.pl',
    domainId: '9259',
  },
  {
    country: 'PL',
    agency: 'awin',
    name: 'For Pro',
    domain: 'forpro.pl',
    domainId: '15551',
  },
  {
    country: 'PL',
    agency: 'awin',
    name: 'Modivo',
    domain: 'modivo.pl',
    domainId: '18280',
  },
  {
    country: 'PL',
    agency: 'awin',
    name: 'New Balance',
    domain: 'nbsklep.pl',
    domainId: '10317',
  },
  {
    country: 'PL',
    agency: 'awin',
    name: 'Nike',
    domain: 'nike.com',
    domainId: '16334',
  },
  {
    country: 'PL',
    agency: 'awin',
    name: 'Sklep Koszykarza',
    domain: 'sklepkoszykarza.pl',
    domainId: '18042',
  },
  {
    country: 'PL',
    agency: 'awin',
    name: 'Sneakers PL',
    domain: 'sneakers.pl',
    domainId: '18554',
  },
  {
    country: 'PL',
    agency: 'awin',
    name: 'Sportowy Sklep',
    domain: 'sportowysklep.pl',
    domainId: '10134',
  },
  // {
  //   country: 'PL',
  //   agency: 'awin',
  //   name: 'Street Supply',
  //   domain: 'streetsupply.pl',
  //   domainId: '16978',
  // },
  {
    country: 'PL',
    agency: 'awin',
    name: 'Yes Sport',
    domain: 'yessport.pl',
    domainId: '19031',
  },
  {
    country: 'PL',
    agency: 'awin',
    name: 'Worldbox',
    domain: 'worldbox.pl',
    domainId: '10342',
  },
  {
    country: 'PL',
    agency: 'awin',
    name: 'Street Style24',
    domain: 'streetstyle24.pl',
    domainId: '10248',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'adidas',
    domain: 'adidas.pl',
  },
  {
    agency: 'webgains',
    name: 'Sneakers Studio PL',
    domain: 'sneakerstudio.pl',
    domainId: '265725',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'Zalando Lounge',
    domain: 'zalando-lounge.pl',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'PROSTO',
    domain: 'prosto.pl',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'Footshop PL',
    domain: 'footshop.pl',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'Buty Sportowe',
    domain: 'butysportowe.pl',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'Sklep Biegacza',
    domain: 'sklepbiegacza.pl',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'Converse PL',
    domain: 'converse.pl',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'Sneaker Studio PL',
    domain: 'sneakerstudio.pl',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'Time Trend',
    domain: 'timetrend.pl',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'Reebok PL',
    domain: 'reebok.pl',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'asics',
    domain: 'asics.com',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'Chmielna20',
    domain: 'chmielna20.pl',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'Kicks Land',
    domain: 'kicks.land',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'Le coq sportif',
    domain: 'lcsklep.pl',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'About You',
    domain: 'aboutyou.pl',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'Footlocker PL',
    domain: 'footlocker.pl',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'Alpha Industries',
    domain: 'alphaindustries.com',
  },
  {
    country: 'PL',
    agency: 'awin',
    name: 'Local Heroes',
    domain: 'localheroesstore.com',
    domainId: '21157',
  },
];

export const shopsEU = [
  {
    country: 'EU',
    agency: 'awin',
    name: 'End Clothing',
    domain: 'endclothing.com',
    domainId: '17184',
  },

  {
    country: 'EU',
    agency: 'awin',
    name: 'Office',
    domain: 'office.co.uk',
    domainId: '2374',
  },
  {
    country: 'EU',
    agency: 'awin',
    name: 'Seven Store',
    domain: 'sevenstore.com',
    domainId: '8035',
  },

  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Sidestep NL',
    domain: 'sidestep-shoes.nl',
    domainId: '',
  },

  // {
  //   country: 'NL',
  //   agency: 'awin',
  //   name: 'Sneakers NL',
  //   domain: 'sneakers.nl',
  //   domainId: '16303',
  // },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Foot District',
    domain: 'footdistrict.com',
    domainId: '',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Vrients',
    domain: 'vrients.com',
    domainId: '',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Just Hype',
    domain: 'justhype.co.uk',
    domainId: '',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Fila de',
    domain: 'fila.de',
    domainId: '',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Stickabush DE',
    domain: 'stickabush.com',
    domainId: '',
  },

  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Sneakers Studio COM',
    domain: 'sneakerstudio.com',
    domainId: '265725',
  },
  // {
  //   country: 'EU',
  //   agency: 'skimlinks',
  //   name: 'HHV DE',
  //   domain: 'hhv.de',
  //   domainId: '10949',
  // },
  // {
  //   country: 'EU',
  //   agency: 'webgains',
  //   name: 'Monox Store',
  //   domain: 'monox-store.com',
  //   domainId: '10537',
  // },
  {
    country: 'EU',
    agency: 'webgains',
    name: 'Opium Paris',
    domain: 'opiumparis.com',
    domainId: '',
  },
  {
    country: 'EU',
    agency: 'webgains',
    name: 'We The New FR',
    domain: 'wethenew.com',
    domainId: '276305',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Zupport',
    domain: 'zupport.de',
    domainId: '',
  },
  {
    country: 'EU',
    agency: 'webgains',
    name: 'Tint',
    domain: 'tint-store.com',
    domainId: '11075',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Crisp Bln',
    domain: 'crispbln.com',
    domainId: '',
  },
  {
    country: 'EU',
    agency: 'webgains',
    name: 'Life Style Sports',
    domain: 'lifestylesports.com',
    domainId: '10245',
  },
  {
    country: 'EU',
    agency: 'webgains',
    name: 'Brooks',
    domain: 'brooks.de',
    domainId: '268925',
  },
  {
    country: 'EU',
    agency: 'webgains',
    name: 'Kong',
    domain: 'kongonline.co.uk',
    domainId: '265795',
  },

  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'adidas UK',
    domain: 'adidas.co.uk',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Sneakersnstuff',
    domain: 'sneakersnstuff.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Mooiesneakers',
    domain: 'mooiesneakers.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Sneakers Enzo',
    domain: 'sneakersenzo.nl',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Sneakers Stores BE',
    domain: 'sneakersstores.be',
  },
  {
    country: 'PL',
    agency: 'skimlinks',
    name: 'Asos',
    domain: 'asos.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Footshop COM',
    domain: 'footshop.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Footshop EU',
    domain: 'footshop.eu',
  },

  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Fortytree',
    domain: 'fortytree.de',
  },

  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Revolut',
    domain: 'revolut.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Puma EU',
    domain: 'eu.puma.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Puma UK',
    domain: 'uk.puma.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Converse',
    domain: 'converse.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'JD Sports UK',
    domain: 'jdsports.co.uk',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Foot Asylum',
    domain: 'footasylum.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Timberland UK',
    domain: 'timberland.co.uk',
  },

  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Penguin.com',
    domain: 'originalpenguin.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Then North Face',
    domain: 'thenorthface.co.uk',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Lyle and scott',
    domain: 'lyleandscott.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Footlocker EU',
    domain: 'footlocker.eu',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'The Hip Store',
    domain: 'thehipstore.co.uk',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'New Era Cap UK',
    domain: 'neweracap.co.uk',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Reebok UK',
    domain: 'reebok.co.uk',
  },

  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Offspring',
    domain: 'offspring.co.uk',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: '43einhalb',
    domain: '43einhalb.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'allikestore',
    domain: 'allikestore.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Slam Jam',
    domain: 'slamjam.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'BSTN',
    domain: 'bstn.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Footpatrol UK',
    domain: 'footpatrol.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Footpatrol FR',
    domain: 'footpatrol.fr',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Stone Island',
    domain: 'stoneisland.co.uk',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'BASS',
    domain: 'sneakerbaas.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'BASS UK',
    domain: 'sneakerbaas.co.uk',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'OBEY',
    domain: 'obeyclothing.eu',
  },
  {
    country: 'EU',
    agency: 'awin',
    name: 'Size UK',
    domain: 'size.co.uk',
    domainId: '2767',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Sole Kitchen',
    domain: 'solekitchen.de',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Sneaker World Shop ITALY',
    domain: 'sneakerworldshop.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Overkill Shop',
    domain: 'overkillshop.com',
  },
  // {
  //   country: 'ENG',
  //   agency: 'skimlinks',
  //   name: 'Noirfonce EU',
  //   domain: 'noirfonce.eu',
  // },
  // {
  //   country: 'ENG',
  //   agency: 'skimlinks',
  //   name: 'Noirfonce ES',
  //   domain: 'noirfonce.es',
  // },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Schrittmacher DE',
    domain: 'schrittmacher-shop.de',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Farfetch DE',
    domain: 'farfetch.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'stockX',
    domain: 'stockx.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Carhartt',
    domain: 'carhartt.com',
  },

  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Nigra Mercato',
    domain: 'nigramercato.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'G-Shock UK',
    domain: 'g-shock.co.uk',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Snipes',
    domain: 'snipes.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Snipes NL',
    domain: 'snipes.nl',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Snipes AT',
    domain: 'snipes.at',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Sneaker Outlet NL',
    domain: 'sneakeroutlet.nl',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Snipes CH',
    domain: 'snipes.ch',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Mr Porter',
    domain: 'mrporter.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Pro Direct Basketball',
    domain: 'prodirectbasketball.com',
  },
  {
    country: 'EU',
    agency: 'skimlinks',
    name: 'Courir FR',
    domain: 'courir.com',
  },
  // {
  //   country: 'EU',
  //   agency: 'cj',
  //   name: 'Naked',
  //   domain: 'nakedcph.com',
  //   cjLink: 'https://www.kqzyfj.com/click-9264245-14033316',
  // },
];

export const shopsUS = [
  // {
  //   country: 'US',
  //   agency: 'skimlinks',
  //   name: 'Puma',
  //   domain: 'puma.com',
  // },
  // {
  //   country: 'US',
  //   agency: 'skimlinks',
  //   name: 'Footlocker',
  //   domain: 'footlocker.com',
  // },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Reebok USA',
    domain: 'reebok.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'DTLR USA',
    domain: 'dtlr.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Fligth Club USA',
    domain: 'flightclub.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Nice Kicks',
    domain: 'shopnicekicks.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'GOAT US',
    domain: 'goat.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Stadium Goods US',
    domain: 'stadiumgoods.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Jimmy Jazz',
    domain: 'jimmyjazz.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Snipes USA',
    domain: 'snipesusa.com',
  },
  // {
  //   country: 'US',
  //   agency: 'skimlinks',
  //   name: 'Snipes USA 2',
  //   domain: 'snipesusa.com',
  // },
  // {
  //   country: 'US',
  //   agency: 'skimlinks',
  //   name: 'Sneaker Villa',
  //   domain: 'dtlr.com',
  // },
  // {
  //   country: 'US',
  //   agency: 'skimlinks',
  //   name: 'Sneaker Villa',
  //   domain: 'ruvilla.com',
  // },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Mooie Sneakers',
    domain: 'mooiesneakers.nl',
  },

  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Joes new balance outlet',
    domain: 'joesnewbalanceoutlet.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Ssense',
    domain: 'ssense.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Nordstrom',
    domain: 'nordstromrack.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Need Supply',
    domain: 'needsupply.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Champs Sports',
    domain: 'champssports.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Eastbay',
    domain: 'eastbay.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Foot action',
    domain: 'footaction.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Your City My City',
    domain: 'ycmc.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Your City My City',
    domain: 'yourcitymycity.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Shoe Palace',
    domain: 'shoepalace.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'Finish Line',
    domain: 'finishline.com',
  },
  {
    country: 'US',
    agency: 'skimlinks',
    name: 'kicks crew',
    domain: 'kickscrew.com',
  },
];

export const shopsRU = [
  {
    country: 'RU',
    agency: 'skimlinks',
    name: 'Sneakerhead RU',
    domain: 'sneakerhead.ru',
  },
  {
    country: 'RU',
    agency: 'skimlinks',
    name: 'Basket Shop',
    domain: 'basketshop.ru',
  },
];

export const shopsAsia = [
  {
    country: 'ASIA',
    agency: 'skimlinks',
    name: 'HBX',
    domain: 'hbx.com',
  },
];

export const shops = [...shopsPL, ...shopsEU, ...shopsUS, ...shopsRU, ...shopsAsia];
