import React, { memo, useState } from 'react';
// import { Card, Icon, Button, Label, Popup } from 'semantic-ui-react';
// import './PromoModal.css';
// import LazyImage from '../../shared/LazyImage';
// import dayjs from 'dayjs';
// import ModalConfirmation from './ModalConfirmation';
// import relativeTime from 'dayjs/plugin/relativeTime';
// import 'dayjs/locale/pl';
import ImageCreator from '../../ImageCreator/ImageCreator';
import { Button, Icon, Modal } from 'semantic-ui-react';
// dayjs.extend(relativeTime);
// dayjs.locale('pl');

const PromoModal = props => {
  const { data = {}, openModal, closeModal, promoType } = props;

  const { title } = data;

  // console.log('DATA', data);

  return (
    <Modal closeIcon size="large" open={openModal} onClose={closeModal}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <ImageCreator data={data} promoType={promoType} />
      </Modal.Content>
    </Modal>
  );
};

export default memo(PromoModal);
