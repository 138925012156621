import React, { Component } from 'react';
import { Responsive } from 'semantic-ui-react';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Title from '../shared/Title';

const socials = [
  {
    title:'TikTok',
    url: 'https://tiktok.com/@streetdealspl',
    icon: 'mobile alternate',
    color:'blue'
  },
  {
    title:'Fanpage',
    url: 'https://www.facebook.com/streetdealspl/',
    icon: 'facebook',
    color: 'facebook',
  },
  {
    title:'Instagram',
    url: 'https://www.instagram.com/streetdealspl/',
    icon: 'instagram',
    color: 'instagram',
  },
  {
    title:'Airmaxy',
    url: 'https://www.facebook.com/airmaxypl',
    icon: 'facebook',
    color: 'facebook',
  },
  {
    title:'Jordany',
    url: 'https://www.facebook.com/butydokoszajordan',
    icon: 'facebook',
    color: 'facebook',
  }
]

export default class Social extends Component {
  render() {
    return (
      <div className="introBackground  search-section">
        <Container data-testid="social">
          <br />
          <Title
            title="Społeczność"
            icon="users"
            subHeader="Nie przegap żadnej okazji na ciuchy czy buty Streetwear. Śledź nasze kanały w mediach społecznościowych."
          ></Title>
          <br />
          <Grid centered>
            <Grid.Row centered>
              {socials.map(social => {
                const {title = '',url='', icon = '', color=''} = social;
                return <Grid.Column mobile={16} tablet={16} computer={3}>
                  <a href={url} target="blank">
                    <Button color={color} fluid>
                      <Icon name={icon} /> {title}
                    </Button>
                  </a>
                </Grid.Column>
              })}
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}
