import React from 'react';
import { Card, Container } from 'semantic-ui-react';
import { mapData } from './Shops.utils';
import Title from '../shared/Title';
import ResponsiveCards from '../shared/ResponsiveCards';
import { useQuery } from '@apollo/client';
import { SHOPS_QUERY } from './Shops.gql';
import Loading from '../shared/Loading';

const postsList = data => {
  return data.map(item => {
    if (item) {
      const { text, refUrl } = item;
      return (
        <Card href={refUrl} target="_blank">
          <Card.Content style={{ textAlign: 'center' }}>
            <Card.Header>{text || ''}</Card.Header>
          </Card.Content>
        </Card>
      );
    }
    return null;
  });
};

const filterShopsBy = (data, field, value) => {
  return data.filter(shop => {
    return shop[field] === value;
  });
};

function Shops() {
  const { loading, error, data, fetchMore } = useQuery(SHOPS_QUERY);

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;

  const { products_shops } = data;
  const shopsPL = filterShopsBy(products_shops, 'country', 1);
  const shopsEU = filterShopsBy(products_shops, 'country', 2);
  const shopsRU = filterShopsBy(products_shops, 'country', 3);
  const shopsUS = filterShopsBy(products_shops, 'country', 4);
  const shopsAsia = filterShopsBy(products_shops, 'country', 5);

  // console.log('shopsPL', shopsPL);

  return (
    <Container>
      <Title title="Sklepy" icon="cart"></Title>
      <p style={{ textAlign: 'center' }}>
        Oto lista legitnych sklepów w których kupicie oryginalne buty i ciuchy typu Streetwear
      </p>
      <br />
      <ResponsiveCards> {postsList(mapData(shopsPL))}</ResponsiveCards>

      <br />
      <Title title="Europa" icon="cart"></Title>
      <ResponsiveCards> {postsList(mapData(shopsEU))}</ResponsiveCards>

      <br />
      <Title title="Rosja" icon="cart"></Title>
      <ResponsiveCards> {postsList(mapData(shopsRU))}</ResponsiveCards>

      <br />
      <Title title="Stany" icon="cart"></Title>
      <ResponsiveCards> {postsList(mapData(shopsUS))}</ResponsiveCards>

      <br />
      <Title title="Azja" icon="cart"></Title>
      <ResponsiveCards> {postsList(mapData(shopsAsia))}</ResponsiveCards>
    </Container>
  );
}

export default Shops;
