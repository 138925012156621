import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/pl';
import updateLocale from 'dayjs/plugin/updateLocale';
import React from 'react';

export const months = [
  'Stycznia',
  'Lutego',
  'Marca',
  'Kwietnia',
  'Maja',
  'Czerwca',
  'Lipca',
  'Sierpnia',
  'Września',
  'Października',
  'Listopada',
  'Grudnia',
];

dayjs.extend(relativeTime);
dayjs.locale('pl');
dayjs.extend(updateLocale);
dayjs.updateLocale('pl', {
  months,
});

export const dateFormat = (date, format) => {
  const formattedDate = dayjs(date);
  return formattedDate.format(format);
};
