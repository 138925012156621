import { Button, Icon } from 'semantic-ui-react';
import React from 'react';

const TabFilter = props => {
  const {type, filters, callback, active, subFilter, size} = props;

  if (filters && filters.length > 0) {
    const tabs =  filters.map(filterOption => {
      const { text, value, icon } = filterOption;
        return (
          <Button
            onClick={() => callback(type, value)}
            active={active === value ? true : false}
            key={value}
            style={subFilter ? { paddingRight: '7px', paddingLeft: '7px' } : { paddingRight: '16px', paddingLeft: '16px' }}
          >
            {icon ? (<Icon name={icon} />) : text}
          </Button>
        );
    });

    return (<Button.Group size={size}>{tabs}</Button.Group>)
  }

  return null;
};

export default TabFilter;
