import { gql } from '@apollo/client';

export const PROMOTED_PRODUCTS_QUERY = gql`
  query GetProducts(
    $shopId: Int
    $limit: Int
    $offset: Int
    $titleLike1: String
    $titleLike2: String
    $titleLike3: String
  ) {
    products(
      where: {
        _or: [
          { title: { _ilike: $titleLike1 } }
          { title: { _ilike: $titleLike2 } }
          { title: { _ilike: $titleLike3 } }
        ]
        shop_id: { _eq: $shopId }
        sizes_total: { _gte: 6 }
        type_id: { _eq: 1 }
        percentage: { _gte: 18 }
      }
      order_by: { percentage: desc }
      limit: 8
    ) {
      distinct_on: code
      url
      type_id
      title
      sub_type_id
      sizes
      shop {
        text
        url
        id
      }
      release_date
      ref_url
      reduced
      price
      final_price
      percentage
      only_app
      launch_method
      image
      id
      brand {
        id
        text
      }
      color {
        id
        text
      }
      gender {
        id
        text
        value
      }
      excluded
      discount_code
      discount
      code
      big_image
      crawler {
        date
      }
    }
    products_sizes {
      value
      type_id
      text
      order
      id
      filter2
      filter1
    }
  }
`;
