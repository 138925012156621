import { gql } from '@apollo/client';

export const FILTERS_QUERY = gql`
  query GetFilters {
    products_brands(where: { active: { _eq: true } }, order_by: { value: asc }) {
      id
      text
      value
      active
    }
    products_colors {
      id
      text
      value
    }
    products_gender {
      id
      parent_id
      text
      value
    }
    products_shops {
      id
      text
    }
    products_types {
      value
      text
      parent_id
      id
    }
    products_sizes(order_by: { order: asc }) {
      filter1
      filter2
      id
      text
      type_id
      value
    }
    products_shoes_models {
      value
      text
      parent_id
      brand_id
      id
    }
  }
`;

export const PRODUCTS_QUERY = gql`
  query GetProducts(
    $shopId: Int
    $brandId: Int
    $typeId: Int
    $genderIds: [Int!]
    $size: jsonb
    $subTypeId: Int
    $colorId: Int
    $orderBy: [products_order_by!]
    $limit: Int
    $offset: Int
    $shoesModelId: Int
    $shoesSubModelId: Int
    $sizesTotal: Int
  ) {
    products(
      where: {
        shop_id: { _eq: $shopId }
        brand_id: { _eq: $brandId }
        type_id: { _eq: $typeId }
        sub_type_id: { _eq: $subTypeId }
        gender_id: { _in: $genderIds }
        color_id: { _eq: $colorId }
        sizes: { _contains: $size }
        sizes_total: { _gt: $sizesTotal }
        shoes_model_id: { _eq: $shoesModelId }
        shoes_sub_model_id: { _eq: $shoesSubModelId }
      }
      order_by: $orderBy
      limit: $limit
      offset: $offset
    ) {
      url
      type_id
      title
      sub_type_id
      sizes
      shop {
        text
        url
        id
      }
      release_date
      ref_url
      reduced
      price
      final_price
      percentage
      only_app
      launch_method
      image
      id
      brand {
        id
        text
      }
      color {
        id
        text
      }
      gender {
        id
        text
        value
      }
      excluded
      discount_code
      discount
      code
      big_image
      crawler {
        date
      }
    }
    products_aggregate(
      where: {
        shop_id: { _eq: $shopId }
        brand_id: { _eq: $brandId }
        type_id: { _eq: $typeId }
        sub_type_id: { _eq: $subTypeId }
        gender_id: { _in: $genderIds }
        color_id: { _eq: $colorId }
        sizes: { _contains: $size }
        sizes_total: { _gt: $sizesTotal }
        shoes_model_id: { _eq: $shoesModelId }
        shoes_sub_model_id: { _eq: $shoesSubModelId }
      }
      order_by: $orderBy
    ) {
      aggregate {
        count
      }
    }
    sales(order_by: { shop: { text: asc } }) {
      id
      end_date
      discount
      created_date
      code
      shop_id
      shop {
        id
        text
        domain
      }
      limited_in_time
      image_url
      message
      sale_type
      start_date
      title
      url
    }
  }
`;

// SELECT ONLY LAST SHOPS DATA
// export const PRODUCTS_QUERY = gql`
//   query GetPosts($shopId: Int, $typeId: Int) {
//     apify(
//       distinct_on: actor
//       order_by: { actor: asc, created_date: desc }
//       where: { shop_id: { _eq: $shopId }, type_id: { _eq: $typeId } }
//     ) {
//       id
//       data
//       created_date
//       actor
//       shop_id
//       type_id
//     }
//     sales(order_by: { shop: { text: asc } }) {
//       id
//       end_date
//       discount
//       created_date
//       code
//       shop_id
//       shop {
//         id
//         text
//         domain
//       }
//       limited_in_time
//       image_url
//       message
//       sale_type
//       start_date
//       title
//       url
//     }
//   }
// `;
