import {
  allShops,
  atafUrl,
  distanceUrl,
  eobuwieUrl,
  footshopUrl,
  nikeUrl,
  worldboxUrl,
} from '../components/Steals/ProductBrowser/ProductBrowser.data';

export const shops = [
  {
    key: 0,
    text: 'Wszystkie',
    value: 'all',
    urls: allShops,
    multiBrands: true,
  },
  {
    key: 2,
    text: 'Nike.com',
    value: 'nike',
    urls: nikeUrl,
    multiBrands: false,
  },
  {
    key: 10,
    text: 'Adidas.pl',
    value: 'adidas',
    multiBrands: false,
    // urls: nikeUrl,
  },
  // {
  //   key: 11,
  //   text: 'Reebok.pl',
  //   value: 'reebok',
  //   multiBrands: false,
  //   // urls: nikeUrl,
  // },
  // {
  //   key: 3,
  //   text: 'Footshop.pl',
  //   value: 'footshop',
  //   urls: footshopUrl,
  // },
  // {
  //   key: 9,
  //   text: 'Chmielna20.pl',
  //   value: 'chmielna',
  //   urls: chmielnaUrl,
  // },
  // {
  //   key: 8,
  //   text: 'SklepKoszykarza.pl',
  //   value: 'sklepkoszykarza',
  //   urls: sklepKoszykarzaUrl,
  // },
  // {
  //   key: 4,
  //   text: 'Worldbox.pl',
  //   value: 'worldbox',
  //   urls: worldboxUrl,
  // },
  // {
  //   key: 5,
  //   text: 'Distance.pl',
  //   value: 'distance',
  //   urls: distanceUrl,
  // },
  // {
  //   key: 7,
  //   text: 'Ataf.pl',
  //   value: 'ataf',
  //   urls: atafUrl,
  // },
  // {
  //   key: 6,
  //   text: 'eObuwie.pl',
  //   value: 'eobuwie',
  //   urls: eobuwieUrl,
  //   multiBrands: true,
  // },
];

export const sortByOptions = [
  {
    key: 0,
    text: 'Okazje',
    value: 'discount',
  },
  {
    key: 1,
    text: 'Najtańsze',
    value: 'cheapest',
  },
  {
    key: 2,
    text: 'Najdroższe',
    value: 'mostExpensive',
  },
  // {
  //   key: 3,
  //   text: 'Najnowsze',
  //   value: 'newest',
  // },
];

export const filters = [
  // { text: 'Wszystko', value: 'wszystko', categories: [] },
  {
    text: 'Buty',
    value: 'buty',
    categories: [
      { text: 'Wszystkie', value: 'all' },
      {
        text: 'Air Max',
        value: 'airmax',
        categories: [
          { key: 0, text: 'Wszystkie', value: 'all' },
          { key: 1, text: 'Air Max 1', value: 'airmax1' },
          { key: 2, text: 'Air Max 90', value: 'airmax90' },
          { key: 3, text: 'Air Max 95', value: 'airmax95' },
          { key: 4, text: 'Air Max 97', value: 'airmax97' },
          { key: 5, text: 'Air Max 98', value: 'airmax98' },
          { key: 6, text: 'Air Max 200', value: 'airmax200' },
          { key: 7, text: 'Air Max 270', value: 'airmax270' },
          { key: 9, text: 'Air Max 2090', value: 'airmax2090' },
          { key: 10, text: 'Air Max Tailwind', value: 'airmaxtailwind' },
          { key: 11, text: 'Air Max Shox', value: 'airmaxshox' },
          { key: 12, text: 'Air Max Verona', value: 'airmaxverona' },
          { key: 13, text: 'Air Max Up', value: 'airmaxup' },
          { key: 14, text: 'Air Max Plus', value: 'airmaxplus' },
          { key: 15, text: 'Air Max Triax', value: 'airmaxtriax' },
          { key: 15, text: 'Air Max Dia', value: 'airmaxdia' },
          { key: 16, text: 'Air VaporMax', value: 'airmaxvapormax' },
          { key: 17, text: 'Air Max Graviton', value: 'airmaxgraviton' },
          { key: 18, text: 'Air Max Excee', value: 'airmaxexcee' },
          { key: 19, text: 'Air Max Invigor', value: 'airmaxinvigor' },
          { key: 20, text: 'MX 720', value: 'airmax720' },
          // { key: 21, text: 'Pozostałe', value: 'airmaxpozostale' },
        ],
      },
      { text: 'Do kosza', value: 'do-kosza' },
      { text: 'Air Force', value: 'airforce' },
      { text: 'Pozostałe', value: 'pozostale' },
    ],
    newValue: 1,
  },
  {
    text: 'Ciuchy',
    value: 'ciuchy',
    categories: [
      { text: 'Wszystkie', value: 'ciuchy', newValue: 2 },
      { text: 'Tshirty', value: 'tshirty', newValue: 4 },
      { text: 'Bluzy', value: 'bluzy', newValue: 5 },
      { text: 'Spodenki', value: 'spodenki', newValue: 6 },
      { text: 'Spodnie', value: 'spodnie', newValue: 7 },
      { text: 'Kurtki', value: 'kurtki', newValue: 8 },
    ],
    newValue: 2,
  },
  {
    text: 'Dodatki',
    value: 'dodatki',
    categories: [
      { text: 'Wszystkie', value: 'dodatki', newValue: 3 },
      { text: 'Czapki', value: 'czapki', newValue: 9 },
      { text: 'Nerki', value: 'nerki', newValue: 10 },
      { text: 'Torebki', value: 'torebki', newValue: 11 },
      { text: 'Plecaki', value: 'plecaki', newValue: 12 },
    ],
    newValue: 3,
  },
];

export const gender = [
  { text: 'Wszystkie', value: 'all', icon: 'users', newValue: 'all' },
  { text: 'Męskie', value: 'meskie', icon: 'male', newValue: 1 },
  { text: 'Damskie', value: 'damskie', icon: 'female', newValue: 2 },
  { text: 'Dzieci i Młodzież', value: 'dzieci', icon: 'child', newValue: 3 },
  // { text: 'Dzieci', value: 'dzieci', icon: 'kids', newValue: 3 },
  // { text: 'Chłopcy', value: 'chlopcy', icon: 'boys', newValue: 4 },
  // { text: 'Dziewczęta', value: 'dziewczeta', icon: 'girls', newValue: 5 },
];

// "44 2/3", ???
// "45 1/3", ???

export const shoesSizes = [
  { text: 'Wszystkie', value: 'all' },

  { text: 'EU 28', value: '28', filter1: '28' },
  { text: 'EU 28 1/2', value: '28_1_2', filter1: '28 1/2', filter2: '28.5' },

  { text: 'EU 29', value: '29', filter1: '29' },
  { text: 'EU 29 1/2', value: '29_1_2', filter1: '29 1/2', filter2: '29.5' },

  { text: 'EU 30', value: '30', filter1: '30' },
  { text: 'EU 30 1/2', value: '30_1_2', filter1: '30 1/2', filter2: '30.5' },

  { text: 'EU 31', value: '31', filter1: '31' },
  { text: 'EU 31 1/2', value: '31_1_2', filter1: '31 1/2', filter2: '31.5' },

  { text: 'EU 32', value: '32', filter1: '32' },

  { text: 'EU 33', value: '33', filter1: '33' },
  { text: 'EU 33 1/2', value: '33_1_2', filter1: '33 1/2', filter2: '33.5' },

  { text: 'EU 34', value: '34', filter1: '34' },

  { text: 'EU 35', value: '35', filter1: '35' },

  ///////////////////

  { text: 'EU 35.5', value: '35_1_2', filter1: '35 1/2', filter2: '35.5' },

  { text: 'EU 36', value: '36', filter1: '36' },
  { text: 'EU 36 1/2', value: '36_1_2', filter1: '36 1/2', filter2: '36.5' },
  { text: 'EU 36 2/3', value: '36_2_3', filter1: '36 2/3' },

  { text: 'EU 37', value: '37', filter1: '37' },
  { text: 'EU 37  1/3', value: '37_1_3', filter1: '37 1/3' },
  { text: 'EU 37  1/2', value: '37_1_2', filter1: '37 1/2', filter2: '37.5' },

  { text: 'EU 38', value: '38', filter1: '38' },
  { text: 'EU 38  1/2', value: '38_1_2', filter1: '38 1/2', filter2: '38.5' },
  { text: 'EU 38  2/3', value: '38_2_3', filter1: '38 2/3' },

  { text: 'EU 39', value: '39', filter1: '39' },
  { text: 'EU 39 1/3', value: '39_1_3', filter1: '39 1/3', filter2: '39.5' },
  { text: 'EU 39 1/2', value: '39_1_2', filter1: '39 1/2' },

  { text: 'EU 40', value: '40', filter1: '40' },
  { text: 'EU 40 1/2', value: '40_1_2', filter1: '40 1/2', filter2: '40.5' },
  { text: 'EU 40 2/3', value: '40_2_3', filter1: '40 2/3' },

  { text: 'EU 41', value: '41', filter1: '41' },
  { text: 'EU 41 1/3', value: '41_1_3', filter1: '41 1/3' },
  { text: 'EU 41 1/2', value: '41_1_2', filter1: '41 1/2', filter2: '41.5' },

  { text: 'EU 42', value: '42', filter1: '42' },
  { text: 'EU 42 1/2', value: '42_1_2', filter1: '42 1/2', filter2: '42.5' },
  { text: 'EU 42 2/3', value: '42_2_3', filter1: '42 2/3' },

  { text: 'EU 43', value: '43', filter1: '43' },
  { text: 'EU 43 1/3', value: '43_1_3', filter1: '43 1/3' },
  { text: 'EU 43 1/2', value: '43_1_2', filter1: '43 1/2', filter2: '43.5' },

  { text: 'EU 44', value: '44', filter1: '44' },
  { text: 'EU 44 1/2', value: '44_1_2', filter1: '44 1/2', filter2: '44.5' },
  { text: 'EU 44 2/3', value: '44_2_3', filter1: '44 2/3' },

  { text: 'EU 45', value: '45', filter1: '45' },
  { text: 'EU 45 1/3', value: '45_1_3', filter1: '45 1/3' },
  { text: 'EU 45 1/2', value: '45_1_2', filter1: '45 1/2', filter2: '45.5' },

  { text: 'EU 46', value: '46', filter1: '46' },
  { text: 'EU 46 1/2', value: '46_1_2', filter1: '46 1/2', filter2: '46.5' },
  { text: 'EU 46 2/3', value: '46_2_3', filter1: '46 2/3' },

  { text: 'EU 47', value: '47', filter1: '47' },
  { text: 'EU 47 1/3', value: '47_1_3', filter1: '47 1/3' },
  { text: 'EU 47 1/2', value: '47_1_2', filter1: '47 1/2', filter2: '47.5' },

  { text: 'EU 48', value: '48', filter1: '48' },
  { text: 'EU 48 1/2', value: '48_1_2', filter1: '48 1/2', filter2: '48.5' },
  { text: 'EU 48 2/3', value: '48_2_3', filter1: '48 2/3' },

  { text: 'EU 49', value: '49', filter1: '49' },
  { text: 'EU 49 1/2', value: '49_1_2', filter1: '49 1/2', filter2: '49.5' },
  { text: 'EU 49 1/3', value: '49_1_3', filter1: '49 1/3' },

  ////////

  { text: 'EU 50', value: '50', filter1: '50' },
  { text: 'EU 50 1/2', value: '50_1_2', filter1: '50 1/2', filter2: '50.5' },
  { text: 'EU 50 2/3', value: '50_2_3', filter1: '50 2/3' },

  { text: 'EU 51', value: '51', filter1: '51' },
  { text: 'EU 51 1/2', value: '51_1_2', filter1: '51 1/2', filter2: '51.5' },
  { text: 'EU 51 1/3', value: '51_1_3', filter1: '51 1/3' },

  { text: 'EU 52', value: '52', filter1: '52' },
  { text: 'EU 52 1/2', value: '52_1_2', filter1: '52 1/2', filter2: '52.5' },
  { text: 'EU 52 2/3', value: '52_2_3', filter1: '52 2/3' },

  { text: 'EU 53', value: '53', filter1: '53' },
  { text: 'EU 53 1/3', value: '53_1_3', filter1: '53 1/3' },
  { text: 'EU 53 1/2', value: '53_1_2', filter1: '53 1/2', filter2: '53.5' },

  { text: 'EU 54', value: '54', filter1: '54' },
  { text: 'EU 54 1/2', value: '54_1_2', filter1: '54 1/2', filter2: '54.5' },
  { text: 'EU 54 2/3', value: '54_2_3', filter1: '54 2/3' },

  { text: 'EU 55', value: '55', filter1: '55' },
  { text: 'EU 55 1/2', value: '55_1_2', filter1: '55 1/2', filter2: '55.5' },
  { text: 'EU 55 2/3', value: '55_2_3', filter1: '55 2/3' },
];

export const clothesSizes = [
  { text: 'Wszystkie', value: 'all' },
  { text: 'XS', value: 'XS' },
  { text: 'S', value: 'S' },
  { text: 'M', value: 'M' },
  { text: 'L', value: 'L' },
  { text: 'XL', value: 'XL' },
  { text: '2XL', value: '2XL' },
  { text: '3XL', value: '3XL' },
];

// { text: 'Alpha Industries', value: 'alphaindustries'},
export const brands = [
  { text: 'Wszystkie', value: 'all', newValue: 'all' },
  { text: 'adidas', value: 'adidas', newValue: 26 },
  { text: 'Asics', value: 'asics', newValue: 1 },
  { text: 'Billionaire Boys Club', value: 'billionaireboysclub', newValue: 2 },
  { text: 'Carhartt', value: 'carhartt', newValue: 3 },
  { text: 'Champion', value: 'champion', newValue: 4 },
  { text: 'Comme Des Garçons Play', value: 'commedesgarconsplay', newValue: 5 },
  { text: 'Converse', value: 'converse', newValue: 6 },
  { text: 'Fila', value: 'fila', newValue: 7 },
  { text: 'HUF', value: 'huf', newValue: 8 },
  { text: 'Jordan', value: 'jordan', newValue: 9 },
  { text: 'Kangaroos', value: 'kangaroos', newValue: 10 },
  { text: 'Kappa', value: 'kappa', newValue: 11 },
  { text: 'Le Coq Sportif', value: 'lecoqsportif', newValue: 12 },
  { text: 'Napapijri', value: 'napapijri', newValue: 13 },
  { text: 'New Balance', value: 'newbalance', newValue: 14 },
  { text: 'New Era', value: 'newera', newValue: 15 },
  { text: 'Nike', value: 'nike', newValue: 16 },
  { text: 'Pleasures', value: 'pleasures', newValue: 17 },
  { text: 'Puma', value: 'puma', newValue: 18 },
  { text: 'Reebok', value: 'reebok', newValue: 19 },
  { text: 'Stüssy', value: 'stussy', newValue: 20 },
  { text: 'The North Face', value: 'thenorthface', newValue: 21 },
  { text: 'Timberland', value: 'timberland', newValue: 22 },
  { text: 'Tommy Hilfiger', value: 'tommyhilfiger', newValue: 23 },
  { text: 'Vans', value: 'vans', newValue: 24 },
  { text: 'Y-3', value: 'y3', newValue: 25 },
];

export const colors = [
  { text: 'Wszystkie', value: 'all', newValue: 'all' },
  { text: 'Biały', value: 'bialy', newValue: 1 },
  { text: 'Brązowy', value: 'brazowy', newValue: 2 },
  { text: 'Czarny', value: 'czarny', newValue: 3 },
  { text: 'Czerwony', value: 'czerwony', newValue: 4 },
  { text: 'Niebieski', value: 'niebieski', newValue: 5 },
  { text: 'Pomarańczowy', value: 'pomaranczowy', newValue: 6 },
  { text: 'Purpurowy', value: 'purpurowy', newValue: 7 },
  { text: 'Różowy', value: 'rozowy', newValue: 8 },
  { text: 'Szary', value: 'szary', newValue: 9 },
  { text: 'Wielokolorowy', value: 'wielokolorowy', newValue: 10 },
  { text: 'Zielony', value: 'zielony', newValue: 11 },
  { text: 'Żółty', value: 'zolty', newValue: 12 },
  // Czerń
  // Niebieski
  // Brązowy
  // Zieleń
  // Szary
  // Wielokolorowe
  // Pomarańczowy
  // Różowy
  // Fiolet
  // Czerwony
  // Biel
  // Żółć
];
