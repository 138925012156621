import React from 'react';
import { Segment, Grid } from 'semantic-ui-react';
// import Posts from '../Posts/Posts';
// import Sales from '../Sales/Sales';
import IndexCalendar from './IndexCalendar';
import Social from './Social';
import Search from './Search/Search';
import NewReleases from '../NewReleases/NewReleases';
import PromotedProducts from './PromotedProducts/PromotedProducts';
import More from '../shared/More';
import Telegram from "./Telegram/Telegram";

// const checkIfLocalhost = () => {
//   if (window.location.href === 'http://localhost:3006/') return true;
//   return false;
// };

function Index() {
  // const showTheSearchBox = checkIfLocalhost();

  return (
    <>
      <Search />
      <IndexCalendar />
      <More url="/dropy" title="Więcej Dropów" />
      <br />

      {/*{showTheSearchBox && <Search/>}*/}
      <Segment vertical className="segmentInverted introBackground ">
        <NewReleases limit={8} />
        <More url="/nowosci" title="Więcej Nowości" />
        <br />
        <br />
      </Segment>

      <PromotedProducts limit={8} inlineLoader />
      <br />
      <br />

      {/*<Segment vertical className="segmentInverted introBackground ">*/}

      {/*</Segment>*/}
      {/*<Posts limit={4} inlineLoader />*/}
      {/*<br />*/}
      {/*<br />*/}

      {/*<Sales limit={4} inlineLoader />*/}
      {/*<br/>*/}
      {/*<br/>*/}
      {/*<Segment vertical className="segmentInverted introBackground ">*/}
        <Telegram/>
        <Social />

      {/*</Segment>*/}
    </>
  );
}

export default Index;
