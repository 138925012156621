import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ResponsiveCards from '../../shared/ResponsiveCards';
import EmptyMessage from './EmptyMessage';
import Loading from '../../shared/Loading';
import Filter from './Filter/Filter';
import { productMapper } from '../../../utils/productMapper';
import { bottomOfThePage } from '../../shared/bottomOfThePage';
import { filters } from '../../../utils/data';
import { postsList, checkPromo, isBeta, fetchDataWithApollo } from './ProductBrowserUtils';
import { getQueryParams, getDefaultFilter, updateCurrentUrlInBrowser, findByValue } from './Filter/Filter.utils';
import { debounce, getPathParams } from '../../../utils/utils';
import PromoModal from '../../shared/ProductCard/PromoModal';
import { client } from '../../../utils/apolloClient';
import { FILTERS_QUERY } from './Products.gql';

const itemsPerPage = 12;
const itemsOffset = 0;

const ProductBrowser = props => {
  const { shops, sortByOptions } = props;

  // set default filter based on URL
  const location = useLocation();
  const pathParams = getPathParams(location);
  const queryParams = getQueryParams(location.search);
  const defaultFilterBasedOnRouting = getDefaultFilter(shops, sortByOptions, pathParams, queryParams);

  const [items, setItems] = useState([]);
  const [itemsCounter, setItemsCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filtersList, setFiltersList] = useState([]);
  const [offset, setOffset] = useState(itemsOffset);
  const [activeFilter, setActiveFilter] = useState(defaultFilterBasedOnRouting);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [promoModalData, setPromoModalData] = useState({});

  const currentShop = activeFilter.shop ? activeFilter.shop : {};
  const promoDiscount = checkPromo(currentShop.value);

  const beta = isBeta();

  const showMore = async () => {
    const newOffset = offset + itemsPerPage;
    if (newOffset <= itemsCounter) {
      setOffset(newOffset);
      const { products } = await fetchAndMapData(activeFilter, '', newOffset);
      let newData = [...items, ...products];
      setItems(newData);
    }
    setLoadingMore(false);
  };

  const onScroll = () => {
    const bottom = bottomOfThePage('.cards > .card:last-child');
    if (bottom && !loadingMore) {
      setLoadingMore(true);
    }
  };

  const fetchFilters = async () => {
    const response = await client.query({
      query: FILTERS_QUERY,
    });
    setFiltersList(response.data);
    return response.data;
  };

  const fetchAndMapData = async (filter, filtersData, newOffset) => {
    const queryOffset = newOffset !== undefined ? newOffset : offset;
    let { products, count } = await fetchDataWithApollo(filter, filtersData || filtersList, itemsPerPage, queryOffset);
    products = productMapper(products, filtersData || filtersList);
    return { products, count };
  };

  useEffect(() => {
    if (loadingMore) {
      showMore();
    }
  }, [loadingMore]); // eslint-disable-line react-hooks/exhaustive-deps

  // scroll listener
  useEffect(() => {
    if (window) {
      window.addEventListener(
        'scroll',
        debounce(e => {
          onScroll();
        }, 50)
      );
    }
  });

  useEffect(() => {
    const loadData = async () => {
      const filtersData = await fetchFilters();
      const { products, count } = await fetchAndMapData(activeFilter, filtersData);
      let results = products;
      setItems(results);
      setItemsCounter(count);
      setLoading(false);
    };

    loadData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterUpdate = async (type, value) => {
    setOffset(0);
    const newActiveFilter = { ...activeFilter };
    setLoading(true);

    // console.log('type', type);
    // console.log('value', value);

    // if ((type && value) || type === 'onlyLotsOfSizes') {
    //   let results = [...data];

    // Update active filter
    newActiveFilter[type] = value;

    // console.log('TEs newActiveFilter', newActiveFilter);

    if (newActiveFilter.mainCategory === 'dodatki') {
      newActiveFilter.onlyLotsOfSizes = false;
    }

    // only a lots of sizes
    if (type === 'onlyLotsOfSizes') {
      newActiveFilter.onlyLotsOfSizes = !activeFilter.onlyLotsOfSizes;
      if (newActiveFilter.onlyLotsOfSizes) {
        newActiveFilter.shoeSize = 'all';
        newActiveFilter.clothesSize = 'all';
      }
    }

    if (type === 'shoeSize' || type === 'clothesSize') {
      newActiveFilter.onlyLotsOfSizes = false;
    }

    // if shop changed fetch new data
    if (type === 'shop') {
      const shop = findByValue(shops, value, 'value');
      newActiveFilter.shop = shop;
    }

    // reset filter if shop or main category changed
    if (type === 'shop' || type === 'mainCategory') {
      newActiveFilter.brand = 'all';
      newActiveFilter.subCategory = '';
      newActiveFilter.model = 'all';
      newActiveFilter.subModel = 'all';
      newActiveFilter.shoeSize = 'all';
    }

    if (type === 'model') {
      newActiveFilter.subModel = 'all';
    }

    // if (type === 'subCategory') {
    //   newActiveFilter.model = 'all';
    //   newActiveFilter.subModel = '';
    // }

    setActiveFilter(newActiveFilter);

    // update URL params to reflect new filters
    updateCurrentUrlInBrowser(pathParams, newActiveFilter);

    const { products, count } = await fetchAndMapData(newActiveFilter, '', 0);
    const newProducts = [...products];

    setItems(newProducts);
    setItemsCounter(count);
    setLoading(false);
  };

  const onPromoModal = data => {
    setPromoModalData(data);
    setShowPromoModal(true);
  };

  const onPromoModalClose = () => {
    setPromoModalData({});
    setShowPromoModal(false);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Filter
            shops={shops}
            sortByOptions={sortByOptions}
            filters={filters}
            activeFilter={activeFilter}
            counter={itemsCounter}
            callback={handleFilterUpdate}
            currentShop={currentShop}
            lastFetch={items.length > 0 ? items[0].date : ''}
            filtersList={filtersList}
          />
          {items.length > 0 ? (
            <ResponsiveCards>{postsList(items, onPromoModal, beta)}</ResponsiveCards>
          ) : (
            <EmptyMessage />
          )}
          {beta && (
            <PromoModal
              openModal={showPromoModal}
              closeModal={onPromoModalClose}
              data={promoModalData}
              promoType="okazja"
            />
          )}
        </div>
      )}
    </>
  );
};

export default ProductBrowser;
