import React, { useState, useEffect } from 'react';
import Loading from '../shared/Loading';
import ResponsiveCards from '../shared/ResponsiveCards';
import { postsList } from './utils';
import { fetchData } from '../../utils/utils';
import { urls, mapData, sortByDateAndBrand, findsEventsWithDate, filterOldEvents, uniqValues, justShoes, filterUnwantedProducts } from './Calendar.utils';
import './Calendar.css';
import PromoModal from '../shared/ProductCard/PromoModal';
import { isBeta } from '../Steals/ProductBrowser/ProductBrowserUtils';

const stores = [
  {title: 'Nike', url: 'https://www.awin1.com/cread.php?awinmid=16334&awinaffid=688439&ued=https%3A%2F%2Fwww.nike.com%2Fpl%2Fw%2Fnowosci-3n82y%3Fsort%3Dnewest'},
  {title: 'Nike APP', url: 'https://www.awin1.com/cread.php?awinmid=16334&awinaffid=688439&clickref=&ued=https://www.nike.com/pl/nike-app'},
  {title: 'Nike SNKRS', url: 'https://www.awin1.com/cread.php?awinmid=16334&awinaffid=688439&ued=https%3A%2F%2Fwww.nike.com%2Fpl%2Flaunch%3Fs%3Dupcoming'},
  {title: 'Yeezy', url: 'https://go.skimresources.com?id=157312X1623776&xs=1&url=https%3A%2F%2Fwww.adidas.pl%2Fyeezy'},
  {title: 'adidas', url: 'https://go.skimresources.com?id=157312X1623776&xs=1&url=https%3A%2F%2Fwww.adidas.pl%2Fdaty-premiery'},
  // {title: 'Reebok', url: 'https://go.skimresources.com?id=157312X1623776&xs=1&url=https%3A%2F%2Fwww.reebok.pl%2Fdaty-premiery'},
]

const Links = () => {
   return stores.map((store, index) => {
     const {title, url} = store;
     return               <><a
         href={url}
         target="_blank"
     >{title}</a>{index + 1 < stores.length ? ', ' : ''}</>
   })
}

function Calendar(props) {
  const beta = isBeta();
  const { perPage = '' } = props;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [promoModalData, setPromoModalData] = useState({});

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      let events = await fetchData(urls);

      events = mapData(events);

      // current events
      let currentEvents = findsEventsWithDate(events);
      currentEvents = filterOldEvents(currentEvents);

      // currentEvents.push({
      //   title: 'test',
      //   date: "2023-04-17T22:00:00.000Z"
      // })
      // console.log('currentEvents',currentEvents)
      currentEvents = justShoes(currentEvents);
      currentEvents = filterUnwantedProducts(currentEvents);
      // console.log('final',currentEvents)
      currentEvents = uniqValues(currentEvents);
      currentEvents = currentEvents.sort(sortByDateAndBrand);

      if (perPage) {
        currentEvents = currentEvents.slice(0, perPage);
        setItems(currentEvents);
        setLoading(false);
      } else {
        setItems(currentEvents);
        setLoading(false);
      }
    };

    loadData();
  }, []);

  const onPromoModal = data => {
    setPromoModalData(data);
    setShowPromoModal(true);
  };

  const onPromoModalClose = () => {
    setPromoModalData({});
    setShowPromoModal(false);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div id="calendar">
          {!perPage && (
            <p style={{ textAlign: 'center', paddingBottom: '20px' }}>
              Monitor dropów:{' '}
              <Links/>
            </p>
          )}
          <ResponsiveCards>{postsList(items, onPromoModal, beta, 'post')}</ResponsiveCards>
          {!perPage && (
            <>
              <br />
              <br />
              <br />
              <br />
            </>
          )}
          {beta && (
            <PromoModal
              openModal={showPromoModal}
              closeModal={onPromoModalClose}
              data={promoModalData}
              promoType="drop"
            />
          )}
        </div>
      )}
    </>
  );
}

export default Calendar;
