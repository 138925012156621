import { awinURL, awinaffid, webgainsURL, skimlinksURL } from './shops';

// awin
const generateAwinRefLink = (domainId, url, extraTracking) =>
  `${awinURL}${domainId}&awinaffid=${awinaffid}&clickref=streetdealspl&ued=${url}${extraTracking || ''}`;

// webgains
const generateWebgainsRefLink = (domainId, url) => `${webgainsURL}${domainId}&wgtarget=${url}`;

// skimlinks
const generateSkimlinksRefLink = url => `${skimlinksURL}${url}`;

export const convertUrl = (domain, agency, domainId, extraTracking) => {
  let refUrl = 'https://' + domain;

  // console.log('domain', domain);
  // console.log('agency', agency);
  // console.log('domainId', domainId);

  refUrl = encodeURIComponent(refUrl);

  if (agency === 'awin' || agency === 1) {
    refUrl = generateAwinRefLink(domainId, refUrl, extraTracking);
  }

  if (agency === 'webgains' || agency === 4) {
    refUrl = generateWebgainsRefLink(domainId, refUrl);
  }

  if (agency === 'skimlinks' || agency === 2) {
    refUrl = generateSkimlinksRefLink(refUrl);
  }

  return refUrl;
};
