import React from 'react';
// import PostCard from '../shared/PostCard';
import ProductCard from '../shared/ProductCard/ProductCard';
import PostCard from '../shared/PostCard';
import { sortByDate } from '../../utils/utils';

export const getPrice = input => {
  let price = '';
  const res = input.match(/<span class="tribe-block__event-price__description">(.*?)<\/span>/g);
  // console.log('getPrice', res);
  if (res && res[0]) {
    price = res[0].replace(/<\/?[^>]+(>|$)/g, '');
  }

  return price;
};

export const postsList = (posts, onPromoModal, beta, cardType) => {
  return posts.map((post, index) => {
    if(cardType === 'post') return <PostCard key={index} data={post} onPromoModal={onPromoModal} beta={beta} />;
    return <ProductCard key={index} data={post} onPromoModal={onPromoModal} beta={beta} />;
  });
};

export const convertDateToString = date => {
  return date.toISOString().split('T')[0];
};

export const getCalendarData = async perPage => {
  const result = await fetchCalendarData(perPage);
  const mappedEvents = mapData(result.events);
  // const filteredEvents = filterOldEvents(mappedEvents);
  // return sortByDate(filteredEvents, 'date').reverse();
};

export const fetchCalendarData = async (perPage = 4) => {
  /*
  SWAGGER
  https://petstore.swagger.io/
  https://streetwear.pl/wp-json/tribe/events/v1/doc
   */
  const today = convertDateToString(new Date());
  const res = await fetch(`https://streetwear.pl/wp-json/tribe/events/v1/events?start=${today}&per_page=${perPage}`, {
    mode: 'cors',
  });
  return res.json();
};

export const getImage = input => {
  const regex = /[^"'=\s]+\.(jpe?g|png|gif)/g;
  const res = regex.exec(input);
  // console.log('images', res);
  return res[0];
};

export const doubleQuoteFix = input => {
  return input
    .replace(/&#8220;/gi, '"')
    .replace(/&#8221;/gi, '"')
    .replace(/&#8216;/gi, '"')
    .replace(/&#8217;/gi, '"');
};

export const mapData = data => {
  return data.map(item => {
    const { description, start_date, image, website, url, title } = item;
    const priceIsNotEmpty = getPrice(description);
    const price = priceIsNotEmpty || 'Cena: ???';
    // const desc = description.replace(/<[^>]*>?/gm, '';
    const buyUrl = website || '';
    return {
      message: doubleQuoteFix(title) || '',
      imageUrl: image.url || '',
      date: start_date || '',
      discussUrl: url || '',
      price: price.replace(/Cena:/gi, '').replace(/cena:/gi, ''),
      buyUrl,
      event: true,
    };
  });
};
