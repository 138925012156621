export const dropsData = [
  {
    country: '',
    name: 'Chmielna20',
    original: 'https://chmielna20.pl/calendar',
    ref: 'https://sdeals.pl/l2k0',
    instagram: 'https://www.instagram.com/chmielna20lab/',
  },
  {
    country: '',
    name: 'SH Store',
    original: 'https://shstore.eu',
    ref: 'https://shstore.eu',
    instagram: 'https://www.instagram.com/shstore.eu/',
  },
  {
    country: '',
    name: 'Footshop',
    original: 'https://releases.footshop.com/',
    ref: 'https://sdeals.pl/cscv',
    instagram: 'https://www.instagram.com/footshop',
  },
  // {
  //   country: '',
  //   name: 'Sneaker Studio',
  //   original: 'https://sneakerstudio.pl/pol_m_Coming-Soon-623.html',
  //   ref: 'https://sdeals.pl/irbc',
  //     instagram: ''
  // },
  {
    country: '',
    name: 'Sneakers n Stuff',
    original: 'https://www.sneakersnstuff.com/en/937/sns-raffles',
    ref: 'https://sdeals.pl/1q2g',
    instagram: 'https://www.instagram.com/sneakersnstuff/',
  },
  {
    country: '',
    name: '43einhalb',
    original: 'https://www.43einhalb.com/en/coming-soon"',
    ref: 'https://sdeals.pl/92p3',
    instagram: 'https://www.instagram.com/43einhalb',
  },

  // {
  //   country: '',
  //   name: 'Foot District',
  //   original: 'https://footdistrict.com/en/upcoming-releases',
  //   ref: 'https://sdeals.pl/edm7',
  // },
  {
    country: '',
    name: 'END',
    original: 'https://launches.endclothing.com/',
    ref: 'https://sdeals.pl/fejg',
    instagram: 'https://www.instagram.com/end_clothing',
  },
  {
    country: '',
    name: 'Seven Store',
    original: 'https://launches.sevenstore.com/',
    ref: 'https://sdeals.pl/yfkz',
    instagram: 'https://www.instagram.com/sevenstorehq',
  },
  // {
  //   country: '',
  //   name: 'Footlocker',
  //   original: 'https://www.footlocker.pl/en/content/release_calendar',
  //   ref: 'https://sdeals.pl/4306',
  //     instagram: ''
  // },
  // {
  //   country: '',
  //   name: 'Offspring',
  //   original: 'https://www.offspring.co.uk/view/category/offspring_catalog/1?sort=releaseDate',
  //   ref: 'https://sdeals.pl/v0r7',
  //     instagram: ''
  // },
  {
    country: '',
    name: 'Overkill',
    original: 'https://www.overkillshop.com/en/',
    ref: 'https://sdeals.pl/3dcr',
    instagram: 'https://www.instagram.com/overkillshop/',
  },
  {
    country: '',
    name: 'Noirfonce EU',
    original: 'https://noirfonce.eu/collections/whatsnew',
    ref: 'https://sdeals.pl/z76l',
    instagram: 'https://www.instagram.com/noir.fonce/',
  },
  // {
  //   country: '',
  //   name: 'Sole Kitchen',
  //   original: 'https://www.solekitchen.de/en/coming-soon/',
  //   ref: 'https://sdeals.pl/2uob',
  // },
  // { country: '', name: 'Sneakerbaas', original: 'https://launches.sneakerbaas.com/', ref: 'https://sdeals.pl/6jwx' },
  {
    country: '',
    name: 'Nicke Kicks',
    original: 'https://shopnicekicks.com/pages/calendar',
    ref: 'https://sdeals.pl/n5cg',
    instagram: 'https://www.instagram.com/shopnicekicks/',
  },
  {
    country: '',
    name: 'Slam Jam',
    original: 'https://www.slamjam.com/en_DE/drops.html',
    ref: 'https://sdeals.pl/cjvb',
    instagram: 'https://www.instagram.com/slamjammilano/',
  },
  {
    country: '',
    name: 'Allike',
    original: 'https://www.allikestore.com/german/coming-soon.html',
    ref: 'https://sdeals.pl/ar0k',
    instagram: 'https://www.instagram.com/allikestore/',
  },
  {
    country: '',
    name: 'BSTN',
    original: 'https://www.bstn.com/upcoming-releases',
    ref: 'https://sdeals.pl/htf0',
    instagram: 'https://www.instagram.com/bstnstore/',
  },
  // {
  //   country: '',
  //   name: 'Tint',
  //   original: 'https://www.tint-store.com/de-de/coming-soon/',
  //   ref: 'https://sdeals.pl/1hm8',
  //   instagram: '',
  // },
  {
    country: '',
    name: 'Footpatrol',
    original: 'https://www.footpatrol.com/page/launch-page/',
    ref: 'https://sdeals.pl/5kvs',
    instagram: 'https://www.instagram.com/footpatrol_ldn/',
  },
  // {
  //   country: '',
  //   name: 'Size UK',
  //   original: 'https://www.size.co.uk/page/sizepreviews-launches/',
  //   ref: 'https://sdeals.pl/0aye',
  //   instagram: '',
  // },
  {
    country: '',
    name: 'Afew',
    original: 'https://en.afew-store.com/collections/sneaker-raffles',
    ref: 'https://en.afew-store.com/collections/sneaker-raffles',
    instagram: 'https://www.instagram.com/afewstore/',
  },
  {
    country: '',
    name: 'Asphaltgold',
    original: 'https://www.asphaltgold.com/en/category/men/sneaker/coming-soon/',
    ref: 'https://www.asphaltgold.com/en/category/men/sneaker/coming-soon/',
    instagram: 'https://www.instagram.com/asphaltgold_sneakerstore/',
  },
  {
    country: '',
    name: 'Hanon',
    original: 'https://launches.hanon-shop.com/collections/launch',
    ref: 'https://launches.hanon-shop.com/collections/launch',
    instagram: 'https://www.instagram.com/hanonshop/',
  },
  {
    country: '',
    name: 'Fenom',
    original: 'https://www.fenom.com/en/234-coming-soon',
    ref: 'https://www.fenom.com/en/234-coming-soon',
    instagram: 'https://www.instagram.com/fenom/',
  },
  {
    country: '',
    name: 'Tres Bien',
    original: 'https://tres-bien.com/new-arrivals',
    ref: 'https://tres-bien.com/new-arrivals',
    instagram: 'https://www.instagram.com/tresbien_official/',
  },
  {
    country: '',
    name: 'Nigramercato',
    original: 'https://nigramercato.com/en/200-launches',
    ref: 'https://nigramercato.com/en/200-launches',
    instagram: 'https://www.instagram.com/nigramercato/',
  },
  {
    country: '',
    name: 'Sneakers76',
    original: 'https://www.sneakers76.com/en/73-release',
    ref: 'https://www.sneakers76.com/en/73-release',
    instagram: 'https://www.instagram.com/sneakers76/',
  },
  {
    country: '',
    name: 'Goodhood',
    original: 'https://launches.goodhoodstore.com/',
    ref: 'https://launches.goodhoodstore.com/',
    instagram: 'https://www.instagram.com/goodhood/',
  },
  {
    country: '',
    name: 'Naked',
    original: 'https://www.nakedcph.com/en/60/releases',
    ref: 'https://www.nakedcph.com/en/60/releases',
    instagram: 'https://www.instagram.com/nakedcph/',
  },
  {
    country: '',
    name: '4elementos',
    original: 'https://4elementos.es/releases/',
    ref: 'https://4elementos.es/releases/',
    instagram: 'https://www.instagram.com/4elementosofficial/',
  },
  {
    country: '',
    name: 'Luisaviaroma',
    original: 'https://www.luisaviaroma.com/en-pl/privilege-program/sneakers-club',
    ref: 'https://www.luisaviaroma.com/en-pl/privilege-program/sneakers-club',
    instagram: 'https://www.instagram.com/luisaviaroma/',
  },
  {
    country: '',
    name: 'Bgastore',
    original: 'https://bdgastore.com/blogs/upcoming-releases',
    ref: 'https://bdgastore.com/blogs/upcoming-releases',
    instagram: 'https://www.instagram.com/bodega/',
  },
  {
    country: '',
    name: 'Titolo',
    original: 'http://en.titoloshop.com/titolo/raffle/',
    ref: 'http://en.titoloshop.com/titolo/raffle/',
    instagram: 'https://www.instagram.com/titoloshop/',
  },
  {
    country: '',
    name: 'Zupport',
    original: 'https://zupport.de/raffle-start',
    ref: 'https://zupport.de/raffle-start',
    instagram: 'https://www.instagram.com/zupportrier/',
  },
  {
    country: '',
    name: 'The Next Door',
    original: 'https://thenextdoor.fr/pages/raffles',
    ref: 'https://thenextdoor.fr/pages/raffles',
    instagram: 'https://www.instagram.com/thenextdoor/',
  },
  {
    country: '',
    name: 'Yeezy Supply',
    original: 'https://www.yeezysupply.com/',
    ref: 'https://www.yeezysupply.com/',
    instagram: '',
  },
  {
    country: '',
    name: 'Back Door',
    original: 'https://back-door.it/raffle-back-door/',
    ref: 'https://back-door.it/raffle-back-door/',
    instagram: '',
  },
  {
    country: '',
    name: 'Wood Wood',
    original: 'https://www.woodwood.com/en/150/upcoming-releases',
    ref: 'https://www.woodwood.com/en/150/upcoming-releases',
    instagram: '',
  },
  {
    country: '',
    name: 'Maha',
    original: 'https://shop.maha-amsterdam.com/us/launches/',
    ref: 'https://shop.maha-amsterdam.com/us/launches/',
    instagram: '',
  },
  {
    country: '',
    name: 'Smets',
    original: 'https://smets.lu/pages/launches',
    ref: 'https://smets.lu/pages/launches',
    instagram: '',
  },
  {
    country: '',
    name: 'Wellgosh',
    original: 'https://launches.wellgosh.com/',
    ref: 'https://launches.wellgosh.com/',
    instagram: '',
  },
  {
    country: '',
    name: 'Beamhill',
    original: 'https://beamhill.fi/launches/page/8/',
    ref: 'https://beamhill.fi/launches/page/8/',
    instagram: '',
  },
  {
    country: '',
    name: 'Woei',
    original: 'https://www.woei-webshop.nl/en/raffle/',
    ref: 'https://www.woei-webshop.nl/en/raffle/',
    instagram: '',
  },
  {
    country: '',
    name: 'Anrosa',
    original: 'https://www.anrosa-store.com/raffle',
    ref: 'https://www.anrosa-store.com/raffle',
    instagram: '',
  },
  {
    country: '',
    name: '18montrose',
    original: 'https://www.18montrose.com/Launches',
    ref: 'https://www.18montrose.com/Launches',
    instagram: '',
  },
  {
    country: '',
    name: 'Consortium',
    original: 'https://releases.consortium.co.uk/releases.html',
    ref: 'https://releases.consortium.co.uk/releases.html',
    instagram: '',
  },
  {
    country: '',
    name: 'Yme',
    original: 'https://www.ymeuniverse.com/en/11/releases',
    ref: 'https://www.ymeuniverse.com/en/11/releases',
    instagram: '',
  },
  {
    country: '',
    name: 'Amigo',
    original: 'https://amigoskateshop.com/blogs/sneaker',
    ref: 'https://amigoskateshop.com/blogs/sneaker',
    instagram: '',
  },
  {
    country: '',
    name: 'Beyond',
    original: 'https://en.beyondstore.fi/category/12/uutuudet',
    ref: 'https://en.beyondstore.fi/category/12/uutuudet',
    instagram: '',
  },
  {
    country: '',
    name: 'Shinzo',
    original: 'https://www.shinzo.paris/en/63-new-releases?p=2',
    ref: 'https://www.shinzo.paris/en/63-new-releases?p=2',
    instagram: '',
  },
  {
    country: '',
    name: 'Panthers',
    original: 'https://www.panthers.be/en/new-arrivals/',
    ref: 'https://www.panthers.be/en/new-arrivals/',
    instagram: '',
  },
  {
    country: '',
    name: 'Sotf',
    original: 'https://www.sotf.com/it/release.php',
    ref: 'https://www.sotf.com/it/release.php',
    instagram: '',
  },
  {
    country: '',
    name: 'Black Box',
    original: 'https://drops.blackboxstore.com/',
    ref: 'https://drops.blackboxstore.com/',
    instagram: '',
  },
  {
    country: '',
    name: 'Corner Street',
    original: 'https://www.cornerstreet.fr/upcoming.html',
    ref: 'https://www.cornerstreet.fr/upcoming.html',
    instagram: '',
  },
  {
    country: '',
    name: 'Corner Street',
    original: 'https://www.cornerstreet.fr/upcoming.html',
    ref: 'https://www.cornerstreet.fr/upcoming.html',
    instagram: '',
  },
  {
    country: '',
    name: 'Bodega',
    original: 'https://bdgastore.com/blogs/upcoming-releases',
    ref: 'https://bdgastore.com/blogs/upcoming-releases',
    instagram: '',
  },
  {
    country: '',
    name: 'Extra Butter',
    original: 'https://extrabutterny.com/blogs/featured-presentations',
    ref: 'https://extrabutterny.com/blogs/featured-presentations',
    instagram: '',
  },
  {
    country: '',
    name: 'Antonia',
    original: 'https://www.antonia.it/us/launches/',
    ref: 'https://www.antonia.it/us/launches/',
    instagram: '',
  },
];

export const brands = [
  {
    country: '',
    name: 'Nike',
    original: '',
    ref: 'https://www.awin1.com/cread.php?awinmid=16334&awinaffid=688439&ued=https%3A%2F%2Fwww.nike.com%2Fpl%2F',
  },
  {
    country: '',
    name: 'adidas',
    original: '',
    ref: 'https://go.skimresources.com?id=157312X1623776&xs=1&url=https%3A%2F%2Fwww.adidas.pl%2F',
  },
  {
    country: '',
    name: 'Yeezy',
    original: '',
    ref: 'https://go.skimresources.com?id=157312X1623776&xs=1&url=https%3A%2F%2Fwww.adidas.pl%2Fyeezy',
  },
  {
    country: '',
    name: 'Asics',
    original: 'https://www.asics.com/pl/pl-pl/asics-launches/',
    ref: 'https://go.skimresources.com?id=157312X1623776&xs=1&url=https%3A%2F%2Fwww.asics.com%2Fpl%2Fpl-pl%2F',
  },
  {
    country: '',
    name: 'New Balance',
    original: 'https://nbsklep.pl/',
    ref: 'https://go.skimresources.com?id=157312X1623776&xs=1&url=https%3A%2F%2Fnbsklep.pl%2F',
  },

  {
    country: '',
    name: 'Reebok',
    original: '',
    ref: 'https://go.skimresources.com?id=157312X1623776&xs=1&url=https%3A%2F%2Fwww.reebok.pl%2F',
  },
];

export const reseller = [
  {
    country: '',
    name: 'stockX',
    original: '',
    ref: 'https://sdeals.pl/3602',
  },
  {
    country: '',
    name: 'GOAT',
    original: '',
    ref: 'https://sdeals.pl/w8he',
  },
  {
    country: '',
    name: 'We The New',
    original: '',
    ref: 'https://sdeals.pl/5cfy',
  },
  {
    country: '',
    name: 'Stadium Goods',
    original: '',
    ref: 'https://sdeals.pl/n3v8',
  },
  {
    country: '',
    name: 'Flight Club',
    original: '',
    ref: 'https://sdeals.pl/7k2r',
  },
  {
    country: '',
    name: 'Kicks Crew',
    original: '',
    ref: 'https://sdeals.pl/uvsk',
  },
];

export const onlineRaffles = [
  // // tres-bien
  // 'https://api.apify.com/v2/actor-tasks/ZJVE91LfQKmsetp40/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&clean=true',
  // // sneakersnstuff
  // 'https://api.apify.com/v2/actor-tasks/FUAkZEKNXkWBVEuFi/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&clean=true',
  // hanon
  'https://api.apify.com/v2/actor-tasks/dSknIQtjEJrQaH27J/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&clean=true',
  // footshop
  'https://api.apify.com/v2/actor-tasks/EzuUpubR5ZPU3nt22/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&clean=true',
  // footpatrol
  'https://api.apify.com/v2/actor-tasks/asKlYGroRyGLrhRRt/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&clean=true',
  // end
  'https://api.apify.com/v2/actor-tasks/APRIBTsf0rrdGU9bR/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&clean=true',
  // asphaltgold
  'https://api.apify.com/v2/actor-tasks/afqv8KTuUD1FJmEZt/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&clean=true',
  // afew
  'https://api.apify.com/v2/actor-tasks/d09t3I52BmyoEcf7a/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&clean=true',
  // bdga
  'https://api.apify.com/v2/actor-tasks/RD53D1F3Tj5J69S1c/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&clean=true',
];
