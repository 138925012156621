import React, { memo } from 'react';
import { Card, Container, Responsive } from 'semantic-ui-react';

const ResponsiveCards = ({ children }) => (
  <Container>
    <Responsive {...Responsive.onlyMobile}>
      <Card.Group centered itemsPerRow={2}>
        {children}
      </Card.Group>
    </Responsive>
    <Responsive {...Responsive.onlyTablet}>
      <Card.Group centered itemsPerRow={3}>
        {children}
      </Card.Group>
    </Responsive>
    <Responsive {...Responsive.onlyComputer}>
      <Card.Group centered itemsPerRow={4}>
        {children}
      </Card.Group>
    </Responsive>
  </Container>
);

export default memo(ResponsiveCards);
