import { Divider, Header, Icon, Segment } from 'semantic-ui-react';
import React from 'react';

type Props = {
  title: string,
  icon?: any,
  subHeader?: string
}

const Title = (props: Props) => {
  const { title, icon, subHeader} = props;

  return (
    <div style={{ padding: '15px 0 15px 0' }}>
      <Divider horizontal>
        <Header as="h4" size='medium'>
          <Icon name={icon} />
          {title}
        </Header>
      </Divider>
      {subHeader && (
        <Segment basic textAlign="center" style={{fontSize: 18}}>
          {subHeader}
        </Segment>
      )}
    </div>
  );
};

export default Title;
