import { convertUrl } from '../../utils/affiliates';

export const mapData = data => {
  return data.map(item => {
    const { domain, affiliates_id, ref_number, extraTracking } = item;

    return {
      ...item,
      refUrl: convertUrl(domain, affiliates_id, ref_number, extraTracking),
    };
  });
};
