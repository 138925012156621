import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

const Loading = inlineLoader => {
  if (inlineLoader) {
    return <Loader active inline="centered" />;
  }

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <Dimmer active inverted>
        <Loader inverted>Pobieranie danych...</Loader>
      </Dimmer>
    </div>
  );
};

export default Loading;
