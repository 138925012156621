import { getUrl, removeBrokenIcons, removeHtmlTags } from '../../utils/utils';
import PostCard from '../shared/PostCard';
import React from 'react';

export const postsList = posts => {
  return posts.map(post => {
    const { discussUrl } = post;
    return <PostCard key={discussUrl} data={post} />;
  });
};

export const mapData = items => {
  return items.map(item => {
    const { message, picture, url } = item;
    const fullMessage = removeHtmlTags(removeBrokenIcons(message));
    const textSlices = fullMessage.split(/\r?\n/);
    let cleanMessage = textSlices && textSlices[0] ? textSlices[0] : '';
    const imageUrl = picture;
    const urls = fullMessage.match(getUrl);
    const buyUrl = urls && urls.length > 0 && urls[0].includes('sdeals') ? urls[0] : '';
    const discussUrl = url;

    return {
      message: cleanMessage,
      fullMessage,
      imageUrl,
      buyUrl,
      discussUrl,
    };
  });
};
