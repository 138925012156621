import React from 'react';
import { Container } from 'semantic-ui-react';
import Title from '../shared/Title';
import SalesQuery from './SalesQuery';

const Sales = props => {
  return (
    <Container data-testid="sales">
      <Title title="Kody Rabatowe" icon="cut"></Title>
      <SalesQuery {...props} />
    </Container>
  );
};

export default Sales;
