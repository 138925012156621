import { calcDiscount } from '../components/Steals/ProductBrowser/Sales.utils';

export const statusCheck = (status, onlyNikeApp, source) => {
  if (source === 'nike-calendar') return 'Premiera w: SNKRS App';
  if (onlyNikeApp) return 'Dostępne tylko w: Nike App';
  return status;
};

export const finalLink = (awinLink, onlyNikeApp) => {
  if (onlyNikeApp)
    return 'https://www.awin1.com/cread.php?awinmid=16334&awinaffid=688439&clickref=&ued=https://www.nike.com/pl/nike-app';
  return awinLink;
};

const mapSizes = (sizes, sizesData, productType) => {
  const res = [];
  sizes.forEach(sizeId => {
    const sizeData = sizesData.find(s => s.id === sizeId);
    if (sizeData) res.push(productType === 1 ? sizeData.filter1 : sizeData.text);
  });
  return res;
};

export const productMapper = (data, filters) => {
  if (data.length) {
    const { products_sizes = [] } = filters;

    // console.log('products_sizes', products_sizes);

    return data.map(item => {
      const {
        id,
        type_id,
        title = '',
        Type = '',
        ref_url,
        price,
        reduced: reducedPrice,
        image,
        big_image = '',
        percentage,
        discount,
        Status,
        gender,
        only_app,
        Source,
        sizes,
        color_id,
        code,
        brand_id = '',
        excluded,
        shop,
        discount_code = '',
        crawler,
      } = item;

      const buyUrl = ref_url;
      let finalPrice = reducedPrice || price;
      let finalPercentage = percentage;
      // let promoCode = '';
      let reduced = reducedPrice;
      let status = statusCheck(Status, only_app, Source);
      const mappedSizes = mapSizes(sizes, products_sizes, type_id);
      const productGender = gender && gender.id ? gender.id : 0;
      let discountTypeId = 0;

      return {
        id,
        shop,
        shopName: shop.text,
        title,
        status,
        discount,
        price: price,
        reduced,
        finalPrice: finalPrice,
        percentage: finalPercentage,
        model: code,
        category: Type,
        gender: productGender,
        buyUrl,
        promoCode: discount_code,
        excluded,
        onlyNikeApp: only_app,
        imageUrl: image,
        bigImage: big_image,
        sizes: mappedSizes,
        color: color_id,
        brand: brand_id,
        shopId: shop.id,
        date: crawler?.date,
        discountTypeId,
      };
    });
  }
  return [];
};
