import React, { useEffect, useState } from 'react';
import './SearchBox.css';
import { shops, gender, shoesSizes, clothesSizes, colors, sortByOptions, brands } from '../../../../utils/data';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';

const SearchBox = props => {
  const { tab } = props;

  const [type, setType] = useState(tab);
  const [searchGender, setSearchGender] = useState('');
  const [shop, setShop] = useState('nike');
  const [brand, setBrand] = useState('');
  const [shoeSize, setShoeSize] = useState('');
  const [clothesSize, setClothesSize] = useState('');
  const [color, setColor] = useState('');
  const [sortBy, setSortBy] = useState('');

  const [showBrandsDropdown, setShowBrandsDropdown] = useState(true);

  useEffect(() => {
    setType(props.tab);
  }, [props]);

  const handleChange = (e, data) => {
    const { name, value } = data;
    if (name === 'gender') value === 'all' ? setSearchGender('') : setSearchGender(value);
    if (name === 'shop') {
      if (
        value === '' ||
        value === 'nike' ||
        value === 'adidas' ||
        value === 'reebok' ||
        value === 'newbalance' ||
        value === 'converse' ||
        value === 'vans'
      ) {
        setShowBrandsDropdown(false);
      } else {
        setShowBrandsDropdown(true);
      }
      value === 'all' ? setShop('') : setShop(value);
    }
    if (name === 'brand') value === 'all' ? setBrand('') : setBrand(value);
    if (name === 'shoeSize') value === 'all' ? setShoeSize('') : setShoeSize(value);
    if (name === 'clothesSize') value === 'all' ? setClothesSize('') : setClothesSize(value);
    if (name === 'color') value === 'all' ? setColor('') : setColor(value);
    if (name === 'sortBy') setSortBy(value);
  };

  const search = () => {
    let baseUrl = 'okazje';

    if (type === 'buty') {
      baseUrl = `${baseUrl}/buty`;
    }
    if (type === 'ciuchy') {
      baseUrl = `${baseUrl}/ciuchy`;
    }
    if (type === 'dodatki') {
      baseUrl = `${baseUrl}/dodatki`;
    }

    const genderParam = gender ? `?gender=${searchGender}` : '';

    const shopParam = shop ? `&shop=${shop}` : '';
    const brandParam = brand ? `&brand=${brand}` : '';
    const shoeSizeParam = shoeSize ? `&shoeSize=${shoeSize}` : '';
    const clothesSizeParam = clothesSize ? `&clothesSize=${clothesSize}` : '';
    const colorParam = color ? `&color=${color}` : '';
    const sortByParam = sortBy ? `&sortBy=${sortBy}` : `&sortBy=${sortByOptions[0].value}`;

    const url = `${baseUrl}${genderParam}${shopParam}${brandParam}${shoeSizeParam}${clothesSizeParam}${colorParam}${sortByParam}`;
    window.location = url;
  };
  const genderOptions = [...gender];
  genderOptions.forEach(v => {
    delete v.icon;
  });

  return (
    <>
      <Input
        className="fluidSearchBox"
        type="text"
        name="city"
        placeholder="Szukaj"
        action
        value={brand}
        onChange={handleChange}
        key="Search"
      >
        <Dropdown
          className="search-box-shop"
          placeholder="Sklep"
          fluid
          compact
          search
          key="shop"
          // multiple
          selection
          value={shop}
          name="shop"
          onChange={handleChange}
          options={shops}
        />
        {/*{showBrandsDropdown && (*/}
        {/*  <Dropdown*/}
        {/*    className="search-box-brand"*/}
        {/*    placeholder="Marka"*/}
        {/*    fluid*/}
        {/*    compact*/}
        {/*    search*/}
        {/*    key="brand"*/}
        {/*    // multiple*/}
        {/*    selection*/}
        {/*    value={brand}*/}
        {/*    name="brand"*/}
        {/*    onChange={handleChange}*/}
        {/*    options={brands}*/}
        {/*  />*/}
        {/*)}*/}
        <Dropdown
          className="search-box-gender"
          placeholder="Płeć"
          fluid
          compact
          search
          labe="test"
          key="gender"
          // multiple
          selection
          value={searchGender}
          name="gender"
          onChange={handleChange}
          options={genderOptions}
        />

        {/*<input />*/}

        {/*{type === 'shoes' && (*/}
        {/*  <Dropdown*/}
        {/*    placeholder="Model"*/}
        {/*    fluid*/}
        {/*    compact*/}
        {/*    search*/}
        {/*    // multiple*/}
        {/*    selection*/}
        {/*    name="eventCategory"*/}
        {/*    onChange={this.handleChange.bind(this)}*/}
        {/*    options={shoeSizes}*/}
        {/*  />*/}
        {/*)}*/}

        {type === 'buty' && (
          <Dropdown
            placeholder="Rozmiar"
            className="search-box-sizes"
            search
            // multiple
            key="shoeSize"
            selection
            value={shoeSize}
            name="shoeSize"
            onChange={handleChange}
            options={shoesSizes}
          />
        )}

        {type === 'ciuchy' && (
          <Dropdown
            placeholder="Rozmiar"
            className="search-box-sizes"
            search
            // multiple
            key="clothesSize"
            selection
            value={clothesSize}
            name="clothesSize"
            onChange={handleChange}
            options={clothesSizes}
          />
        )}

        <Dropdown
          placeholder="Kolor"
          className="search-box-color"
          search
          // multiple
          key="color"
          selection
          value={color}
          name="color"
          onChange={handleChange}
          options={colors}
        />

        {/*<Dropdown*/}
        {/*  placeholder="Sortuj"*/}
        {/*  className="search-box-sort"*/}
        {/*  search*/}
        {/*  // multiple*/}
        {/*  key="sortBy"*/}
        {/*  selection*/}
        {/*  value={sortBy}*/}
        {/*  name="sortBy"*/}
        {/*  onChange={handleChange}*/}
        {/*  options={sortByOptions}*/}
        {/*/>*/}

        <Button icon type="submit" onClick={search}>
          <Icon name="search" />
        </Button>
      </Input>
    </>
  );
};

export default SearchBox;
