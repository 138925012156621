// discountType:
// - onlyFullPrice - only full price
// - selectedProducts - only selected products with code - adidas, reebok
// - reduced - only reduced products
// - all - all products

// const nikePromo = {
//   shop: 'adidas',
//   discountType: 'onlyFullPrice', // onlyReduced / all / onlyFullPrice
//   discountValue: 20,
//   discountCode: 'MAY21',
//   discountFromNewsletter: false,
//   discountTitle: 'Dodatkowe 20% na nieprzecenione Produkty',
//   discountText: `Zaloguj się i wpisz kod "MAY21" i uzyskaj -20% RABATU na nieprzecenione produkty!`,
//   discountValidUntil: '2021-05-04',
//   discountRules: '',
// }

export const currentPromos = [
  {
    shop: 'adidas',
    discountType: 'selectedProducts', // onlyReduced / all / onlyFullPrice / selectedProducts
    discountValue: 25,
    discountCode: 'SHOP25',
    discountFromNewsletter: false,
    discountTitle: 'Dodatkowe 25% na Wybrane Produkty',
    discountText: `Wpisz kod "SHOP25" i uzyskaj -25% RABATU na WYBRANE PRODUKTY!`,
    discountValidUntil: '2021-04-18',
    discountRules: '',
  },
  {
    shop: 'nike',
    discountType: 'onlyFullPrice', // onlyReduced / all / onlyFullPrice
    discountValue: 20,
    discountCode: 'MAY21',
    discountFromNewsletter: false,
    discountTitle: 'Dodatkowe 20% na nieprzecenione Produkty',
    discountText: `Zaloguj się i wpisz kod "MAY21" i uzyskaj -20% RABATU na nieprzecenione produkty!`,
    discountValidUntil: '2021-05-04',
    discountRules: '',
  },
  // {
  //   shop: 'nike',
  //   discountType: 'onlyReduced', // onlyReduced / all / onlyFullPrice / selectedProducts
  //   discountValue: 20,
  //   discountCode: 'SPRING21',
  //   discountFromNewsletter: false,
  //   discountTitle: 'Dodatkowe 20% rabatu na WYPRZEDAŻ',
  //   discountText: `Wpisz kod "SPRING21" i uzyskaj -20% RABATU na produkty z WYPRZEDAŻY!`,
  //   discountValidUntil: '2021-04-16',
  //   discountRules: 'https://www.nike.com/pl/w/wyprzedaz-3yaep',
  // },
  {
    shop: 'adidas',
    discountType: 'onlyFullPrice', // onlyReduced / all / onlyFullPrice / selectedProducts
    discountValue: 20,
    discountCode: '',
    discountFromNewsletter: true,
    discountTitle: 'Dodatkowe 20% rabatu po zapisaniu się do Newslettera',
    discountText: `Zapisz się do Newslettera i uzyskaj -20% RABATU na nieprzecenione produkty!`,
    discountValidUntil: null,
    discountRules: '',
  },
  {
    shop: 'reebok',
    discountType: 'onlyFullPrice', // onlyReduced / all / onlyFullPrice / selectedProducts
    discountValue: 20,
    discountCode: '',
    discountFromNewsletter: true,
    discountTitle: 'Dodatkowe 20% rabatu po zapisaniu się do Newslettera',
    discountText: `Zapisz się do Newslettera i uzyskaj -20% RABATU na nieprzecenione produkty!`,
    discountValidUntil: null,
    discountRules: '',
  },
];

// export const currentNikePromo = {
//   discountType: 'onlyReduced', // onlyReduced / all
//   discountValue: 30,
//   discountCode: 'BRIGHT30',
//   discountTitle: 'Dodatkowe 30% rabatu na produkty z wyprzedaży',
//   discountText: 'Po wpisaniu kodu: "BRIGHT30" dodatkowe -30% RABATU na wszystkie rzeczy z Wyprzedaży! Trzeba się zalogować!',
//   discountValidUntil: '2020-11-24',
//   discountRules: 'https://www.nike.com/pl/help/a/member25-promo-terms-eu',
// };

// export const currentNikePromo = {
//   discountType: 'all', // onlyReduced / all
//   discountValue: 25,
//   discountCode: 'MEMBER25',
//   discountTitle: 'Dodatkowe 25% rabatu na produkty z wyprzedaży',
//   discountText: 'Po wpisaniu kodu: "MEMBER25" dodatkowe -25% RABATU na wszystkie rzeczy z Wyprzedaży!',
//   discountValidUntil: '2020-11-11',
//   discountRules: 'https://www.nike.com/pl/help/a/member25-promo-terms-eu',
// };

// export const currentNikePromo = {
//   discountType: 'onlyReduced',
//   discountValue: 20,
//   discountCode: 'OCT2020',
//   discountTitle: 'Dodatkowe 20% rabatu na produkty z wyprzedaży',
//   discountText: 'Po wpisaniu kodu: "OCT2020" dodatkowe -20% RABATU na wszystkie rzeczy z Wyprzedaży!',
//   discountValidUntil: '2020-10-15',
//   discountRules: 'https://www.nike.com/pl/help/a/oct2020-promo-terms-eu',
// };

export const nikeSNKRS =
  'https://api.apify.com/v2/acts/GoufDjz4FKTKdRnMK/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&clean=true';

export const nikeNewestProducts =
  'https://api.apify.com/v2/acts/NY7LspdYNeBlyo1Jo/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&desc=true&clean=true';

// export const nikeAllshoes = 'https://api.apify.com/v2/acts/3FRhybnj3z8BLKF2x/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&desc=true&clean=true'

export const nikeAllshoes =
  'https://api.apify.com/v2/acts/V3j2iiJt8pE1M7gQB/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&desc=true&clean=true';

// export const nikeAllclothes = 'https://api.apify.com/v2/acts/DCN0fJZUTbI8ltqee/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6'

export const nikeAllclothes =
  'https://api.apify.com/v2/acts/MZgZv2AQsfPQgSd3o/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6';

export const nikeAllaccessories =
  'https://api.apify.com/v2/acts/8mZeUmj0S3cY9qNI8/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6';

export const nikeUrl = [nikeSNKRS, nikeAllclothes, nikeAllshoes, nikeAllaccessories];
export const chmielnaUrl = [
  'https://api.apify.com/v2/actor-tasks/fIzJ35UmrXsanwSQP/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&desc=true&clean=true',
];
export const sklepKoszykarzaUrl = [
  'https://api.apify.com/v2/actor-tasks/SKR2RNFjeYvZJfmVW/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&desc=true&clean=true',
];
export const distanceUrl = [
  'https://api.apify.com/v2/actor-tasks/Cg8MBjpBg2O2tFHZe/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&desc=true&clean=true',
];
export const worldboxUrl = [
  'https://api.apify.com/v2/actor-tasks/rCuBFdlF9ztedsJVl/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&desc=true&clean=true',
];
export const atafUrl = [
  'https://api.apify.com/v2/actor-tasks/9WJHIk7Xht9NMpE06/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&desc=true&clean=true',
];
export const eobuwieUrl = [
  'https://api.apify.com/v2/actor-tasks/up3IkK6o21fWvWYfN/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&desc=true&clean=true',
];
export const footshopUrl = [
  'https://api.apify.com/v2/acts/fc6edGXceu3EGSwuW/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&desc=true&clean=true',
  // 'https://api.apify.com/v2/acts/VeR67TqdaYJPoGB7E/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&desc=true&clean=true' last
  // 'https://api.apify.com/v2/actor-tasks/KdQgBhmvCf9OvulaB/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&desc=true&clean=true',
];

export const allShops = [
  ...nikeUrl,
  // ...chmielnaUrl,
  // ...sklepKoszykarzaUrl,
  // ...distanceUrl,
  ...worldboxUrl,
  // ...atafUrl,
  ...eobuwieUrl,
  // ...footshopUrl,
];
