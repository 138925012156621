import React from 'react';
import './ImageCreator.css';
import { shops } from './ImageCreator.data';
import { dateFormat } from '../../utils/dateFormat';
import dayjs from 'dayjs';

const flipBackgroundImage = shop => {
  if (shop === 'Adidas.pl' || shop === 'Reebok.pl' || shop === 'eObuwie.pl') return true;
  return false;
};

const isSteal = (retail, resell) => {
  if (retail !== resell) return true;
  return false;
};

const Image = props => {
  const { data, file, scale, position, id, grid, currentDate } = props;
  const { promoType, shop, hour, title, retail, resell, method, percentage } = data;

  const steal = isSteal(retail, resell);

  const flippedBackgroundImage = flipBackgroundImage(shop.text);

  const imageBoxStyle = {
    top: `${position}px`,
  };

  const imageStyle = {
    backgroundImage: `url(${file} )`,
    transform: flippedBackgroundImage ? `scaleX(-${scale}) scaleY(${scale})` : `scale(${scale})`,
    // filter: flippedBackgroundImage ? 'FlipH' : '',
    backgroundPosition: `50% 50%`,
  };

  return (
    <div class="imageWrapper">
      <div id={id}>
        <div id="box">
          {steal && (
            <div id="corner-triangle">
              <div className="corner-triangle-text text-capitalize"></div>
            </div>
          )}
          <span id="corner-triangle-text">-{percentage}%</span>
          {/*{promoType === 'okazja' && (<div id="percentage"><span>-30%</span></div>)}*/}
          <img id="logo" src={process.env.PUBLIC_URL + '/apple-icon-144x144.png'} alt="" />
          {promoType === 'drop' && (
            <>
              <div id="date">
                {/*<Icon name="calendar" size="small" />*/}
                {/*{currentDate && dayjs(currentDate)}*/}
                {currentDate && dateFormat(currentDate, 'D MMMM')}
              </div>
              <div id="shop">
                {shop && shops[shop] ? shops[shop].text : ''} {method && ` - ${method}`}
              </div>
              <div id="hour">
                {dayjs(currentDate).format('H')}:00
                {/*{hour}*/}
              </div>
            </>
          )}

          <div id="imagebox" style={imageBoxStyle}>
            <div id="image" style={imageStyle}></div>
          </div>

          <div id="title">{title}</div>

          {grid && <div id="perfectImage"></div>}

          <div id="retail">
            {promoType === 'drop' ? (
              <>
                Retail: <strong>{retail} zł</strong>
              </>
            ) : (
              <>
                {' '}
                <strong id="promoPrice">{resell} zł</strong> {steal && <del>{retail} zł</del>}
              </>
            )}
          </div>

          <div id="extraText">Bezpłatna DOSTAWA i ZWROT</div>

          {promoType === 'drop' && resell && (
            <div id="resell">
              Resell: <strong id="promoPrice">{resell} zł</strong>
            </div>
          )}

          <div id="branding">STREETDEALS.PL</div>
        </div>
      </div>
    </div>
  );
};

export default Image;
