export const routes = [
  {
    title: 'Streetdeals.pl',
    url: '/',
    exact: true,
  },
  // {
  //   title: 'Newsy',
  //   url: '/newsy',
  // },
  {
    title: 'Dropy',
    url: '/dropy',
  },
  {
    title: 'Nowości',
    url: '/nowosci',
  },
  {
    title: 'Okazje',
    url: '/okazje/buty?gender=&shop=nike&sortBy=discount',
  },
  {
    title: 'Kody rabatowe',
    url: '/kody',
  },
  {
    title: 'Sklepy',
    url: '/sklepy',
  },
  {
    title: 'O nas',
    url: '/o-nas',
  },
  {
    title: 'Streetwear',
    url: 'https://streetwear.pl',
    newTab: true,
  },
  {
    title: 'Market',
    url: 'https://www.facebook.com/groups/streetdeals/',
    newTab: true,
  },
];

export const socials = [
  {title: 'Telegram', icon: 'telegram', url:'https://t.me/+cPqY0OXxbGkyZTFk'},
  {title: 'Discord', icon: 'discord', url:'https://discord.gg/ySh98CQr8U'},
  {title: 'Fanpage', icon: 'facebook', url:'https://www.facebook.com/StreetDealspl/'},
  {title: 'Instagram', icon: 'instagram', url:'https://www.instagram.com/streetdealspl/'},
  {title: 'TikTok', icon: 'tiktok', url:'https://www.tiktok.com/@streetdealspl'},
  // {title: '', icon: '', url:''},
  // {title: '', icon: '', url:''}
]
