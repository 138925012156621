import { gql } from '@apollo/client';

export const SHOPS_QUERY = gql`
  query GetShops {
    products_shops {
      url
      text
      ref_number
      id
      extra_tracking
      domain
      country
      affiliates_id
    }
  }
`;
