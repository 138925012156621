import React, { useState } from 'react';
import { Container, Tab } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import Shops from './Shops';
import Tabs from '../shared/Tabs/Tabs';
import Calendar from './Calendar';
// import Raffles from './Raffles';
// import Online from './Online';
import Resell from './Resell';
// import Premiers from './Premiers';
import { updateUrl, getPathParams } from '../../utils/utils';

const panes = [
  {
    menuItem: 'Kalendarz',
    url: 'kalendarz',
    render: () => (
      <Tab.Pane attached={true}>
        <br />
        <br />
        <Calendar />
      </Tab.Pane>
    ),
  },
  // {
  //   menuItem: 'Premiery',
  //   url: 'premiery',
  //   render: () => (
  //     <Tab.Pane attached={true}>
  //       <Premiers />
  //     </Tab.Pane>
  //   ),
  // },
  // {
  //   menuItem: 'Resell',
  //   url: 'resell',
  //   render: () => (
  //     <Tab.Pane attached={true}>
  //       <Resell />
  //     </Tab.Pane>
  //   ),
  // },
  // {
  //   menuItem: 'Online',
  //   url: 'online',
  //   render: () => (
  //     <Tab.Pane attached={true}>
  //       <Online />
  //     </Tab.Pane>
  //   ),
  // },
  // {
  //   menuItem: 'Raffles',
  //   url: 'raffles',
  //   render: () => (
  //     <Tab.Pane attached={true}>
  //       <Raffles />
  //     </Tab.Pane>
  //   ),
  // },

  {
    menuItem: 'Sklepy',
    url: 'sklepy',
    render: () => (
      <Tab.Pane attached={true}>
        <Shops />
      </Tab.Pane>
    ),
  },
];

function Drops() {
  // const match = useRouteMatch();
  // console.log('match', match);
  let defaultActiveIndex = 0;

  // set tab based on URL
  const location = useLocation();
  const pathParams = getPathParams(location);
  // console.log('pathParams',pathParams)

  if (pathParams[1] !== '') {
    const tabName = pathParams[1];

    console.log('tabName',tabName)

    // if (tabName === 'premiery') {
    //   defaultActiveIndex = 1;
    // }
    // if (tabName === 'online') {
    //   defaultActiveIndex = 2;
    // }
    // if (tabName === 'raffles') {
    //   defaultActiveIndex = 3;
    // }
    // if (tabName === 'resell') {
    //   defaultActiveIndex = 4;
    // }
    if (tabName === 'sklepy') {
      defaultActiveIndex = 1;
    }
  }

  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);

  const tabCallback = (e, data) => {
    const { activeIndex, panes } = data;
    setActiveIndex(activeIndex);
    // console.log('e', e);
    // console.log('data', data);
    const newUrl = panes[activeIndex].url ? panes[activeIndex].url : '';
    updateUrl(`/dropy/${newUrl}`);
  };

  return (
    <Container>
      <br />
      <Tabs panes={panes} activeIndex={activeIndex} callback={tabCallback} />
    </Container>
  );
}

export default Drops;
