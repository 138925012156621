import React, { useState } from 'react';
import { Container, Tab } from 'semantic-ui-react';
import Title from '../../shared/Title';
import PromotedProductsQuery from './PromotedProductsQuery';
import Tabs from '../../shared/Tabs/Tabs';
import { updateUrl } from '../../../utils/utils';

const panes = [
  {
    menuItem: 'Nike',
    url: 'nike',
    render: () => (
      <Tab.Pane attached={true} style={{ background: 'transparent' }}>
        <br />
        <br />
        <PromotedProductsQuery shopId={2} moreUrl="/okazje/buty?gender=&shop=nike&sortBy=discount" />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'adidas',
    url: 'adidas',
    render: () => (
      <Tab.Pane attached={true} style={{ background: 'transparent' }}>
        <br />
        <br />
        <PromotedProductsQuery shopId={10} moreUrl="/okazje/buty?gender=&shop=adidas&sortBy=discount" />
      </Tab.Pane>
    ),
  },
  // {
  //   menuItem: 'Reebok',
  //   url: 'reebok',
  //   render: () => (
  //     <Tab.Pane attached={true} style={{ background: 'transparent' }}>
  //       <br />
  //       <br />
  //       <PromotedProductsQuery shopId={11} moreUrl="/okazje/buty?gender=&shop=reebok&sortBy=discount" />
  //     </Tab.Pane>
  //   ),
  // },
];

const PromotedProducts = props => {
  const { limit } = props;

  const [activeIndex, setActiveIndex] = useState(0);

  const tabCallback = (e, data) => {
    const { activeIndex, panes } = data;
    setActiveIndex(activeIndex);
    // console.log('e', e);
    // console.log('data', data);
    // const newUrl = panes[activeIndex].url ? panes[activeIndex].url : '';
    // updateUrl(`/dropy/${newUrl}`);
  };

  return (
    <Container data-testid="news">
      <Title title="Okazje" icon="hotjar"></Title>
      <Tabs panes={panes} activeIndex={activeIndex} callback={tabCallback} />
    </Container>
  );
};

export default PromotedProducts;
