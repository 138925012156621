import React  from 'react';
import { Container } from 'semantic-ui-react';
import Title from '../shared/Title';
import PostsQuery from './PostsQuery';
import PostsTabs from './PostsTabs';

const Posts = props => {
  const {limit} = props;
  return (
    <Container data-testid="news">
      <Title title="Newsy" icon="hotjar"></Title>
      {limit ? <PostsQuery {...props} /> : <PostsTabs />}
    </Container>
  );
};

export default Posts;
