import { convertUrl } from '../../utils/affiliates';
import dayjs from 'dayjs';

export const validPromo = end_date => {
  const today = dayjs(new Date());
  const promoEndDate = dayjs(end_date);
  return promoEndDate.isAfter(today);
};

export const filterPromos = data => {
  return data.filter(sale => {
    const { limited_in_time, end_date } = sale;
    if (limited_in_time && end_date !== '') return validPromo(end_date);
    return sale;
  });
};

export const addTracking = data => {
  return data.map(item => {
    const { shop } = item;
    const { domain, affiliates_id, extra_tracking, ref_number } = shop;
    return {
      ...item,
      refUrl: convertUrl(domain, affiliates_id, ref_number, extra_tracking),
    };
  });
};

// export const groupPromos = (shops, promos) => {
//   const groupedPromos = [];
//
//   shops.forEach(shop => {
//     const { id } = shop;
//
//     let newShop = { ...shop };
//     newShop.promos = [];
//
//     promos.forEach(promo => {
//       const { shop_id } = promo;
//       if (shop_id === id) {
//         newShop.promos.push(promo);
//
//         console.log('shop', shop);
//         console.log('promo', promo);
//       }
//     });
//
//     if (newShop.promos.length > 0) groupedPromos.push(newShop);
//   });
//   console.log('groupedPromos', groupedPromos);
//
//   return groupedPromos;
// };
