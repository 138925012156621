import React from 'react';
import { Container, Grid, Image } from 'semantic-ui-react';
import Title from '../shared/Title';
import logo from '../../img/sdeals_promo.png';

function AboutUs() {
  return (
    <Container>
      <Title title="O nas" icon="mobile alternate"></Title>

      <Grid centered>
        <p><Image src={logo} alt="Aplikacja Street Deals" /></p>
        <p>Aplikacja <strong>Streetdeals</strong> to <strong>agregator produktów Streewear</strong>.</p><p>Uprzedzając pytania, nie sprzedajemy żadnych produktów, a jedynie monitorujemy i polecamy produkty innych.</p>
        <p>Naszym głównym celem jest śledzenie: <strong>Dropów</strong>, <strong>Okazji</strong> i ,<strong>Nowości</strong> buciarskich i limitowanych ciuchów typu Streetwear.
        </p><p>Między innymi takich marek jak: <strong>Nike</strong>, <strong>adidas</strong>, <strong>Supreme</strong>, <strong>Asics</strong> czy <strong>New Balance</strong>
        </p><p>Współpracujemy tylko ze sprawdzonymi sklepami, które sprzedają oryginalne buty, ciuchy i akcesoria.</p>
        <p>
          Aplikacja <strong>Street Deals</strong> jest częścią sieci stron, które tworzymy dla was od lat:
          <a href="https://Airmaxy.pl" target="_blank" rel="noopener noreferrer">
            Airmaxy.pl
          </a>, <a href="https://Jordany.pl" target="_blank" rel="noopener noreferrer">
            Jordany.pl</a> i <a href="https://Streetwear.pl" target="_blank" rel="noopener noreferrer">
            Streetwear.pl</a>.
        </p>
        <p>
          To co widzicie to dopiero początek tego co mamy w planach. Zaglądajcie do nas systematycznie i sprawdzajcie nowe funkcje. <br /><br />Ekipa Street Deals
        </p>
      </Grid>
    </Container>
  );
}

export default AboutUs;
