import React from 'react';
import { Container, Card} from 'semantic-ui-react';
import ResponsiveCards from '../shared/ResponsiveCards';
import Title from '../shared/Title';
import { dropsData, reseller, brands } from './Drops.data';

const postsList = posts => {
  return posts.map((post, index) => {
    const {name, ref} = post;
    return (
      <Card key={index} href={ref}>
        <Card.Content style={{textAlign: 'center'}}>
          <Card.Header>{name}</Card.Header>
        </Card.Content>
      </Card>
    );
  });
};

const Shops = () => {
  return (
    <Container>

      <Title title="Marki" icon="calendar" subHeader='Kalendarze premier marek.'></Title>
      <ResponsiveCards>{postsList(brands)}</ResponsiveCards>

      <Title title="Raffles" icon="calendar" subHeader='Sklepy w których odbywają się losowania.'></Title>
      <ResponsiveCards>{postsList(dropsData)}</ResponsiveCards>

      <Title title="Resellerzy" icon="calendar" subHeader='Sklepy, które skupują i sprzedają limitowane buty.'></Title>
      <ResponsiveCards>{postsList(reseller)}</ResponsiveCards>

    </Container>
  );
};

export default Shops;
