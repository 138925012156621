import React from 'react';
import { Card, Icon, Button, Label } from 'semantic-ui-react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/pl';
import LazyImage from './LazyImage';
import { dateFormat } from '../../utils/dateFormat';
dayjs.extend(relativeTime);
dayjs.locale('pl');

// type Props = {
//   data: {
//     title: string;
//     message: string;
//     imageUrl: string;
//     buyUrl: string;
//     discussUrl: string;
//     date: string;
//     price: string;
//     event: string;
//     instagramUrl: string;
//     raffleUrl: string;
//     shop: string;
//     snkrs: boolean;
//     where: string;
//     method: string;
//     time: string;
//     order: number;
//     isDrop: boolean
//   };
//   onPromoModal?: void,
//   beta: boolean
// };

const PostCard = props => {
  const { data, onPromoModal, beta = false, showDate } = props;

  // console.log('data',data)

  const {
    title,
    message,
    imageUrl,
    bigImage,
    buyUrl,
    discussUrl,
    date,
    price,
    event,
    instagramUrl,
    raffleUrl,
    shop,
    where,
    method,
    order,
    isDrop,
    premium,
    code,
    shopName
  } = data;

  const onBtnClick = url => {
    window.open(url, '_blank');
  };

  const label = premium ? { as: 'a', corner: 'right', icon: 'fire', color: 'red' } : null;

  // const test = new Date(date);
  // const testDay = test.getUTCDate();

  // const today = new Date();
  // const todayDay = today.getUTCDate();
  // console.log('todayDay',todayDay)
  // const testDate = new Date(date);
  // const dateDay = testDate.getUTCDate();
  // console.log('dateDay',dateDay)
  // const res =  (dateDay < todayDay)

  return (
    <Card hover="true">
      <LazyImage src={imageUrl} wrapped ui={false} shop={shop} label={label} />
      {/*{isDrop && date && date.length > 2 && where !== '' && (*/}
        <Label attached="top left" className="calendar-date-container">
          <span className="calendar-date">{dayjs(date).format('D MMMM')}</span>
          <span className="calendar-where">
            {where} {method && <span> - {method}</span>}
          </span>
          <span className="calendar-hour">{dayjs(date).format('H')}:00</span>
        </Label>
      {/*// )}*/}
      <Card.Content>
        {message && event ? (
          <Card.Description style={{ textAlign: 'center' }}>{message}</Card.Description>
        ) : (
          <Card.Description>
            {title !== '' && (
              <>
                {order}
                <h5 style={{ paddingBottom: '0', marginBottom: '0' }}>{title}</h5>
                {code && (<span style={{ paddingBottom: '10px', textAlign: 'center', color: 'rgba(0,0,0,.4)' }}>
                  <span className="mobile hidden">Model:</span> {code}
                </span>)}
              </>
            )}
            {/*<br/>*/}
            {/*{todayDay}*/}
            {/*<br/>*/}
            {/*{dateDay}*/}
            {/*<br/>*/}
            {/*{res}*/}
{/*            {testDay}*/}
{/*<br/>*/}
{/*            {date}*/}
            {message}
            {/*<br />*/}
            {/*{dateFormat(date, 'D MMMM')}*/}
          </Card.Description>
        )}
      </Card.Content>
      {price && (
        <Card.Content extra>
          <div style={{ textAlign: 'center' }}>
            <Label tag size="large">
              {price}
            </Label>
          </div>
        </Card.Content>
      )}
      <Card.Content extra>
        <div style={{ textAlign: 'center' }}>
          {buyUrl && (
            <Button
              primary
              size="tiny"
              onClick={() => {
                onBtnClick(buyUrl);
              }}
            >
              <Icon name="external" /> { shopName || shop}
            </Button>
          )}
          {discussUrl && (
            <Button
              size="tiny"
              onClick={() => {
                onBtnClick(discussUrl);
              }}
            >
              <Icon name="comments" /> Dyskusja
            </Button>
          )}
          {instagramUrl && (
            <Button
              primary
              size="tiny"
              onClick={() => {
                onBtnClick(instagramUrl);
              }}
            >
              <Icon name="instagram" /> Instagram
            </Button>
          )}
          {raffleUrl && (
            <Button
              primary
              size="tiny"
              onClick={() => {
                onBtnClick(raffleUrl);
              }}
            >
              <Icon name="external" /> {shop}
            </Button>
          )}
          {beta && (
            <Button
              basic
              color="grey"
              size="tiny"
              onClick={() => {
                onPromoModal(data);
              }}
            >
              <Icon name="share square" /> Promo
            </Button>
          )}
        </div>
      </Card.Content>
    </Card>
  );
};

export default PostCard;
