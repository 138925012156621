import {popularModels} from '../../../Index/Search/PopularModels'

export const footerData = [
    {
        title: 'Streetdeals',
        children: [
            {
                title: 'Dropy',
                url: 'dropy'
            },
            {
                title: 'Nowości',
                url: 'nowosci'
            },
            {
                title: 'Okazje',
                url: 'okazje'
            },
            {
                title: 'Kody rabatowe',
                url: 'kody'
            },
            {
                title: 'Sklepy',
                url: 'sklepy'
            },
            {
                title: 'O nas',
                url: 'o-nas'
            },
        ]
    },
    {
        title: 'Marki',
        children: [{
            title: 'Nike',
            url: 'okazje/buty?gender=&shop=nike&sortBy=discount'
        },
        {
            title: 'Adidas',
            url: 'okazje/buty?gender=&shop=adidas&sortBy=discount'
        },
        {
            title: 'Reebok',
            url: 'okazje/buty?gender=&shop=reebok&sortBy=discount'
        }
        ]
    },
    {
        title: 'Kategorie',
        children: [
            {
                title: 'Buty',
                url: 'okazje/buty?gender=&shop=nike&sortBy=discount',
            },
            {
                title: 'Ciuchy',
                url: 'okazje/ciuchy?gender=&shop=nike&sortBy=discount',
                children: [
                    {
                        title: 'Tshirty',
                        url: 'okazje/ciuchy/tshirty?gender=&shop=nike&brand=all&model=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount'
                    },
                    {
                        title: 'Bluzy',
                        url: 'okazje/ciuchy/bluzy?gender=&shop=nike&brand=all&model=all&subModel=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount'
                    },
                    {
                        title: 'Spodenki',
                        url: 'okazje/ciuchy/spodenki?gender=&shop=nike&brand=all&model=all&subModel=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount'
                    },
                    {
                        title: 'Spodnie',
                        url: 'okazje/ciuchy/spodnie?gender=&shop=nike&brand=all&model=all&subModel=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount'
                    },
                    {
                        title: 'Kurtki',
                        url: 'okazje/ciuchy/kurtki?gender=&shop=nike&brand=all&model=all&subModel=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount'
                    }
                ]
            },
            {
                title: 'Dodatki',
                url: 'okazje/dodatki?gender=&shop=nike&brand=all&model=all&subModel=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
                children: [
                    {
                        title: 'Czapki',
                        url: 'okazje/dodatki/czapki?gender=&shop=nike&brand=all&model=all&subModel=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount'
                    },
                    {
                        title: 'Nerki',
                        url: 'nerki?gender=&shop=nike&brand=all&model=all&subModel=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount'
                    },
                    {
                        title: 'Torebki',
                        url: 'okazje/dodatki/torebki?gender=&shop=nike&brand=all&model=all&subModel=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount'
                    },
                    {
                        title: 'Plecaki',
                        url: 'okazje/dodatki/plecaki?gender=&shop=nike&brand=all&model=all&subModel=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount'
                    },
                ]
            }
        ]
    },
    {
        title: 'Popularne modele',
        children: [...popularModels]
    },

]