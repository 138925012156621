import React, { Component } from 'react';
// import { Header } from 'semantic-ui-react';
import { Card } from 'semantic-ui-react';
import { shops } from '../../../utils/data';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import { Link } from 'react-router-dom';
import { Grid, Image } from 'semantic-ui-react';

export const popularModels = [

  {
    title: 'Dunk',
    url:
        'okazje/buty?gender=&shop=nike&brand=all&model=dunk&subModel=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  },
  // {
  //   title: 'Dunk Low',
  //   url:
  //     'okazje/buty?gender=&shop=nike&brand=all&model=dunk&subModel=dunk-low&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  // },
  // {
  //   title: 'Dunk High',
  //   url:
  //       'okazje/buty?gender=&shop=nike&brand=all&model=dunk&subModel=dunk-high&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  // },
  {
    title: 'Air Jordan 1',
    url:
      'okazje/buty?gender=&shop=nike&brand=all&model=jordan&subModel=air-jordan-1&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  },
  {
    title: 'Jordan',
    url:
        'okazje/buty?gender=&shop=nike&brand=all&model=jordan&subModel=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  },
  // {
  //   title: 'Air Jordan 5',
  //   url:
  //       'okazje/buty?gender=&shop=nike&brand=all&model=jordan&subModel=air-jordan-5&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  // },
  {
    title: 'Air Force 1',
    url:
      'okazje/buty?gender=all&shop=nike&brand=all&model=air-force&subModel=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  },
  // {
  //   title: 'Air Jordan 4',
  //   url:
  //       'okazje/buty?gender=&shop=nike&brand=all&model=jordan&subModel=air-jordan-1&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  // },
  {
    title: 'Air Max 1',
    url:
        'okazje/buty?gender=&shop=nike&brand=all&model=air-max&subModel=air-max-1&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  },
  {
    title: 'Air Max 90',
    url:
      'okazje/buty?gender=&shop=nike&brand=all&model=air-max&subModel=air-max-90&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  },
  {
    title: 'Air Max Plus',
    url:
        'okazje/buty?gender=&shop=nike&brand=all&model=air-max&subModel=air-max-plus&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  },
  // {
  //   title: 'Blazer',
  //   url:
  //     'okazje/buty?gender=&shop=nike&brand=all&model=blazer&subModel=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  // },
  {
    title: 'Nike SB',
    url:
      'okazje/buty?gender=&shop=nike&brand=all&model=sb&subModel=all&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  },

  // {
  //   title: 'Air Max 90',
  //   url:
  //     'okazje/buty?gender=all&shop=all&brand=all&model=air-max&subModel=air-max-90&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  // },
  // {
  //   title: 'Air Max 95',
  //   url:
  //     'okazje/buty?gender=all&shop=all&brand=all&model=air-max&subModel=air-max-95&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  // },
  // {
  //   title: 'Air Max Plus',
  //   url:
  //     'okazje/buty?gender=all&shop=all&brand=all&model=air-max&subModel=air-max-plus&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  // },
  // {
  //   title: 'Air VaporMax',
  //   url:
  //     'okazje/buty?gender=all&shop=all&brand=all&model=air-max&subModel=air-vapormax&shoeSize=all&clothesSize=all&color=all&sortBy=discount',
  // },
];

const generateList = () => {
  return popularModels.map((item, index) => {
    const { title, url } = item;
    return (
      <Grid.Column mobile={8} tablet={5} computer={2}>
        <Card key={index} as={Link} to={url} color="grey" className="gradient" fluid>
          <Card.Content textAlign="center">
            {/*<Card.Header>{title}</Card.Header>*/}
            <Card.Description style={{whiteSpace: 'nowrap'}}>
              <strong>{title}</strong>
            </Card.Description>
            {/*<Card.Description>*/}
            {/*  Matthew is a pianist living in Nashville.*/}
            {/*</Card.Description>*/}
          </Card.Content>
        </Card>
      </Grid.Column>
    );
  });
};

const PopularModels = () => {
  return (
    <div className="popularModels">
      <Grid centered columns='equal'>
        {/*<Card.Group itemsPerRow={8} centered>*/}
        {generateList()}
        {/*</Card.Group>*/}
      </Grid>
      <br />
    </div>
  );
};

export default PopularModels;
