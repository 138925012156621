import React, { Component } from 'react';
// import { Header } from 'semantic-ui-react';
import { shops } from '../../../utils/data';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';

export default class SearchIntro extends Component {
  render() {
    return (
      <Header as="h2" icon textAlign="center">
        <br />
        <Header.Content style={{ marginBottom: '10px' }}>Wyszukiwarka okazji Sneakers & Streetwear</Header.Content>
        <Header.Subheader style={{fontSize: 18}}>Sprawdź w jednym miejscu najlepsze Okazje na Buty i Ciuchy typu Streetwear*</Header.Subheader>
        <Header.Subheader style={{fontSize: 18}}>
          <small>
            Nie trać czasu - przeglądaj tylko dostępne <strong>rozmiary</strong> i <strong>kolory</strong>. Nasza baza
            jest aktualizowana kilka razy dziennie.
          </small>
        </Header.Subheader>
        {/*<Header.Subheader>*/}
        {/*  <small>* Aktualnie śledzone sklepy: {listOfShops()}</small>*/}
        {/*</Header.Subheader>*/}
      </Header>
    );
  }
}
