import React from 'react';
import ProductCard from '../../shared/ProductCard/ProductCard';
import { currentPromos } from './ProductBrowser.data';
import dayjs from 'dayjs';
import { client } from '../../../utils/apolloClient';
import { PRODUCTS_QUERY } from './Products.gql';
import { filters } from '../../../utils/data';

const typeIdMapper = mainCategory => {
  if (mainCategory === filters[0].value) return filters[0].newValue;
  if (mainCategory === filters[1].value) return filters[1].newValue;
  if (mainCategory === filters[2].value) return filters[2].newValue;
};

const getIdByValue = (data, value) => {
  let res = undefined;
  const results = data.filter(i => {
    // console.log('value', value);
    return i.value === value;
  });
  if (results.length && results[0].id) {
    res = results[0].id;
  }
  return res;
};

const getId = (data, value) => data.find(item => item.value === value).id;

export const fetchDataWithApollo = async (activeFilter, filters, limit, offset) => {
  // console.log('limit', limit);
  // console.log('offset', offset);
  // const { key = '' } = shop;
  // const typeId = typeIdMapper(mainCategory);
  //
  // let shopId = null;
  // if (key !== 0) shopId = key;

  // console.log('filters', filters);
  const { products_brands, products_sizes, products_colors, products_shoes_models } = filters;

  // console.log('activeFilter', activeFilter);

  const {
    shop,
    brand,
    mainCategory,
    subCategory,
    gender,
    shoeSize,
    clothesSize,
    model,
    subModel,
    color,
    sortBy,
    onlyLotsOfSizes,
  } = activeFilter;
  const { key } = shop;

  // console.log('onlyLotsOfSizes', onlyLotsOfSizes);

  let shopId;
  if (key) shopId = key;

  // console.log('shopId',shopId)

  let brandId;
  if (brand && brand !== 'all') brandId = getId(products_brands, brand);

  // console.log('clothesSize', clothesSize);

  let typeId;
  if (mainCategory === 'buty') typeId = 1;
  if (mainCategory === 'ciuchy') typeId = 2;
  if (mainCategory === 'dodatki') typeId = 3;

  // console.log('typeId', typeId);

  let genderIds;
  if (gender === 'all') genderIds = [0, 1, 2, 3, 4, 5, 6];
  if (gender === 'meskie') genderIds = [1, 6];
  if (gender === 'damskie') genderIds = [2, 6];
  if (gender === 'dzieci') genderIds = [3];

  let subTypeId;
  if (subCategory === 'tshirty') subTypeId = 4;
  if (subCategory === 'bluzy') subTypeId = 5;
  if (subCategory === 'spodenki') subTypeId = 6;
  if (subCategory === 'spodnie') subTypeId = 7;
  if (subCategory === 'kurtki') subTypeId = 8;
  if (subCategory === 'czapki') subTypeId = 9;
  if (subCategory === 'nerki') subTypeId = 10;
  if (subCategory === 'torebki') subTypeId = 11;
  if (subCategory === 'plecaki') subTypeId = 12;

  let size;
  if (typeId === 1) {
    if (shoeSize && shoeSize !== 'all') size = shoeSize;
  } else if (typeId === 2) {
    if (clothesSize && clothesSize !== 'all') size = clothesSize;
  }
  if (size) {
    size = getIdByValue(products_sizes, size);
  }

  let colorId;
  if (color && color !== 'all') {
    colorId = getIdByValue(products_colors, color);
  }

  let shoesModelId;
  // console.log('model', model);
  // console.log('products_shoes_models', products_shoes_models);
  if (model && model !== 'all') {
    // console.log('model', model);
    shoesModelId = getId(products_shoes_models, model);
  }

  let shoesSubModelId;
  if (subModel && subModel !== 'all') {
    // console.log('model', model);
    shoesSubModelId = getId(products_shoes_models, subModel);
  }

  // console.log('shoesModelId', shoesModelId);

  let orderBy = {};
  if (sortBy === 'discount') {
    orderBy = { percentage: 'desc' };
  } else if (sortBy === 'mostExpensive') {
    orderBy = { final_price: 'desc' };
  } else if (sortBy === 'cheapest') {
    orderBy = { final_price: 'asc' };
  } else if (sortBy === 'newest') {
    orderBy = { id: 'desc' };
  }

  let sizesTotal = undefined;
  if (onlyLotsOfSizes) {
    if (typeId === 1) sizesTotal = 10;
    if (typeId === 2) sizesTotal = 4;
  }

  const response = await client.query({
    query: PRODUCTS_QUERY,
    variables: {
      shopId,
      brandId,
      typeId,
      genderIds,
      size,
      subTypeId,
      colorId,
      shoesModelId,
      shoesSubModelId,
      orderBy,
      limit,
      offset,
      sizesTotal,
    },
    // fetchPolicy:'network-only'
  });

  // console.log('response', response);

  const { data } = response;
  const { products, sales, products_aggregate } = data;
  const { aggregate } = products_aggregate;
  const { count } = aggregate;
  // const products = mapApolloData(rawProducts);

  return {
    products,
    sales,
    count,
  };
};

const mapApolloData = data => {
  let res = [];
  data.forEach(shop => {
    let res2 = [];
    let { data, shop_id } = shop;
    data.forEach(product => {
      res2.push({
        ...product,
        ShopId: shop_id,
      });
    });
    res = [...res, ...res2];
  });
  return res;
};

export const isBeta = () => {
  if (window.location.href.includes('http://localhost:3006/')) return true;
  return false;
};

export const postsList = (posts, onPromoModal, beta) => {
  return posts.map(post => {
    const { id, buyUrl } = post;
    return <ProductCard key={id} data={post} key={buyUrl} onPromoModal={onPromoModal} beta={beta} />;
  });
};

export const limitData = (data, limit) => {
  return data.slice(0, limit);
};

export const validateData = data => {
  return data.filter(product => {
    const { title, type_id, image, price, only_app, sizes = [] } = product;

    let hasSizes = true;
    if (type_id === 1 || type_id === 2) {
      if (sizes && sizes.length === 0) hasSizes = false;
      if (!product.hasOwnProperty('sizes')) hasSizes = false;
    }

    const hasTitle = title !== '';
    const hasImage = image !== '';
    const hasPrice = parseInt(price) > 5;
    const noNikeOnlyProduct = only_app !== true;
    const unwantedProduct = title.includes('Mesh Ankle Boot');

    return (
      product['#error'] !== true &&
      hasTitle &&
      hasSizes &&
      hasPrice &&
      hasImage &&
      noNikeOnlyProduct &&
      !unwantedProduct
    );
  });
};

// export const uniqItems = data => data.filter((thing, index, self) => index === self.findIndex(t => t.buyUrl === thing.buyUrl));

// const removeUnwantedProducts = data => {
//   const newData = [...data];
//   return newData.filter(item => {
//     const { type } = item;
//     return (
//       !type.toLowerCase().includes('kolce') &&
//       !type.toLowerCase().includes('piłkarskie') &&
//       !type.toLowerCase().includes('piłkarska') &&
//       !type.toLowerCase().includes('startowe')
//     );
//   });
// };

export const isPromoActive = promoDiscount => {
  const { end_date, limited_in_time } = promoDiscount;

  if (limited_in_time) {
    if (end_date === null) return true;
    const today = dayjs(new Date().setHours(23, 59, 59, 999));
    const promoEndDate = dayjs(end_date);
    return today.isBefore(promoEndDate);
  }

  return true;
};

const defaultPromo = {
  productTypes: '',
  discountValue: 0,
};

export const checkPromo = shopValue => {
  if (shopValue !== 'all') {
    const promo = currentPromos.find(promo => {
      const { shop } = promo;
      return shop === shopValue;
    });
    if (promo) return promo;
  }
  return defaultPromo;
};
