import React from 'react';
import domtoimage from 'dom-to-image';

export const findShopValueByName = (shopName, shops) => {
  // console.log('shops', shops);
  // console.log('shopName', shopName);
  return shops.find(shop => {
    return shop.mappingName.toLowerCase() === shopName.toLowerCase();
  });
};

export const test = (canvasId, width, height) => {
  const node = document.getElementById(canvasId);
  const scale = 750 / node.offsetWidth;
  domtoimage
    .toPng(node, {
      height: node.offsetHeight * scale,
      width: node.offsetWidth * scale,
      style: {
        transform: 'scale(' + scale + ')',
        transformOrigin: 'top left',
        width: node.offsetWidth + 'px',
        height: node.offsetHeight + 'px',
      },
    })
    .then(function(dataUrl) {
      // var img = new Image();
      // img.src = dataUrl;
      // document.body.appendChild(img);
      saveAs(dataUrl, 'sdeals.png');
    })
    .catch(function(error) {
      console.error('oops, something went wrong!', error);
    });
};

export const saveAs = (uri, filename) => {
  let link = document.createElement('a');

  if (typeof link.download === 'string') {
    link.href = uri;
    link.download = filename;

    //Firefox requires the link to be in the body
    document.body.appendChild(link);

    //simulate click
    link.click();

    //remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
};
