import { Container, Message } from 'semantic-ui-react';
import React from 'react';

const EmptyMessage = () => (
  <Container text>
    <Message
      icon="meh outline"
      header="Brak wyników"
      content="Przykro nam, ale nie znaleźliśmy produktów spełniających twoje kryteria. Zajżyj tu za jakiś czas... lub zmień kryteria wyszukiwania."
    />
  </Container>
);

export default EmptyMessage;
