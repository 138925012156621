import 'semantic-ui-css/semantic.min.css';
import React from 'react';
import './App.css';
import Template from './components/shared/Template/Template';
import { ApolloProvider } from '@apollo/client';
import { client } from './utils/apolloClient';

function App() {
  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Template />
      </div>
    </ApolloProvider>
  );
}

export default App;
