import React from 'react'
import { Tab } from 'semantic-ui-react'

const Tabs = props => {
  const {activeIndex, panes, callback} = props
  return (
    <Tab className='tabs' activeIndex={activeIndex} panes={panes} onTabChange={callback}/>
  )
}

export default Tabs;
