import React from 'react';
import { Header, Modal } from 'semantic-ui-react';

const AdBlockMessage = () => {
    const [open, setOpen] = React.useState(true)

    return (
        <Modal
            onOpen={() => setOpen(true)}
            open={open}
            centered={true}
        >
            <Header icon='lock' content="Wykryliśmy, że używasz ADBLOCK'a" />
            <Modal.Content image>
                <Modal.Description>
                    <Header>Prosimy, odblokuj wyświetlanie reklam w naszym serwisie i odśwież stronę.</Header>
                    <p>W przeciwnym wypadku linki do polecanych przez naz stron nie będą działać.</p>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    )
}

export default AdBlockMessage
