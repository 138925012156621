/*eslint no-control-regex: 0*/
export const getUrl = new RegExp(
  '(^|[ \t\r\n])((ftp|http|https|gopher|mailto|news|nntp|telnet|wais|file|prospero|aim|webcal):(([A-Za-z0-9$_.+!*(),;/?:@&~=-])|%[A-Fa-f0-9]{2}){2,}(#([a-zA-Z0-9][a-zA-Z0-9$_.+!*(),;/?:@&~=%-]*))?([A-Za-z0-9$_+!*();/?:~-]))',
  'g'
);

export const removeHtmlTags = input => input.replace(/<\/?[^>]+(>|$)/g, '');

export const removeBrokenIcons = input => input.replace('🤩', '');

export const openUrl = url => {
  window.open(url, '_blank');
};

export const sortDescending = (data, sortBy) => data.sort((a, b) => b[sortBy] - a[sortBy]);
export const sortAscending = (data, sortBy) => data.sort((a, b) => a[sortBy] - b[sortBy]);

export const sortByDate = (items, sortBy) => {
  return items.sort((a, b) => {
    a = new Date(a[sortBy]);
    b = new Date(b[sortBy]);
    return a > b ? -1 : a < b ? 1 : 0;
  });
};

export const findBy = (data, valueName, value) => {
  return data.find(item => {
    return item[valueName] === value;
  });
};

export const uniqValues = data => {
  return data.filter((v, i, a) => a.findIndex(t => t['link'] === v['link']) === i);
};

export const debounce = (func, timer) => {
  let timeId = null;
  return (...args) => {
    if (timeId) {
      clearTimeout(timeId);
    }
    timeId = setTimeout(() => {
      func(...args);
    }, timer);
  };
};

export const fetchAndReturnJSON = async url => {
  const res = await fetch(url);
  return res ? res.json() : [];
};

export const fetchData = async urls => {
  let res = [];
  await Promise.all(
    urls.map(async url => {
      const contents = await fetchAndReturnJSON(url);
      if (contents.length) {
        res = [...res, ...contents];
      }
    })
  );
  return res;
};

export const limitWordsTo = (text, wordsLimit) => {
  return text.length > wordsLimit ? text.substr(0, wordsLimit - 1) + '...' : text;
};

export const updateUrl = url => {
  window.history.replaceState(null, null, url);
};

export const getPathParams = location => {
  const currentPath = location.pathname;
  return currentPath.split('/').filter(el => {
    return el !== '';
  });
};
