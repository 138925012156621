import React, { useState, useEffect } from 'react';
import Loading from '../shared/Loading';
import ResponsiveCards from '../shared/ResponsiveCards';
import { bottomOfThePage } from '../shared/bottomOfThePage';
import { useQuery } from '@apollo/client';
import { SALES_QUERY } from './Sales.gql';
import SaleCard from './SaleCard';
import { filterPromos, addTracking } from './Sales.utils';

const cards = (sales, limit) => {
  let items = sales;

  // if (limit) {
  //   items = sales.slice(0, limit);
  // }

  return items.map(sale => {
    const { url } = sale;
    return <SaleCard key={url} data={sale} />;
  });
};

const queryPerPage = 16;

const PostsQuery = props => {
  const { limit, inlineLoader } = props;
  const [loadingMore, setLoadingMore] = useState(false);

  const { loading, error, data, fetchMore } = useQuery(SALES_QUERY, {
    // variables: { offset: 0, limit: limit || queryPerPage },
  });

  // scroll listener
  // const onScroll = () => {
  //   if (bottomOfThePage('.cards > .card:last-child') && !loadingMore) {
  //     setLoadingMore(true);
  //   }
  // };

  // useEffect(() => {
  //   if (!limit && window) {
  //     window.addEventListener('scroll', onScroll);
  //   }
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // show more when hit bottom of the page
  // useEffect(() => {
  //   if (loadingMore) {
  //     fetchMore({
  //       variables: {
  //         offset: data.sales.length,
  //       },
  //       updateQuery: (prev, { fetchMoreResult }) => {
  //         if (!fetchMoreResult) return prev;
  //         setLoadingMore(false);
  //         return Object.assign({}, prev, {
  //           sales: [...prev.sales, ...fetchMoreResult.sales],
  //         });
  //       },
  //     });
  //   }
  // }, [loadingMore]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Loading inlineLoader={inlineLoader} />;
  if (error) return <p>Error :(</p>;

  const { sales } = data;

  let items = filterPromos(sales);
  items = addTracking(items);
  // items = groupPromos(products_shops,items);

  return <ResponsiveCards>{cards(items, limit)}</ResponsiveCards>;
};

export default PostsQuery;
