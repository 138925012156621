import React  from 'react';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container';
import {useResponsive} from '../../../../hooks/useResponsive'
import {Divider, Header, Icon, Segment, List} from 'semantic-ui-react';
import {footerData} from "./Footer.data";
import FooterMobile from './FooterMobile'
import {FooterLinks} from './FooterLinks'

const Footer  =  () => {
    const { isTabletOrMobile} = useResponsive();
    return (
        <div className="introBackground segmentInverted search-section" style={{marginTop: 48, borderTop: '1px solid #e4e4e4'}}>
            <Container data-testid="footer">
                <br />
                <br />
                {isTabletOrMobile ? (<FooterMobile data={footerData}/>) :   (<Grid centered>
                    <Grid.Row centered style={{fontSize: isTabletOrMobile ? 18 : 24, lineHeight: '1.3em'}}>
                        {footerData.map(footerRow => {
                            const{ title= '', children = []} = footerRow
                            return <Grid.Column mobile={4} tablet={4} computer={4}>
                                <Header as="h3">
                                    {title}
                                </Header>
                                {children.length > 0 && <FooterLinks data={children}></FooterLinks>}
                            </Grid.Column>
                        })}
                    </Grid.Row>
                </Grid>)}
                <br />
                <br />
                <br />
            </Container>
        </div>
    );

}

export default Footer