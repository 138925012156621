import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {Container, Dropdown, Icon, Menu, Responsive, Segment, Sidebar} from 'semantic-ui-react';
import { getWidth, menuItems } from './utils';
import AdBlockDetected from "../Adblock/AdBlockDetected/AdBlockDetected";
import {socials} from './Template.data'
import {openUrl} from "../../../utils/utils";
import Footer from './Footer/Footer'

const menuStyle = {
  padding: '12px 12px',
  fontSize: 16
}

const SocialIcons = ({handleSidebarHide}) => {
  return socials.map(item => {
    const {title, icon, url} = item;
    return               <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleSidebarHide}
    >
      <Menu.Item style={menuStyle}>- {title}</Menu.Item>
    </a>
  })
}

class MobileContainer extends Component {
  state = {};

  handleSidebarHide = () => this.setState({ sidebarOpened: false });
  handleToggle = () => this.setState({ sidebarOpened: true });

  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive as={Sidebar.Pushable} getWidth={getWidth} maxWidth={Responsive.onlyMobile.maxWidth}>
        <Sidebar as={Menu} animation="push" inverted onHide={this.handleSidebarHide} vertical visible={sidebarOpened}>
          {menuItems(this.handleSidebarHide)}
          <Menu.Item style={menuStyle}>
            Kanały społecznościowe
            <Menu.Menu>
              <SocialIcons handleSidebarHide={this.handleSidebarHide}/>
            </Menu.Menu>
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment inverted textAlign="center" vertical>
            <Container>
              <Menu inverted pointing secondary size="large">
                <Menu.Item as={NavLink} exact to="/" style={{fontWeight: 'bold'}}>
                  Streetdeals.pl
                </Menu.Item>
                <Menu.Item position="right" onClick={this.handleToggle} style={menuStyle}>
                  <Icon name="sidebar" style={{border: '1px solid grey', borderRadius: 4, padding: '4px 6px', width: 28, height: 26}} />
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          <AdBlockDetected/>
          {children}
          <Footer/>
        </Sidebar.Pusher>
      </Responsive>
    );
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

export default MobileContainer;
