import { gql } from '@apollo/client';

export const SALES_QUERY = gql`
  query GetSales($limit: Int, $offset: Int) {
    sales(limit: $limit, offset: $offset, order_by: { shop: { text: asc } }) {
      created_date
      id
      shop {
        id
        text
        domain
        url
        country
        affiliates_id
        ref_number
        extra_tracking
      }
      title
      message
      discount
      url
      image_url
      code
      limited_in_time
      start_date
      end_date
      sale_type
    }
  }
`;
