export const offersTypes = [
  { key: '0', value: 'drop', text: 'Drop' },
  { key: '1', value: 'okazja', text: 'Okazja' },
];

export const shops = [
  { key: '0', value: '0', text: 'Nike SNKRS App', link: 'https://sdeals.pl/pvu', mappingName: 'nike SNKRS App' },
  { key: '1', value: '1', text: 'Nike App', link: 'https://sdeals.pl/pvu', mappingName: 'nike App' },
  { key: '2', value: '2', text: 'adidas App', link: 'https://sdeals.pl/kriw', mappingName: '' },
  { key: '3', value: '3', text: 'adidas.pl', link: 'https://sdeals.pl/kriw', mappingName: 'adidas' },
  { key: '4', value: '4', text: 'reebok.pl', link: 'https://sdeals.pl/trqn', mappingName: 'reebok' },
  { key: '5', value: '5', text: 'asics.pl', link: 'https://sdeals.pl/jv0k', mappingName: '' },
  { key: '6', value: '6', text: 'nbsklep.pl', link: 'https://sdeals.pl/cvbx', mappingName: '' },
  { key: '7', value: '7', text: 'supremenewyork.com', link: 'https://supremenewyork.com', mappingName: '' },
  { key: '8', value: '8', text: 'nike ', link: 'https://sdeals.pl/1v06', mappingName: 'nike' },
];
