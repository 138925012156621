import { sortAscending } from '../../utils/utils';
import dayjs from 'dayjs';
// import isToday from 'dayjs/esm/plugin/isToday/index.js'
import {mapSizes, statusCheck, finalLink} from '../../utils/productMapper';
//
// dayjs.extend(isToday)

const sortByBrand = (a,b) =>{
  // console.log('sortByBrand')
  // console.log('a shop: ',a.shop);
  // console.log('b shop: ',b.shop)
  // if (a.premium === true && b.premium !== true) {
  //   // if (a.shop !== 'nike') {
  //   //   return -1;
  //   // }
  //   // if (a.shop === 'nike' && b.shop !== 'nike') {
  //   //   return 1;
  //   // }
  //   return -1;
  // }
  // if (a.premium === false && b.premium !== false) {
  //   // if (a.shop === 'nike' && b.shop !== 'nike') {
  //   //   return 1;
  //   // }
  //   return 1;
  // }
  if (a.shop < b.shop) {
    return -1;
  }
  if (a.shop > b.shop) {
    return 1;
  }

   // if (a.shop === 'nike' && b.shop !== 'nike') {
   //   return 1;
   // }
   // return -1;
  // if (a.shop !== 'nike' && b.shop !== 'nike') {
  //   return -1;
  // }
  // if (b.shop === 'nike' && a.shop !== 'nike') {
  //   if (a.shop > b.shop) {
  //     return -1;
  //   }
  //   if (a.shop < b.shop) {
  //     return 1;
  //   }
  // }
  // return -1;
}

export const urls = [
  // 'https://api.apify.com/v2/acts/GoufDjz4FKTKdRnMK/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&clean=true', // nike calendar
  'https://api.apify.com/v2/acts/9raauiNyqeol6d8sX/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6', // nike-app-shoes
    'https://api.apify.com/v2/acts/distracting_wild~nike-juz-wkrotce-shoes/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&clean=true', // nike latest shoes
  // 'https://api.apify.com/v2/acts/MZgZv2AQsfPQgSd3o/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6', // nike-app-clothes
  // 'https://api.apify.com/v2/acts/8mZeUmj0S3cY9qNI8/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6', // nike-app-accessories
  'https://api.apify.com/v2/actor-tasks/dvCUJzYJbpmUm9pxj/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&clean=true', // adidas
  'https://api.apify.com/v2/actor-tasks/my6RSmtVPuBBr0q1O/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&clean=true', // reebok
];

export const sortByDateAndBrand = (a, b) => {
  const aDate = new Date(a.date);
  const aYear = aDate.getUTCFullYear();
  const aMonth = aDate.getUTCMonth() + 1;
  const aDay = aDate.getUTCDate();
  const aHour = aDate.getUTCHours()

  const bDate = new Date(b.date);
  const bYear = bDate.getUTCFullYear();
  const bMonth = bDate.getUTCMonth() + 1;
  const bDay = bDate.getUTCDate();
  const bHour = bDate.getUTCHours()

  if (aYear < bYear) return -1;
  if (aYear > bYear) return 1;

  if (aMonth < bMonth) return -1;
  if (aMonth > bMonth) return 1;

  if (aDay < bDay) return -1;
  if (aDay > bDay) return 1;

  if (aDay === bDay && aMonth === bMonth) {
    if (aHour < bHour) return -1;
    if (aHour > bHour) return 1;
  }
};

const monthConverter = text => {
  switch (text) {
    case 'sty':
      return '01';
    case 'lut':
      return '02';
    case 'mar':
      return '03';
    case 'kwi':
      return '04';
    case 'maj':
      return '05';
    case 'cze':
      return '06';
    case 'lip':
      return '07';
    case 'sie':
      return '08';
    case 'wrz':
      return '09';
    case 'paz':
      return '10';
    case 'lis':
      return '11';
    case 'gru':
      return '12';
    default:
      return '';
  }
};

const setYear = month => {
  const currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const realeaseMonth = parseInt(month);
  if (realeaseMonth < currentMonth) {
    return currentYear + 1;
  }
  return currentYear;
};

export const convertStringToDate = (shop, date, source) => {
  let month = '';
  let day = '';
  let hour = '';
  let releaseDate = '';

  if (date) {
    if (shop === 'nike') {
      if (source === 'nike-app') {
        releaseDate = date;
      } else {
        const text = date.split(' ');
        day = text[0] || '';
        if (day.length === 1) {
          day = `0${day}`;
        }
        month = text[1] || '';
        // console.log('text[1]', text[1]);
        month = monthConverter(text[1]);
        // console.log('month', month);

        if (month !== '' && day !== '') {
          const year = setYear(month);
          releaseDate = `${year}-${month}-${day}:09:00`;
        }
      }
    }

    if (shop === 'adidas' || 'reebok') {
      const text = date.split(' ');
      if (text.length > 1) {
        day = text[1] || '';
        month = monthConverter(text[2]);
        hour = text[3] || '00:00';

        if (month !== '' && day !== '') {
          const year = setYear(month);
          releaseDate = `${year}-${month}-${day}:${hour}`;
          // console.log('releaseDate', releaseDate);
        }
      } else {
        releaseDate = date;
      }
    }
  }

  return releaseDate;
};

export const filterOnlyNewProdutcs = products => {
  return products.filter(item => {
    const { Release } = item;
    const currentDate = new Date();
    const releaseDate = new Date(Release);
     return releaseDate < currentDate;
  });
};

export const filterOnlyNewProdutcs2 = products => {
  return products.filter(item => {
    const { Release } = item;
    const currentDate = new Date();
    const releaseDate = new Date(Release);
    releaseDate.setHours(23, 59, 59, 999);
    return releaseDate < currentDate;
  });
};

export const filterOnlyPRomotedProdutcs = products => {
  return products.filter(item => {
    const { title } = item;
    const titleLowerCase = title.toLowerCase();
    const words = ['air force', 'air max', 'vapormax', 'jordan', 'dunk'];
    return words.some(word => {
      if (titleLowerCase.includes(word)) return true;
    });
  });
};

export const sortProductsBy = products => {
  return products.sort(function(a, b) {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });
};

export const roundeThePrice = price => {
  const newPrice = price ? Math.round(parseInt(price)) : '';
  return `${newPrice} zł`;
};

const premierInSnkrsApp = (shop, source, channels, item) => {
  if (channels && channels.includes('SNKRS') && !channels.includes('Nike.com')) return true;
  return false;
};

const releasePlace = (shop, source, snkrs, onlyNikeApp) => {
  if (source === 'nike-app') {
    if (onlyNikeApp) return 'nike App';
    return 'nike';
  }
  if (snkrs) return 'nike SNKRS App';
  return shop;
};

const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

const releaseDate = (shop, source, release, date, channels) => {
  // if (shop === 'adidas') {
  //   // console.log('shop',shop);
  //   // console.log('source',source)
  //   // console.log('release',release)
  // }

  // const test = new Date();
  // console.log('test',test.getDay())


  if (source === 'nike-calendar') return release;
  if (source === 'nike-app') {
    const currentDate = new Date();
    const releaseDate = new Date(release);
    if (releaseDate >= currentDate) {
      return release;
    } else {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      if(datesAreOnSameDay(yesterday, releaseDate) && channels.includes('SNKRS')){
        const dropDate = new Date(date);
        return dropDate.setHours(9, 0, 0);
      }
    }
  }
  if (shop === 'adidas' || shop === 'reebok') {
    return convertStringToDate(shop, release, source);
  }
  return release;
};

// const replaceLinkIfSnkrsAppRealse = (link, snkrsApp) => {
//   const newUrl = 'https://www.nike.com/pl/launch/t/buty-meskie-air-jordan-6-retro-low';
//   return link;
// };

const checkItIsDrop = (shop, source, release) => {
  if (source === 'nike-calendar' || shop === 'adidas' || shop === 'reebok') return true;
  if (source === 'nike-app') {
    const currentDate = new Date();
    const releaseDate = new Date(release);
    releaseDate.setHours(23, 59, 59, 999);
    if (releaseDate >= currentDate) {
      return true;
    }
  }
  return false;
};

const getShopName = (name, OnlyNikeApp) =>{
  if(name === 'reebok') return 'Reebok.com'
  if(name === 'adidas') return 'Adidas.com'
  if(name === 'nike') {
    if(OnlyNikeApp)  return 'Nike APP'
    return 'Nike.com'
  }
  return name
}

const productPremium = title => {
  const titleLowerCase = title.toLowerCase();
  const words = [
    'yeezy',
    'yzy',
    'dunk',
    'air jordan 1 ',
    'air max 1',
    'jaden smith',
    'supreme',
    'comme des garcons',
    'dior',
    'pleasures',
    'ultra boost',
    'air jordan 4',
    'cpfm',
    'air jordan iv',
    'air jordan 5',
    'air jordan v',
    'air force',
    'ambush',
    'pharrell',
    'ruchan wang',
    'prada',
    'beyounce',
    'paris saint',
    'travis scott',
    'stussy',
    'sacai',
    'off-white',
    'retro',
    'lego',
    'skepta',
    'nocta',
  ];

  const notPremium = ['air jordan 1 acclimate'];

  if (
    notPremium.some(word => {
      if (titleLowerCase.includes(word)) return true;
    })
  )
    return false;

  return words.some(word => {
    if (titleLowerCase.includes(word)) return true;
  });
};

export const mapData = data => {
  return data.map(item => {
    const {
      Image,
      BigImage = '',
      AwinLink,
      Title,
      Price,
      Shop,
      Release,
      Type,
      LaunchMethod,
      Source,
      OnlyNikeApp,
      Channels,
      StyleProduct,
      Status,
      Model,
      MainCategory,
        Date,
        Sizes
    } = item;

    const snkrs = premierInSnkrsApp(Shop, Source, Channels, item);
    const where = releasePlace(Shop, Source, snkrs, OnlyNikeApp);
    const isDrop = checkItIsDrop(Shop, Source, Release);
    const date = releaseDate(Shop, Source, Release, Date, Channels);
    const premium = productPremium(Title);
    const buyUrl = finalLink(AwinLink, OnlyNikeApp);
    const shopName = getShopName(Shop, OnlyNikeApp);
    const model = StyleProduct;

    return {
      title: Title,
      where,
      message: '',
      imageUrl: Image,
      bigImage: BigImage,
      date,
      price: Price ? roundeThePrice(Price) : 'N/A',
      finalPrice: Price,
      shop: Shop,
      shopName,
      buyUrl,
      snkrs,
      model,
      method: LaunchMethod,
      isDrop,
      premium,
      code: StyleProduct,
      sizes: Sizes,
      mainCategory: MainCategory
    }

  });
};

const notStreetwear = type => {
  return type === 'Męskie Piłka Nożna';
};

export const findsEventsWithDate = events => {
  return events.filter(item => {
    const { date, type } = item;
    return date !== '' && !notStreetwear(type);
  });
};

export const findCoomingEvents = events => {
  return events.filter(item => {
    const { date, type, shop } = item;
    return date === '' && !notStreetwear(type) && shop === 'nike';
  });
};

const getFormattedDate = dateString => {
  var date = new Date(dateString);
  date.setHours(23, 59, 59, 999); // Set hours, minutes and seconds
  return date.toString();
};

export const justShoes = (products) => {
  // return products
  return products.filter(product => {
    const {mainCategory} = product
    if(mainCategory){
      if(mainCategory !== 1) return false
    }
    return true;
  })
}

export const filterUnwantedProducts = (products) => {
  return products.filter(product => {
    const {title} = product;

    // Dont show old products
    const today = new Date();
    const todayDay = today.getUTCDate();
    const date = new Date(product.date);
    const dateDay = date.getUTCDate();
    if(dateDay < todayDay) return false;
    if(title){
      const lowerTitle = title.toLowerCase();
      if(!lowerTitle.includes('atric23') && !lowerTitle.includes('hiking')) return true
    }
    return false
  })
}

export const filterOldEvents = events => {
  return events.filter(event => {
    const {date} = event;
    const today = new Date();
    const test1 = getFormattedDate(dayjs(today, 'Poland').toString());
    let eventDate = new Date(date);

    const test2 = getFormattedDate(
      dayjs(eventDate, 'Poland')
        .hour(23)
        .minute(59)
        .second(59)
        .toString()
    );

    return dayjs(test2).unix() >= dayjs(test1).unix();

    return false;
  });
};

export const uniqValues = data => {
  console.log('data', data);
  return data.filter((v, i, a) => a.findIndex(t => t['imageUrl'] === v['imageUrl']) === i);
};
