import React, { FunctionComponent } from 'react';
import { Container} from 'semantic-ui-react';
import Title from '../shared/Title';

const Tv: FunctionComponent<{}> = (props: any) => {
  return (
    <Container>
      <Title title="Tv" icon="tv"></Title>
    </Container>
  );
};

export default Tv;
