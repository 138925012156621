import React from 'react';
import { Menu, Responsive } from 'semantic-ui-react';
import { routes } from './Template.data';
import { NavLink } from 'react-router-dom';

export const getWidth = () => {
  const isSSR = typeof window === 'undefined';
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};

const menuStyle = {
    fontSize: 16,
    // border: '1px solid red',
    padding: '12px 12px',
    marginRight: '8px',
    margin: 0
}

export const menuItems = callback =>
  routes.map(item => {
    const { title, url, newTab, exact = false } = item;
    return newTab ? (
      <a href={url} key={url} target="_blank"rel="noopener noreferrer" onClick={callback}>
        <Menu.Item style={menuStyle}>{title}</Menu.Item>
      </a>
    ) : (
      <Menu.Item style={menuStyle} key={url} as={NavLink} to={url} exact={exact} activeClassName="active" onClick={callback}>
        {title}
      </Menu.Item>
    );
  });
