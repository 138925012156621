import React from 'react';
import { Visibility, Image, Placeholder } from 'semantic-ui-react';
import './LazyImage.css';

type Shop = {
  id: number;
};

type Props = {
  src: string;
  size?: any;
  ui?: boolean;
  wrapped?: boolean;
  shop?: Shop;
  label?: object;
};

type State = {
  show: boolean;
};

export default class LazyImage extends React.Component<Props, State> {
  static defaultProps = {
    size: `medium`,
  };

  state = {
    show: false,
  };

  showImage = () => {
    this.setState({
      show: true,
    });
  };

  // @ts-ignore
  revertImage = shop => {
    if(typeof shop === 'string') {
      if(shop === 'worldbox'||  shop === 'eobuwie'|| shop === 'distance'|| shop === 'adidas' || shop === 'reebok') return true;
    } else {
      const { id } = shop;
      if(id === 4 || id === 6 || id === 5|| id === 10 || id === 11) return true;
    }
    return false;
  }

  render() {
    const { src, size, shop ={} } = this.props || {};
    // console.log('SHOP', shop);

    // console.log('shop',shop)

    // @ts-ignore
    // const { id } = shop;
    //   const style = {
    //     transform: shop === 'worldbox'||  shop === 'eobuwie'|| shop === 'distance'|| shop === 'adidas' || shop === 'reebok' ? 'scaleX(-1)' : ''
    // }

    const className =
      this.revertImage(shop) ? 'revertPhoto' : '';

    if (!this.state.show) {
      return (
        <Visibility as="span" onTopVisible={this.showImage} fireOnMount={true}>
          <Placeholder>
            <Placeholder.Image rectangular />
          </Placeholder>
        </Visibility>
      );
    }
    return <Image className={className} {...this.props} src={src} size={size} />;
  }
}
