export const bottomOfThePage = selector => {
  const element = document.querySelector(selector);
  if (element) {
    const { offsetTop, clientHeight } = element;
    const { pageYOffset, innerHeight } = window;

    const lastElementOffset = offsetTop + clientHeight;
    const pageOffset = pageYOffset + innerHeight;

    if (pageOffset + 200 > lastElementOffset) return true;

    return false;
  }
};
