import React, {useState} from 'react'
import { Icon, Message, Container } from 'semantic-ui-react'

const Telegram = () => {
    const [visible, setVisible] = useState(true)

    const handleDismiss = () => {
        setVisible(false)
    }

    if(!visible) return null;

    return <Container>
        <Message icon color="info" style={{margin: '10px 0', padding: 14}} onDismiss={handleDismiss}>
                <Icon name='telegram' style={{fontSize: 24}} />
                <Message.Content style={{paddingRight: 10, fontSize: 15}}>
                    {/*<Message.Header>Nowa grupa</Message.Header>*/}
                    Po najszybsze powiadomienia o Dropach, Early Access i Okazjach wbijaj na nasz kanał w aplikacji: <strong><a target="_blank" href='https://t.me/+cPqY0OXxbGkyZTFk'> TELEGRAM </a></strong>
                </Message.Content>
        </Message>
    {/*    <Message  color="info" style={{margin: 10, padding: 14}} onDismiss={handleDismiss}>*/}
    {/*    <Icon name='bullhorn' style={{fontSize: 16 }}/>*/}
    {/*    */}

    {/*</Message>*/}
    </Container>
}

export default Telegram
