import React from 'react';
import { filters, gender } from '../../../../utils/data';

// import {
//   isJordan,
//   isAirmax,
//   isOtherShoes,
//   isAirForce,
//   isTshirt,
//   isTrousers,
//   isJacket,
//   isAccessory,
//   isCap,
//   isBackpack,
//   isBag,
//   isKidneyBag,
//   isHoodie,
//   isShort,
//   isClothes,
//   isShoe,
//   titleIncludesText,
// } from './filters';
import { updateUrl, sortDescending, sortAscending, findBy } from '../../../../utils/utils';
// import { shoesSizes } from '../../../../utils/data';

// const filterByGender = (filterGender, productGender) => {
//   if (filterGender) {
//     if (filterGender === gender[0].value) return true;
//     if (filterGender === gender[1].value) {
//       return productGender === 'M' || productGender === 'U';
//     }
//     if (filterGender === gender[2].value) {
//       return productGender === 'W' || productGender === 'U';
//     }
//     return false;
//   }
//   return true;
// };
//
// const filterByBrand = (brand, productBrand, productTitle) => {
//   if (brand === '' || brand === 'all') return true;
//   const title = productTitle.toLowerCase();
//   if (brand === productBrand || title.includes(brand)) return true;
//   return false;
// };
//
// const findShoesFilterSize = (size, type) => {
//   let filterValue = '';
//   const filter = shoesSizes.find(s => {
//     return s.value === size;
//   });
//   if (filter && filter[type]) {
//     filterValue = filter[type];
//   }
//   return filterValue;
// };

// const filterBySize = (size, sizes, type) => {
//   if (size === '' || size === 'all') return true;
//
//   if (size && sizes && sizes.length > 0) {
//     if (size === '2XL') {
//       return sizes.includes(size) || sizes.includes('XXL');
//     }
//     if (size === '3XL') {
//       return sizes.includes(size) || sizes.includes('XXXL');
//     }
//
//     if (type === 'buty') {
//       const convertedSize = findShoesFilterSize(size, 'filter1');
//       const convertedSizeTwo = findShoesFilterSize(size, 'filter2');
//
//       if (convertedSize && convertedSizeTwo) {
//         return sizes.includes(convertedSize) || sizes.includes(convertedSizeTwo);
//       } else {
//         return sizes.includes(convertedSize);
//       }
//     }
//     return sizes.includes(size);
//   }
//   return false;
// };

// export const sortData = (data, filter) => {
//   const { sortBy } = filter;
//
//   //  sort ascending by: reduced price 0 > 100
//   if (sortBy === 'newest') {
//     return sortAscending(data, 'order');
//   }
//
//   // sort descending by: percentage 100 > 0
//   if (sortBy === 'discount') {
//     return sortDescending(data, 'percentage');
//   }
//   //  sort ascending by: reduced price 0 > 100
//   if (sortBy === 'cheapest') {
//     return sortAscending(data, 'finalPrice');
//   }
//
//   if (sortBy === 'mostExpensive') {
//     return sortDescending(data, 'finalPrice');
//   }
// };

// const filterByColor = (productColor, filterColor) => {
//   if (filterColor === '' || filterColor === 'all') return true;
//   return productColor.includes(filterColor);
//   return false;
// };

// const filterByLotsOfSizes = (onlyLotsOfSizes, productType, productSizes) => {
//   if (onlyLotsOfSizes) {
//     if (productType === 1 && productSizes.length > 10) return true;
//     if (productType === 2 && productSizes.length > 4) return true;
//     return false;
//   }
//   return true;
// };

// const commonFilters = (
//   filter,
//   productGender,
//   productSizes,
//   productColor,
//   productBrand,
//   productTitle,
//   onlyLotsOfSizes
// ) => {
//   const { mainCategory, subCategory, model, shoeSize, clothesSize, gender, color, brand } = filter;
//
//   if (mainCategory === 'buty') {
//     return (
//       filterByBrand(brand, productBrand, productTitle) &&
//       filterByGender(gender, productGender) &&
//       filterBySize(shoeSize, productSizes, mainCategory) &&
//       filterByColor(productColor, color) &&
//       filterByLotsOfSizes(onlyLotsOfSizes, 1, productSizes)
//     );
//   }
//
//   if (mainCategory === 'ciuchy') {
//     return (
//       filterByBrand(brand, productBrand, productTitle) &&
//       filterByGender(gender, productGender) &&
//       filterBySize(clothesSize, productSizes, mainCategory) &&
//       filterByColor(productColor, color) &&
//       filterByLotsOfSizes(onlyLotsOfSizes, 2, productSizes)
//     );
//   }
//
//   return (
//     filterByBrand(brand, productBrand, productTitle) &&
//     filterByGender(gender, productGender) &&
//     filterByColor(productColor, color)
//   );
// };

// export const filterData = (data, filter) => {
//   const newData = [...data];
//
//   const { mainCategory, subCategory, model, onlyLotsOfSizes } = filter;
//
//   let filterBy;
//   if (subCategory !== '' && subCategory === 'airmax' && model !== '') {
//     filterBy = model;
//   } else if (subCategory !== '') {
//     filterBy = subCategory;
//   } else {
//     filterBy = mainCategory;
//   }
//
//   return newData.filter(item => {
//     const { type, title, gender, sizes, color: productColor, brand } = item;
//     const productTitle = title.toLowerCase();
//     const productType = type.toLowerCase();
//     const productBrand = brand.toLowerCase();
//
//     if (subCategory === 'airmax') {
//       switch (model) {
//         case 'airmax1':
//           return (
//             titleIncludesText(productTitle, 'air max 1') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmax90':
//           return (
//             titleIncludesText(productTitle, 'air max 90') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmax95':
//           return (
//             titleIncludesText(productTitle, 'air max 95') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmax97':
//           return (
//             titleIncludesText(productTitle, 'air max 97') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmax98':
//           return (
//             titleIncludesText(productTitle, 'air max 98') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmax200':
//           return (
//             titleIncludesText(productTitle, 'air max 200') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmax270':
//           return (
//             titleIncludesText(productTitle, 'air max 270') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmax2090':
//           return (
//             titleIncludesText(productTitle, '2090') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmaxtailwind':
//           return (
//             titleIncludesText(productTitle, 'air max tailwind') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmaxshox':
//           return (
//             titleIncludesText(productTitle, 'shox') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmaxverona':
//           return (
//             titleIncludesText(productTitle, 'verona') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmaxup':
//           return (
//             titleIncludesText(productTitle, 'air max up') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmaxplus':
//           return (
//             titleIncludesText(productTitle, 'air max plus') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmaxtriax':
//           return (
//             titleIncludesText(productTitle, 'triax') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmaxdia':
//           return (
//             titleIncludesText(productTitle, 'air max dia') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmaxvapormax':
//           return (
//             titleIncludesText(productTitle, 'vapormax') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmaxgraviton':
//           return (
//             titleIncludesText(productTitle, 'graviton') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmaxexcee':
//           return (
//             titleIncludesText(productTitle, 'excee') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmaxinvigor':
//           return (
//             titleIncludesText(productTitle, 'invigor') &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airmax720':
//           return (
//             (titleIncludesText(productTitle, 'max 720') || titleIncludesText(productTitle, ' mx-720')) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'all':
//           return (
//             isAirmax(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         default:
//           return true;
//       }
//     } else if (mainCategory === 'buty') {
//       switch (filterBy) {
//         case 'wszystkie':
//           return (
//             isShoe(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'do-kosza':
//           return (
//             isJordan(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'airforce':
//           return (
//             isAirForce(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'pozostale':
//           return (
//             isOtherShoes(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         default:
//           return commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes);
//       }
//     } else if (mainCategory === 'ciuchy') {
//       switch (filterBy) {
//         case 'ciuchy':
//           return (
//             isClothes(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'bluzy':
//           return (
//             isHoodie(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'tshirty':
//           return (
//             isTshirt(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'spodenki':
//           return (
//             isShort(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'spodnie':
//           return (
//             isTrousers(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'kurtki':
//           return (
//             isJacket(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         default:
//           return commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes);
//       }
//     } else if (mainCategory === 'dodatki') {
//       switch (filterBy) {
//         case 'dodatki':
//           return (
//             isAccessory(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'czapki':
//           return (
//             isCap(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'plecaki':
//           return (
//             isBackpack(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'torebki':
//           return (
//             isBag(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         case 'nerki':
//           return (
//             isKidneyBag(productTitle, productType) &&
//             commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes)
//           );
//         default:
//           return true;
//       }
//     } else {
//       switch (filterBy) {
//         case 'buty':
//           return commonFilters(filter, gender, sizes, productColor, productBrand, productTitle, onlyLotsOfSizes);
//         default:
//           return true;
//       }
//     }
//   });
// };

// export const filterAndSortData = (data, filter) => {
//   data = filterData(data, filter);
//   data = sortData(data, filter);
//   return data;
// };

export const getQueryParams = search => {
  const params = [];
  const urlSearchParams = new URLSearchParams(search);
  urlSearchParams.forEach((value, key) => {
    params.push({ key, value });
  });

  return params;
};

export const updateCurrentUrlInBrowser = (queryParams, filter) => {
  const {
    mainCategory,
    subCategory,
    gender,
    shop,
    sortBy,
    model,
    subModel,
    shoeSize,
    clothesSize,
    color,
    brand,
    onlyLotsOfSizes,
  } = filter;

  const route = subCategory !== '' ? `${mainCategory}/${subCategory}` : mainCategory;
  const queryGender = gender ? `?gender=${gender}` : '?gender=';
  const queryShop = shop ? `&shop=${shop.value}` : '';
  const queryBrand = brand ? `&brand=${brand}` : '';
  const queryModel = model ? `&model=${model}` : '';
  const querySubModel = subModel ? `&subModel=${subModel}` : '';
  const queryShoeSize = shoeSize ? `&shoeSize=${shoeSize}` : '';
  const queryClothesSize = clothesSize ? `&clothesSize=${clothesSize}` : '';
  const queryColor = color ? `&color=${color}` : '';
  const querySortBy = sortBy ? `&sortBy=${sortBy}` : '';
  const queryOnlyLotsOfSizes = onlyLotsOfSizes ? `&onlyLotsOfSizes=${onlyLotsOfSizes}` : '';

  updateUrl(
    `/${queryParams[0]}/${route}${queryGender}${queryShop}${queryBrand}${queryModel}${querySubModel}${queryShoeSize}${queryClothesSize}${queryColor}${querySortBy}${queryOnlyLotsOfSizes}`
  );
};

const convertTrueFalseString = value => {
  if (value === 'true') return true;
  return value;
};

export const getDefaultFilter = (shops, sortByOptions, pathParams, queryParams) => {
  // const defaultShopValue = shops[1].value;
  // const shop = findShop(shops, defaultShopValue);

  const defaultFilter = {
    shop: shops[0],
    brand: 'all',
    mainCategory: filters[0].value,
    subCategory: '',
    model: filters[0].categories[0].value,
    subModel: '',
    gender: gender[0].value,
    sortBy: sortByOptions[0].value,
    shoeSize: 'all',
    clothesSize: 'all',
    color: 'all',
    onlyLotsOfSizes: false,
  };

  const path1 = pathParams[1] || '';
  const path2 = pathParams[2] || '';

  if (path1) defaultFilter.mainCategory = path1;
  if (path2) defaultFilter.subCategory = path2;

  if (defaultFilter.mainCategory === '2' || defaultFilter.mainCategory === 3) {
    defaultFilter.model = '';
    defaultFilter.subModel = '';
  }

  // if (defaultFilter.subCategory === 'airmax') {
  //   defaultFilter.model = filters[0].categories[1].value;
  // }

  queryParams.forEach(param => {
    const { key, value } = param;
    if (defaultFilter.hasOwnProperty(key)) {
      defaultFilter[key] = convertTrueFalseString(value);

      if (key === 'shop') {
        const shop = findByValue(shops, value, 'value');
        if (shop) {
          defaultFilter['shop'] = shop;
        }
      }
    }
  });

  return defaultFilter;
};

export const findByValue = (list, value, valueName) => {
  return findBy(list, valueName, value);
};
