import React from 'react';
import { Button, Checkbox, Form, Grid } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { shops, offersTypes } from './ImageCreator.data';
import { saveAs, test } from './ImageCreator.utils';

function ImageCreatorForm(props) {
  const {
    data,
    onPromoTypeChange,
    callback,
    currentDate,
    datePickerCallback,
    shopCallback,
    refInput,
    handleFileChange,
    grid,
    onGridChange,
    onScaleChange,
    onPositionChange,
    canvasId,
    canvasWidth,
    canvasHeight,
  } = props;

  const savePicture = () => {
    test(canvasId, canvasWidth, canvasHeight);
  };

  const { promoType, shop, date, hour, title, retail, resell } = data;
  return (
    <Form>
      <Form.Field inline>
        {/*<label>Sklep:</label>*/}
        <Form.Select
          fluid
          options={offersTypes}
          placeholder="Rodzaj Promocji"
          onChange={onPromoTypeChange}
          value={promoType}
        />
        {/*<input placeholder="Shop:" value={shop} onChange={e => callback(e, 'shop')} />*/}
      </Form.Field>
      {promoType === 'drop' ? (
        <>
          <Form.Field inline>
            {/*<label>Sklep:</label>*/}
            <Form.Select fluid options={shops} placeholder="Sklep" onChange={shopCallback} value={shop} />
            {/*<input placeholder="Shop:" value={shop} onChange={e => callback(e, 'shop')} />*/}
          </Form.Field>
          <Form.Field inline>
            {/*<label>Data:</label>*/}
            <SemanticDatepicker
              showToday={true}
              value={currentDate}
              onChange={datePickerCallback}
              clearable={false}
              format="DD.MM.YYYY"
              locale="pl-PL"
            />
            <input
              placeholder="Hour"
              value={hour}
              onChange={e => callback(e, 'hour')}
              style={{ marginLeft: '15px', maxWidth: '80px' }}
            />
          </Form.Field>
          {/*<Form.Field inline>*/}
          {/*  <label>Godzina:</label>*/}
          {/*  <input placeholder="Hour" value={hour} onChange={e => callback(e, 'hour')} />*/}
          {/*</Form.Field>*/}
          <Form.Field inline>
            {/*<label>Tytuł:</label>*/}
            <Form.Input fluid value={title} placeholder="Tytuł" onChange={e => callback(e, 'title')} />
            {/*<input placeholder="Tytuł" value={title} onChange={e => callback(e, 'title')} />*/}
            {/*<Form.TextArea placeholder="Tytuł:" value={title} onChange={e => callback(e, 'title')} />*/}
          </Form.Field>
          <Form.Field inline>
            {/*<label>Retail:</label>*/}
            <input placeholder="Retail" type="number" value={retail} onChange={e => callback(e, 'retail')} />
          </Form.Field>
          <Form.Field inline>
            {/*<label>Resell:</label>*/}
            <input placeholder="Resell" type="number" value={resell} onChange={e => callback(e, 'resell')} />
          </Form.Field>
          <Form.Field inline>
            <Button content="Wybierz plik" labelPosition="left" icon="file" onClick={() => refInput.current.click()} />
            <input ref={refInput} type="file" hidden onChange={handleFileChange} />
          </Form.Field>
          <Form.Field inline>
            <label>Rozmiar zdjęcia:</label>
            <Button class="ui button" icon="minus" onClick={() => onScaleChange()}></Button>
            <Button class="ui button" icon="plus" onClick={() => onScaleChange('up')}></Button>
          </Form.Field>
          <Form.Field inline>
            <label>Pozycja zdjęcia:</label>
            <Button class="ui button" icon="arrow up" onClick={() => onPositionChange('up')}></Button>
            <Button class="ui button" icon="arrow down" onClick={() => onPositionChange()}></Button>
          </Form.Field>
          <Form.Field inline>
            {/*<label>Siatka:</label>*/}
            <Checkbox checked={grid} label="Pomocnicza siatka" onChange={onGridChange} defaultChecked />
          </Form.Field>
        </>
      ) : (
        ''
      )}
      <Form.Field inline>
        <Button class="ui primary button" onClick={savePicture}>
          Zapisz obrazek
        </Button>
      </Form.Field>
    </Form>
  );
}

export default ImageCreatorForm;
