import React, { Component } from 'react';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import Title from '../../shared/Title';
import {useResponsive} from '../../../hooks/useResponsive'

const Telegram  =  () => {
    const { isTabletOrMobile} = useResponsive();
    return (
        <div className="introBackground segmentInverted search-section">
            <Container data-testid="telegram">
                <br />

                <Title
                    title="Telegram"
                    icon="telegram"
                    // subHeader="Otrzymuj natychmiastowe powiadomienia o Early Access, Shock Dropach i Okazjach za pośrednictwem naszej grupy na Telegramie!"
                ></Title>
                {/*<br />*/}
                <Grid centered>
                    <Grid.Row centered>
                        <Grid.Column mobile={16} tablet={10} computer={9} style={{fontSize: isTabletOrMobile ? 18 : 24, lineHeight: '1.3em'}}>
                            <p>Otrzymuj natychmiastowe powiadomienia o: <strong>Early Access</strong>, <strong>Shock Dropach</strong> i <strong>Okazjach</strong> Streetwear za pośrednictwem naszej grupy na Telegramie!</p>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={6} computer={4} style={ isTabletOrMobile ? { display: 'flex',   flexFlow: 'column', marginTop: 18, maxWidth: '50%'} : {display: 'flex',alignContent: 'center',     flexWrap: 'wrap'}}>
                            <Button color='telegram' size={isTabletOrMobile ? 'big' : 'big'} color='blue'>
                                <Icon name='telegram' /> Dołącz
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <br />
                <br />
                <br />
            </Container>
        </div>
    );

}

export default Telegram