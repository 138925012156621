import React from 'react';
import { postsList } from './PromotedProducts.utils';
import Loading from '../../shared/Loading';
import ResponsiveCards from '../../shared/ResponsiveCards';
import { useQuery } from '@apollo/client';
import { PROMOTED_PRODUCTS_QUERY } from './PromotedProducts.gql';
import { productMapper } from '../../../utils/productMapper';
import More from '../../shared/More';

const queryPerPage = 8;

const PromotedProductsQuery = ({ shopId, limit, inlineLoader, searchBy = '', moreUrl }) => {
  // console.log('shopId', shopId);
  // console.log('searchBy', searchBy);

  let titleLike1;
  let titleLike2;
  let titleLike3;

  if (shopId === 2) {
    titleLike1 = '%Air Max%';
    titleLike2 = '%Vapormax%';
    titleLike3 = '%Zoom Air%';
  }

  // adidas
  if (shopId === 10) {
    titleLike1 = '%Falcon%';
    titleLike2 = '%Superstar%';
    titleLike3 = '%OZWEEGO%';
  }

  // Reebok
  if (shopId === 11) {
    titleLike1 = '%Classic%';
    titleLike2 = '%Club%';
    titleLike3 = '%Royal%';
  }

  const { loading, error, data } = useQuery(PROMOTED_PRODUCTS_QUERY, {
    variables: { shopId, offset: 0, limit: limit || queryPerPage, titleLike1, titleLike2, titleLike3 },
  });

  if (loading) return <Loading inlineLoader={inlineLoader} />;
  if (error) return <p>Error :(</p>;

  const items = productMapper(data.products, { products_sizes: data.products_sizes });

  // console.log('items', items);

  return (
    <>
      <ResponsiveCards>{postsList(items)}</ResponsiveCards> <br />
      <More url={moreUrl} title="Więcej Okazji" />
    </>
  );
};

export default PromotedProductsQuery;
