import {List} from "semantic-ui-react";
import React from "react";

const linkStyles = {
    fontSize: 14,
    color: 'grey'
}

export const LinkItem = ({href, title, style}) => {
    return <List.Item as='a' href={href} style={{...style,...linkStyles}}>{title}</List.Item>
}

export const SubList = ({data, title, url}) => {
    if(data?.length > 0) {
        return <>
            <List.Item as='a' href={url} style={linkStyles}>{title}</List.Item>
            {data.map(item => {
                const {title, url : itemUrl} = item;
                return <LinkItem title={title} href={itemUrl} style={{marginLeft: 12}}></LinkItem>
            })}
        </>
    }
    return null;
}

export const FooterLinks = ({data}) => {
    return <List size="mini">
        {data.map(item => {
            const {title, url, children} = item;
            if(children?.length > 0) return <SubList data={children} title={title} url={url}></SubList>
            return <LinkItem href={url} title={title} />
        })}
    </List>
}