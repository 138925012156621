import PropTypes from 'prop-types';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';
import Posts from '../../Posts/Posts';
import Drops from '../../Drops/Drops';
import Sales from '../../Sales/Sales';
import Index from '../../Index/Index';
import AboutUs from '../../AboutUs/AboutUs';
import Steals from '../../Steals/Steals';
import Shops from '../../Shops/Shops';
import NewReleases from '../../NewReleases/NewReleases';
import Tv from '../../Tv/Tv';
import ImageCreator from '../../ImageCreator/ImageCreator';
import DesktopContainer from './DesktopContainer';
import MobileContainer from './MobileContainer';
import Telegram from '../../shared/Telegram'

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer><Telegram/>{children}</DesktopContainer>
    <MobileContainer><Telegram/>{children}</MobileContainer>
  </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

const HomepageLayout = () => {
  function handleUpdate() {
    let { action } = this.state.location;

    if (action === 'PUSH') {
      window.scrollTo(0, 0);
    }
  }

  return (
    <Router onUpdate={handleUpdate}>
      <ResponsiveContainer>
        {/*<Segment vertical>*/}
        <Switch>
          <Route exact path="/">
            <Index />
          </Route>
          <Route exact path="/newsy">
            <Posts infiniteScroll />
          </Route>
          <Route path="/okazje">
            <Steals />
          </Route>
          <Route path="/nowosci">
            <NewReleases />
          </Route>
          <Route exact path="/kody">
            <Sales />
          </Route>
          <Route path="/dropy">
            <Drops />
          </Route>
          <Route path="/sklepy">
            <Shops />
          </Route>
          <Route path="/o-nas">
            <AboutUs />
          </Route>
          <Route path="/tv">
            <Tv />
          </Route>
          <Route path="/image">
            <ImageCreator />
          </Route>
        </Switch>
        {/*</Segment>*/}
      </ResponsiveContainer>
    </Router>
  );
};

export default HomepageLayout;
