import React, { Component } from 'react';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container';
import Calendar from '../Drops/Calendar';
import Title from '../shared/Title';

export default class IndexCalendar extends Component {
  render() {
    return (
      <Container data-testid="index-calendar">
        <Title title="Dropy" icon="calendar"></Title>
        <Calendar perPage={4} />
        <br />
      </Container>
    );
  }
}
