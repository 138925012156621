import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const More = props => {
  const {url, title} = props
  return (
    <div style={{width: '100%',textAlign: 'center'}}>
      <Link to={url}>
        <Button>
          {title}
        </Button>
      </Link>
    </div>
  );
};

export default More;
