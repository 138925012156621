import React, { memo } from 'react';
import { gender } from '../../../../utils/data';
import { Form, Grid, Responsive, Select, Header, Checkbox } from 'semantic-ui-react';
import { polishPlurals } from 'polish-plurals';
import TabFilter from './TabFilter';
import dayjs from 'dayjs';
import './Filter.css';

const findByValue = (data, name, value) => data.find(item => item[name] === value);

const brandsFilter = shop => {
  const { multiBrands } = shop;
  if (multiBrands) return true;
  return false;
};

// const shoesCategory = shop => {
//   const { value } = shop;
//   if (value === 'all' || value === 'nike') return true;
//   return false;
// };

const finndActiveSubCategory = (activeMainCategory, activeFilter) => {
  let activeSubCategory = '';
  const { categories = [] } = (activeMainCategory = {});
  if (categories.length > 0) {
    const { value } = categories[0];
    const { subCategory = '' } = activeFilter;
    activeSubCategory = subCategory || value;
  }
  return activeSubCategory;
};

const getSizes = (sizes, typeId) =>
  sizes.filter(size => {
    return size.type_id === typeId;
  });

const getShoesModels = (models, shop, model) => {
  // console.log('shop', shop)

  if (shop === 'all') {
    return models.filter(model => {
      const { parent_id } = model;
      return parent_id === null;
    });
  } else {
    let brandId;
    if (shop === 'nike') brandId = 16;
    if (shop === 'adidas') brandId = 26;

    if (brandId) {
      return models.filter(model => {
        const { parent_id, brand_id } = model;
        // console.log('model', model);
        return parent_id === null && brand_id === brandId;
      });
    }

    return [];
  }
};

const sortShoesModelsByName = models => {
  return models.sort((a, b) => {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  });
};

const getShoesSubModels = (models, shop, mainModelValue) => {
  // console.log('shop', shop)

  let res = [];

  const mainModel = getShoesModelByValue(models, mainModelValue);

  if (mainModel && mainModel.id) {
    // console.log('mainModelId', mainModel);
    return models.filter(model => {
      const { parent_id } = model;
      return parent_id === mainModel.id;
    });
  }
  return res;
};

const getShoesModelByValue = (models, shoesModelValue) => {
  return models.find((shoesModel, i) => {
    const { value } = shoesModel;
    // console.log('shoesModelValue', shoesModelValue);
    // console.log('value', value);
    return value === shoesModelValue;
  });
};

const Filter = props => {
  const { currentShop, lastFetch, shops, sortByOptions, filters, activeFilter, callback, counter, filtersList } = props;

  // console.log('sortByOptions', sortByOptions);

  const { shop, onlyLotsOfSizes } = activeFilter;

  const { products_brands, products_sizes, products_colors, products_shoes_models } = filtersList;

  // console.log('products_shoes_models', products_shoes_models);

  const brands = [...products_brands];
  brands.unshift({ text: 'Wszystkie', value: 'all', newValue: 'all' });

  const shoesSizes = getSizes(products_sizes, 1);
  shoesSizes.unshift({ text: 'Wszystkie', value: 'all', newValue: 'all' });

  const clothesSizes = getSizes(products_sizes, 2);
  clothesSizes.unshift({ text: 'Wszystkie', value: 'all', newValue: 'all' });

  const colors = [...products_colors];
  colors.unshift({ text: 'Wszystkie', value: 'all', newValue: 'all' });
  // console.log('colors',colors)
  // console.log('clothesSizes',clothesSizes);

  // console.log('brand', activeFilter.brand);
  let shoesModels = getShoesModels(products_shoes_models, shop.value);
  shoesModels = sortShoesModelsByName(shoesModels);
  shoesModels.unshift({ text: 'Wszystkie', value: 'all', newValue: 'all' });
  // console.log('shoesModels', shoesModels);

  let shoesSubModels = getShoesSubModels(products_shoes_models, shop.value, activeFilter.model);
  shoesSubModels = sortShoesModelsByName(shoesSubModels);
  if (shoesSubModels.length > 0) {
    shoesSubModels.unshift({ text: 'Wszystkie', value: 'all', newValue: 'all' });
  }

  // const getShoesModelByValue
  // const shoesSubModels = getShoesModels(products_shoes_models, shop.value, activeFilter.model);

  const defaultFilter = activeFilter.mainCategory || filters[0].value;
  const activeMainCategory = findByValue(filters, 'value', defaultFilter);

  // if(queryOnlyLotsOfSizes === 'true')  { queryOnlyLotsOfSizes = true } else { queryOnlyLotsOfSizes = false}
  const showBrandsFilter = brandsFilter(shop);

  let activeSubCategory = finndActiveSubCategory(activeMainCategory, activeFilter);

  const handleFitlering = (type, value) => {
    callback(type, value);
  };

  const handleDropdown = (e, { name, value }) => {
    callback(name, value);
  };

  // console.log('shoesModels', shoesModels);
  // console.log('activeFilter.model', activeFilter.model);

  // console.log('list', filters[1].categories[1].categories);

  return (
    <div style={{ textAlign: 'center', padding: '0 0 20px 0' }} className="stealsFilter">
      <Grid centered>
        <Form>
          <Form.Group>
            <Grid.Column mobile={6} tablet={4} computer={4}>
              <Form.Field
                style={{ minWidth: '12em', marginLeft: '-10px', marginRight: '10px' }}
                control={Select}
                options={shops}
                label={{ children: 'Sklep:', htmlFor: 'form-select-control-shops' }}
                placeholder="Sklep"
                value={activeFilter.shop.value}
                name="shop"
                onChange={handleDropdown}
              />
            </Grid.Column>
            {/*{showBrandsFilter && (*/}
            {/*  <Grid.Column mobile={6} tablet={4} computer={4}>*/}
            {/*    <Form.Field*/}
            {/*      style={{ minWidth: '12em' }}*/}
            {/*      control={Select}*/}
            {/*      options={brands}*/}
            {/*      label={{ children: 'Marka:', htmlFor: 'form-select-control-brands' }}*/}
            {/*      placeholder="Marka"*/}
            {/*      value={activeFilter.brand}*/}
            {/*      name="brand"*/}
            {/*      onChange={handleDropdown}*/}
            {/*    />*/}
            {/*  </Grid.Column>*/}
            {/*)}*/}
            <Grid.Column mobile={6} tablet={4} computer={4}>
              <Form.Field
                style={{ minWidth: '12em', marginLeft: '10px' }}
                control={Select}
                options={sortByOptions}
                label={{ children: 'Sortuj według:', htmlFor: 'form-select-control-sort-by' }}
                placeholder="Sortuj"
                value={activeFilter.sortBy}
                name="sortBy"
                onChange={handleDropdown}
              />
            </Grid.Column>
          </Form.Group>
        </Form>
      </Grid>

      <br />

      <Responsive {...Responsive.onlyMobile}>
        <br />
      </Responsive>

      {currentShop.value !== 'all' && (
        <p style={{ textAlign: 'center', paddingBottom: '15px' }}>
          <i className="clock outline icon"></i> Ost.aktualizacja:{' '}
          <strong>{lastFetch ? dayjs(lastFetch).fromNow() : 'brak danych'}</strong>
        </p>
      )}

      <TabFilter type="mainCategory" filters={filters} callback={handleFitlering} active={defaultFilter} />

      <br />
      <br />

      <TabFilter
        type="gender"
        filters={gender}
        callback={handleFitlering}
        active={activeFilter.gender || gender[0].value}
      />

      {activeMainCategory &&
        activeMainCategory.categories &&
        activeMainCategory.categories.length > 0 &&
        defaultFilter !== 'buty' && (
          <>
            <br />
            <br />

            <TabFilter
              type="subCategory"
              filters={activeMainCategory.categories}
              callback={handleFitlering}
              active={activeSubCategory}
              subFilter={true}
              size="tiny"
            />
          </>
        )}
      <br />

      {defaultFilter === 'buty' && (
        // SHOES MODELS
        <Grid centered style={{ marginTop: '15px' }}>
          <Form>
            <Form.Group>
              <Grid.Column mobile={6} tablet={4} computer={4}>
                <Form.Field
                  inline
                  // style={{ minWidth: '16em' }}
                  control={Select}
                  options={shoesModels}
                  label={{ children: 'Model:', htmlFor: 'form-select-control-shops' }}
                  placeholder="Model"
                  value={activeFilter.model}
                  name="model"
                  onChange={handleDropdown}
                />
              </Grid.Column>
              {activeFilter.model && shoesSubModels.length > 1 && (
                <Grid.Column mobile={6} tablet={4} computer={4} className="shoesSubModels">
                  <Form.Field
                    inline
                    style={{ marginLeft: '10px', minWidth: '16em' }}
                    control={Select}
                    options={shoesSubModels}
                    // label={{ children: 'Model:', htmlFor: 'form-select-control-shops' }}
                    placeholder="Model"
                    value={activeFilter.subModel}
                    name="subModel"
                    onChange={handleDropdown}
                  />
                </Grid.Column>
              )}
            </Form.Group>
          </Form>
        </Grid>
      )}

      <Grid centered style={{ marginTop: '20px' }}>
        <Form>
          <Form.Group>
            {/*{showBrandsFilter && (*/}
            {/*  <Grid.Column mobile={6} tablet={4} computer={4}>*/}
            {/*    <Form.Field*/}
            {/*      style={{ minWidth: '12em', marginLeft: '-10px' }}*/}
            {/*      control={Select}*/}
            {/*      options={brands}*/}
            {/*      label={{ children: 'Marka:', htmlFor: 'form-select-control-brand' }}*/}
            {/*      placeholder="Marka"*/}
            {/*      value={activeFilter.brand}*/}
            {/*      name="brand"*/}
            {/*      onChange={handleDropdown}*/}
            {/*    />*/}
            {/*  </Grid.Column>*/}
            {/*)}*/}
            {defaultFilter === 'buty' && (
              <Grid.Column mobile={6} tablet={4} computer={4}>
                <Form.Field
                  style={{ minWidth: '12em', marginLeft: '-10px' }}
                  control={Select}
                  options={shoesSizes}
                  label={{ children: 'Rozmiar:', htmlFor: 'form-select-control-shoeSize' }}
                  placeholder="Rozmiar"
                  value={activeFilter.shoeSize}
                  name="shoeSize"
                  onChange={handleDropdown}
                />
              </Grid.Column>
            )}
            {defaultFilter === 'ciuchy' && (
              <Grid.Column mobile={6} tablet={4} computer={4}>
                <Form.Field
                  style={{ minWidth: '12em', marginLeft: '-10px' }}
                  control={Select}
                  options={clothesSizes}
                  label={{ children: 'Rozmiar:', htmlFor: 'form-select-control-clothesSize' }}
                  placeholder="Rozmiar"
                  value={activeFilter.clothesSize}
                  name="clothesSize"
                  onChange={handleDropdown}
                />
              </Grid.Column>
            )}
            <Grid.Column mobile={6} tablet={4} computer={4}>
              <Form.Field
                style={{ minWidth: '12em', marginLeft: '10px' }}
                control={Select}
                options={colors}
                label={{ children: 'Kolor:', htmlFor: 'form-select-control-color' }}
                placeholder="Kolor"
                value={activeFilter.color}
                name="color"
                onChange={handleDropdown}
              />
            </Grid.Column>

            {/*<Checkbox label='Tylko dużo rozmiarów'  checked={true} onChange={handleDropdown} />*/}
          </Form.Group>
        </Form>
      </Grid>

      {(defaultFilter === 'buty' || defaultFilter === 'ciuchy') && (
        <Grid centered style={{ marginTop: '0' }}>
          <Form>
            <Form.Group>
              <Checkbox
                toggle
                checked={onlyLotsOfSizes}
                label="Tylko dużo rozmiarów"
                name="onlyLotsOfSizes"
                style={{ marginTop: '30px', marginLeft: '20px' }}
                onChange={handleDropdown}
              />
            </Form.Group>
          </Form>
        </Grid>
      )}

      <Header as="h4" textAlign="center">
        {counter} {polishPlurals('produkt', 'produkty', 'produktów', counter)}
      </Header>
    </div>
  );
};

export default memo(Filter);
