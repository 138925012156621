import React, { useState } from 'react';
import { Button, Confirm, Icon } from 'semantic-ui-react';

const ModalConfirmation = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const onConfirmation = () => {
    window.open('https://sdeals.pl/nikeapp', '_blank');
    closeModal();
  };

  return (
    <div>
      <Button primary size="tiny" onClick={openModal}>
        <Icon name="mobile" /> Kup
      </Button>
      <Confirm
        open={showModal}
        header="Uwaga!"
        content="Wybrany produkt dostępny jest TYLKO w aplikacji NIKE APP."
        onCancel={closeModal}
        cancelButton="Anuluj"
        onConfirm={onConfirmation}
        confirmButton="Pobierz aplikacje"
      />
    </div>
  );
};

export default ModalConfirmation;
