import React, { Component } from 'react';
import './Search.css';
import Grid from 'semantic-ui-react/dist/commonjs/collections/Grid';
import Container from 'semantic-ui-react/dist/commonjs/elements/Container';
import Segment from 'semantic-ui-react/dist/commonjs/elements/Segment';
import Tab from 'semantic-ui-react/dist/commonjs/modules/Tab';
import SearchIntro from './SearchIntro';
// import IndexCards from '../components/index/IndexCards';
import SearchBox from './SearchBox/SearchBox';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import { shops } from '../../../utils/data';
import PopularModels from './PopularModels';

const listOfShops = () => {
  const justShopsWithName = [...shops];
  justShopsWithName.shift();
  // console.log('justShopsWithName', justShopsWithName);
  return justShopsWithName.map((shop, index) => {
    const { text } = shop;
    return `${text}${index !== justShopsWithName.length - 1 ? ',' : ''} `;
  });
};

const panes = [
  // {
  //   menuItem: 'Wszystko',
  //   render: () => (
  //     <Tab.Pane>
  //       <SearchBox tab="wszystko" />
  //     </Tab.Pane>
  //   ),
  // },
  {
    menuItem: 'Buty',
    render: () => (
      <Tab.Pane>
        <SearchBox tab="buty" />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Ciuchy',
    render: () => (
      <Tab.Pane>
        <SearchBox tab="ciuchy" />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Dodatki',
    render: () => (
      <Tab.Pane>
        <SearchBox tab="dodatki" />
      </Tab.Pane>
    ),
  },
];

export default class Search extends Component {
  render() {
    return (
      <Segment
        vertical
        className=" introBackground search-section gradient"
        style={{ backgroundImage: 'linear-gradient(to top, #e7e7e7, #dedede, #d5d5d5, #cdcdcd, #c4c4c4);' }}
      >
        {/*<Segment vertical className="segmentInverted introBackground search-section">*/}
        <Container id="topContainer">
          <SearchIntro />
          <br />
          <Grid centered className="searchBox">
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={12}>
                <Tab panes={panes} renderActiveOnly={true} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {/*<Header>*/}
          {/*  <Header.Subheader style={{ textAlign: 'center' }}>*/}
          {/*    <small>* Aktualnie monitorowane sklepy: {listOfShops()}</small>*/}
          {/*    /!** Atualnie monitorowane sklepy: <strong>nike.com</strong>, <strong>footshop.pl</strong>,{' '}*!/*/}
          {/*    /!*<strong>wordlbox.pl</strong>*!/*/}
          {/*  </Header.Subheader>*/}
          {/*</Header>*/}
          {/*<br />*/}
          {/*<br />*/}
          {/*<IndexCards />*/}
          <PopularModels />
        </Container>
      </Segment>
    );
  }
}
