import React, { useState, useEffect } from 'react';
import Loading from '../shared/Loading';
import ResponsiveCards from '../shared/ResponsiveCards';
import { postsList } from '../Drops/utils';
import { fetchData } from '../../utils/utils';
import { mapData, filterOnlyPRomotedProdutcs, sortProductsBy, filterOnlyNewProdutcs2 } from '../Drops/Calendar.utils';
import Title from '../shared/Title';
import PromoModal from '../shared/ProductCard/PromoModal';
import { isBeta } from '../Steals/ProductBrowser/ProductBrowserUtils';
const nikeNewestShoes =
  'https://api.apify.com/v2/acts/9raauiNyqeol6d8sX/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6';
import { Container, Tab } from 'semantic-ui-react';

function NewReleases({ perPage = '', limit }) {
  const beta = isBeta();
  const [newestItems, setNewestItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [promoModalData, setPromoModalData] = useState({});

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      let newestItems = await fetchData([nikeNewestShoes]);
      newestItems = filterOnlyNewProdutcs2(newestItems);
      newestItems = mapData(newestItems);
      newestItems = sortProductsBy(newestItems, 'date');

      if (limit) {
        newestItems = filterOnlyPRomotedProdutcs(newestItems);
        const slicedArray = newestItems.slice(0, limit);
        setNewestItems(slicedArray);
      } else {
        setNewestItems(newestItems);
      }
      setLoading(false);
    };

    loadData();
  }, []);

  const onPromoModal = data => {
    setPromoModalData(data);
    setShowPromoModal(true);
  };

  const onPromoModalClose = () => {
    setPromoModalData({});
    setShowPromoModal(false);
  };

  return (
    <>
      <Container>
        <div id="calendar">
          {!perPage && (
            <div>
              <br />
              <Title title="Nowości" icon="fire"></Title>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <ResponsiveCards>{postsList(newestItems, onPromoModal, beta)}</ResponsiveCards>
                  <br />
                  <br />
                </>
              )}

              {/*<More url="/okazje/wszystko?gender=wszystkie&shop=nike&sortBy=newest" title="Więcej Nowości" />*/}

              {/*<br />*/}
              {/*<br />*/}
              {/*<br />*/}
              {/*<br />*/}
            </div>
          )}
          {beta && (
            <PromoModal
              openModal={showPromoModal}
              closeModal={onPromoModalClose}
              data={promoModalData}
              promoType="drop"
            />
          )}
        </div>
      </Container>
    </>
  );
}

export default NewReleases;
