import React, { useState, useEffect } from 'react';
import Loading from '../shared/Loading';
import axios from 'axios';
import { Grid, Image } from 'semantic-ui-react';
// import XMLParser from 'react-xml-parser';
import ResponsiveCards from '../shared/ResponsiveCards';
// import { postsList } from './utils';
import { IGEmbed } from 'react-ig-embed';
import { fetchData, limitWordsTo } from '../../utils/utils';
import PostCard from '../shared/PostCard';
const convert = require('xml-js');

// const {convertXML} = require("simple-xml-to-json")

export const postsList = (posts, onPromoModal, beta) => {
  return posts.map((post, index) => {
    const { instagramUrl } = post;
    return (
      <Grid.Column mobile={16} tablet={8} computer={5}>
        <IGEmbed url={instagramUrl} linkTextDisabled={true} />
      </Grid.Column>
    );
    // return <IGEmbed url={instagramUrl} />
    // return <PostCard key={index} data={post} onPromoModal={onPromoModal} beta={beta} />;
  });
};

const filterData = data => {
  return data.filter(item => {
    let { caption, queryUsername } = item;
    caption = caption.toLowerCase();
    return caption.includes(' resell') && queryUsername !== 'newsy_i_predykcje' && queryUsername !== 'jutro.drop';
  });
};

//  || caption.includes('will be available')

const mapData = data => {
  return data.map(item => {
    const { title, pubDate, link } = item;
    const instagramUrl = link._text;
    const postId = instagramUrl.substr(instagramUrl.length - 11);

    // const imageUrl = `https://www.instagram.com/p/${postId}/media/?size=l`;

    return {
      title: title._cdata,
      date: pubDate._text,
      // message: `${limitWordsTo(caption, 200)}...`,
      // imageUrl,
      instagramUrl,
    };
  });
};

function Resell() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      // const jutroDrop = 'https://rss.app/feeds/RNveGLWmXbQRBTkg.xml';
      const resellRss = 'https://rss.app/feeds/_EGxv3erU2D9272nj.xml';
      const xmlData = await axios.get(resellRss, {
        'Content-Type': 'application/xml; charset=utf-8',
      });
      // console.log('res', xmlData);

      const res = convert.xml2js(xmlData.data, { compact: true, spaces: 4 });
      // const res = convertXML(xmlData)
      // console.log('res', res);
      // console.log('res more', res.rss.channel.item);
      // let result = await fetchData([
      //   'https://api.apify.com/v2/acts/3hnY5buBJieQ6MK49/runs/last/dataset/items?token=D3oo8wXXjtNhLSsHTJjtc4Eu6&status=SUCCEEDED&clean=true',
      // ]);
      // result = filterData(result);
      const result = mapData(res.rss.channel.item);
      const limited = result.slice(0, 9);
      setItems(limited);
      setLoading(false);
    };

    loadData();
  }, []);

  return (
    <>
      {/*<Title title="Resell" icon="calendar"></Title>*/}
      <br />
      <br />
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Grid centered columns="equal" padded>
            {postsList(items)}
          </Grid>
          {/*<ResponsiveCards>{postsList(items)}</ResponsiveCards>*/}
        </div>
      )}
    </>
  );
}

export default Resell;
